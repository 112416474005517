import styled from "styled-components";
import * as Defines from '../../utils/defines/defines'

export const Container = styled.div`
    width: 100w;
    height: 100vh;
    background: ${Defines.BackgroundContainer};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px;
    box-sizing: border-box;
`
export const Nav = styled.div`
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
`

export const NavTitleContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const NavTitle = styled.p`
    margin: 0;
    font-family: "Montserrat";
    background: -webkit-linear-gradient(45deg, rgb(62, 147, 216), rgb(76, 185, 49));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    font-weight: bold;
    font-size: 35px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
`
export const NavTitleWhite = styled.p`
    margin: 0;
    font-family: "Montserrat";
    color: rgba(255,255,255,0.8);
    font-weight: regular;
    font-size: 30px;
    letter-spacing: 1.5px;
`

export const UserImg = styled.img`
    height: 25px;
    width: 25px;
    border-radius: 50%;
`

export const NavSubtitle = styled.p`
    margin: 0;
    font-family: "Montserrat";
    color: ${Defines.TitleColor};
    font-weight: 300;
    font-size: 20px;
`

export const NavButtons = styled.div`
    display: flex;
    grid-gap: 0px 5px;
`

export const BtnIcon = styled.img`
    height: 20px;
    width: 20px;
`

export const Card = styled.div`
    width: 100%;
    background: rgba(94, 94, 94, 0.1);
    backdrop-filter: blur(4px);
    border: none;
    border-radius: 20px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const Content = styled.div`
    height: calc(100% - 60px);
    max-height: calc(100% - 60px);
    min-height: calc(100% - 60px);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const HalfContent = styled.div<{percentage: number}>`
    width: ${props => props.percentage}%;
    height: 100%;
    overflow-y: scroll;
    ::-webkit-scrollbar{
        display: none;
    }
`

export const Title = styled.p`
    margin: 0;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 25px;
    margin-bottom: 10px;
    color: ${Defines.TitleColor};
`

export const CardTitle = styled.div`
    height: 20px;
    border: 1px solid ${props => props.color};
    border-radius: 10px;
    padding-left: 5px; 
    padding-right: 5px;
    transition: .5s;
    transition-timing-function: ease-in;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const TitleUHBG = styled.div`
    transition: .5s;
    transition-timing-function: ease-in;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 40px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    align-items: center;
    box-sizing: border-box;
    background-color: #17212E;
    boder: 0px;
    border-radius: 20px;
`

export const Scroll = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    ::-webkit-scrollbar{
        display: none;
    }
    :hover{
        // ::-webkit-scrollbar{
        //     display: block;
        // }
    }
`

export const MoendaCard = styled.button`
    cursor: pointer;
    background: rgba(94, 94, 94, 0.1);
    backdrop-filter: blur(4px);
    border: none;
    border-radius: 20px;

    width: 100%;
    height: 90px;
    margin-bottom: 5px;
    padding: 20px;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;

    display: flex;
    justify-content: space-between;
    align-items: center;

`

export const ListCard = styled.button`
    cursor: pointer;
    background: rgba(94, 94, 94, 0.1);
    backdrop-filter: blur(4px);
    border: none;
    border-radius: 20px;

    width: 100%;
    margin-bottom: 5px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`
export const ListText = styled.p`
    margin: 0;
    font-family: "Nunito";
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 5px;
    color: rgba(255,255,255,0.8)
`

export const ListTitle = styled.p`
    margin: 0;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 18px;
    color: ${Defines.TitleColor};
`

export const Circle = styled.div<{color: string}>`
    width: 40px;
    height: 40px;
    border: 2px solid ${props => props.color};
    border-radius: 50%;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const CircleTitle = styled.div<{color: string}>`
    width: 18px;
    height: 18px;
    border: 1px solid ${props => props.color};
    border-radius: 50%;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const IconTitle = styled.img`
    width: 10px;
    height: 10px;
`

export const CircleRed = styled.div`
    width: 15px;
    height: 15px;
    top: 2px;
    right: 20%;
    border: none;
    border-radius: 50%;
    background: red;
    position: absolute;
`

export const Row = styled.div`
    display: flex;
`

export const MoendaIcon = styled.img`
    height: 25px;
`

export const MoendaTextContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

export const MoendaTitle = styled.p`
    margin: 0;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 20px;
    transition: .5s;
    color: ${Defines.TitleColor};
`

export const MoendaText = styled.p`
    margin: 0;
    font-family: "Nunito";
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 10px;
    color: rgba(255,255,255,0.8);
      -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    -ms-transition: 0.5s;
    transition: 0.5s
`

export const MaintenanceCard = styled.div`
    cursor: pointer;
    width: 100%;
    background: rgba(94, 94, 94, 0.1);
    backdrop-filter: blur(4px);
    border: none;
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    overflow: hidden;

    margin-bottom: 10px;
    transition: .5s;
`

export const MaintenanceInit = styled.div`
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const MaintenanceCardIcon = styled.img`
    height: 80px;
`

export const MaintenanceTitle = styled.p`
    margin: 0;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 18px;
    color: ${Defines.TitleColor};
`

/* Gradient */
export const PickupGradient = styled.div``

export const CircleText = styled.p`
    margin: 0;
`

export const GradientContent = styled.div`
    position: relative;
    marigin-top: 10px;
    padding-top: 2px;
    min-height: 80px;
    box-sizing: border-border;
    width: 100%;
`
export const Pickup = styled.div<{left: number}>`
    top: 0;
    position: absolute;
    left: ${props => props.left}%;
`
export const TextPickup = styled.p`
    margin: 0;
    font-family: 'Nunito';
    font-size: 10px;
    color: #FFF;
    white-space: nowrap;
    margin-left: -30%;
`

export const RailPickup = styled.div`
    position: relative;
    margin-top: -7px;
    width: calc(100% - 8px);
`

export const CirclePickup = styled.div`
    height: 5px;
    width: 5px;
    border-radius: 25px;
    border: 2px solid #FFF;
    color: #FFF;
`

export const GradientCenter = styled.div`
    width: 100%;
    height: 5px;
    border-radius: 25px;
    background: -webkit-linear-gradient(0deg, rgba(240,63,63,1) 5%,  rgba(229,211,74,1) 15%,rgba(146,178,88,1) 25%, rgba(146,178,88,1) 50%, rgba(146,178,88,1) 75%,  rgba(229,211,74,1) 85%, rgba(240,63,63,1) 95%);
`

export const GradientStart = styled.div`
    width: 100%;
    height: 5px;
    border-radius: 25px;
    background: -webkit-linear-gradient(0deg, rgba(146,178,88,1) 20%, rgba(229,211,74,1) 25%, rgba(229,211,74,1) 43%,rgba(240,63,63,1) 50%);
`
export const GradientUmidade = styled.div`
    width: 100%;
    height: 5px;
    border-radius: 25px;
    background: -webkit-linear-gradient(0deg, rgba(146,178,88,1) 40%, rgba(229,211,74,1) 41%, rgba(229,211,74,1) 60%, rgba(240,63,63,1) 61%);
`

export const ContentTimeUpdate = styled.div`
    position: absolute;
    bottom: 10px;
`