import React, {Dispatch} from 'react';
import * as Body from './Styles'

type Props = {
    text: string,
    width?: number,
    action?: any,
    margintop?: number
}

const ButtonGradient: React.FC <Props> = ({
    text, width, action, margintop
}) => {
    return <Body.Button style={{width: width? width+'%':'', marginTop: margintop?margintop:''}}
         onClick={action} >
        {text}
    </Body.Button>
}

export default ButtonGradient