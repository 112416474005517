import { Terno } from "../types";
import { TernoAction } from "./actionType";
import { data } from "../data";


const terno = (
    state: Terno = data[0].ternos[0],
    action: TernoAction
) =>{
    return state
}

export default terno