import React, { Dispatch, SetStateAction, useState, useRef, useEffect } from "react";
import Modal from "../../../components/Modal/Modal";
import * as Body from './Styles'
import Input from '../../../components/Input/Input'
import Button from '../../../components/Button/Button'
import * as BodyInput from '../../../components/Input/Style'
import * as Functions from '../../../utils/functions/functions'
import * as Regex from '../../../utils/Regex/Regex'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'
import ListModal from '../../../components/ListModal/ListModal'
import imageCompression from 'browser-image-compression'
import * as Types from '../../../utils/redux/types'
import { useDispatch } from "react-redux";
import EyeIcon from '../../../assets/icons/eye-regular.svg'
import EyeSplashIcon from '../../../assets/icons/eye-slash-regular.svg'
import Loading from "../../../components/Loading/Loading";
import CodeInput from "../../../components/CodeInput/CodeInput";
import NumberInput from "../../../components/NumberInput/NumberInput";

const avatar = require('../../../assets/images/avatar-1.png')

type ModalProp = {
    toggle: Dispatch<SetStateAction<boolean>>
}

type AuthTecnicoProps = {
    p_nome: string,
    s_nome: string,
    email: string,
    telefone_ddd: string,
    telefone_numero: string,
    senha: string,    
    toggle: Dispatch<SetStateAction<boolean>>
}

type ModalSelectProp = {
    toggle: Dispatch<SetStateAction<boolean>>,
    setText: Dispatch<SetStateAction<string>>
}

// export const AuthModal: React.FC <ModalProp> = ({
//     toggle, 
// }) => {
//     const navigate = useNavigate()

//     useState(()=>{
//         axios.post(process.env.REACT_APP_AUTH_REQUEST_URL!, {})
//             .then((response)=> {
//                 switch(response.data.code){
//                     case 200:
//                         break
//                     case 403:
//                         alert("Token inválido")
//                         navigate('/')
//                         break
//                     case 500:
//                         alert("Erro interno")
//                         navigate('/')
//                         break
//                 }
//             })
//             .catch((error)=>{
//                 alert("Erro" + error)
//             })
//     })   

//     const [mensagem, setMensagem] = useState("")

//     const [code1, setCode1] = useState("")
//     const [code2, setCode2] = useState("")
//     const [code3, setCode3] = useState("")
//     const [code4, setCode4] = useState("")
//     const [code5, setCode5] = useState("")
//     const [code6, setCode6] = useState("")

//     const ref1 = useRef<any>()
//     const ref2 = useRef<any>()
//     const ref3 = useRef<any>()
//     const ref4 = useRef<any>()
//     const ref5 = useRef<any>()
//     const ref6 = useRef<any>()

//     const field = (text: string, value: number) => {
//         switch(value){
//             case 1:
//                 setCode1(text)
//                 if(text.length > 0) ref2.current.focus()
//                 break
//             case 2:
//                 setCode2(text)
//                 text.length > 0? ref3.current.focus():ref1.current.focus()
//                 break
//             case 3:
//                 setCode3(text)
//                 text.length > 0? ref4.current.focus():ref2.current.focus()
//                 break
//             case 4:
//                 setCode4(text)
//                 text.length > 0? ref5.current.focus():ref3.current.focus()
//                 break
//             case 5:
//                 setCode5(text)
//                 text.length > 0? ref6.current.focus():ref4.current.focus()
//                 break
//             case 6:
//                 setCode6(text)
//                 if(text.length === 0) ref5.current.focus()
//                 break
//         }
//     }

//     const send = () => {
//         setMensagem("")
//         if((code1.length  == 0 ) && (code2.length == 0) && (code3.length == 0) 
//             && (code4.length == 0) && (code5.length == 0) && (code6.length == 0)){
//             setMensagem("Preencha todos os campos")
//             return
//         }

//         let concat = code1.concat(code2, code3, code4, code5, code6)
//         let code = Number(concat)
//         axios.post(process.env.REACT_APP_AUTH_URL!, {code})
//             .then((response)=> {
//                 switch(response.data.code){
//                     case 200:
//                         toggle(false)
//                         break
//                     case 403:
//                         setMensagem("Token/Código inválido")
//                         break
//                     case 500:
//                         alert("Erro interno")
//                         break
//                 }
//             })
//             .catch((error)=> {
//                 alert('Erro: '+ error)
//             })
//     }



//     return <Modal toggleModal={toggle} title="Autenticação" >
//        <Body.Content>
//            <Body.Text>Enviamos um código de confirmação para seu telefone, insira-o para prossegir</Body.Text>
//            <Body.Row>
//                <Body.HalfContent percentage={16}>
//                     <BodyInput.Input ref={ref1} value={code1} onChange={e => field(e.target.value, 1)} 
//                         maxLength={1} align="center"/>
//                </Body.HalfContent>
//                <Body.HalfContent percentage={16}>
//                     <BodyInput.Input ref={ref2} value={code2} onChange={e => field(e.target.value, 2)}  
//                         maxLength={1} align="center"/>
//                </Body.HalfContent>
//                <Body.HalfContent percentage={16}>
//                     <BodyInput.Input ref={ref3} value={code3} onChange={e => field(e.target.value, 3)}  
//                         maxLength={1} align="center"/>
//                </Body.HalfContent>
//                <Body.HalfContent percentage={16}>
//                     <BodyInput.Input ref={ref4} value={code4} onChange={e => field(e.target.value, 4)} 
//                         maxLength={1} align="center"/>
//                </Body.HalfContent>
//                <Body.HalfContent percentage={16}>
//                     <BodyInput.Input ref={ref5} value={code5} onChange={e => field(e.target.value, 5)}  
//                         maxLength={1} align="center"/>
//                </Body.HalfContent>
//                <Body.HalfContent percentage={16}>
//                     <BodyInput.Input ref={ref6} value={code6} onChange={e => field(e.target.value, 6)} 
//                         maxLength={1} align="center"/>
//                </Body.HalfContent>
//            </Body.Row>
//            <Button text="Cadastrar" width={40}/>
//        </Body.Content>
//     </Modal>
// }


export const RegisterUser: React.FC <ModalProp> = ({
    toggle
}) => {
    const [p_nome, setName] = useState("")
    const [s_nome, setSobrenome] = useState("")
    const [email, setEmail] = useState("")
    const [telefone_ddd, setDDD] = useState('')
    const [senha, setSenha] = useState("")
    const [telefone_numero, setTelefone] = useState("")

    const [codigo_tfa, setCode] = useState("")
    
    const [content, toggleContent] = useState("SEND")
    const dispatch = useDispatch()
    const [loading, toggleLoading] = useState(false)

    const [passVisible, toggleVisibility] = useState(true)

    const telefoneInsert = (text: string) => {
        setTelefone(Regex.numberRegex(text, telefone_numero))
    }

    const send = () => {
        toggleLoading(true)
        axios.post(process.env.REACT_APP_TECNICO_CREATE!, 
            {p_nome, s_nome, email, telefone_ddd, telefone_numero, senha},  
            {withCredentials: true})
            .then((response)=>{
                toggleLoading(false)
                toggleContent("INSERT")
            })
            .catch((e)=>{
                toggleLoading(false)
                console.log(e)
            })
    }

    const inputData = () => {
        return <>
        <Body.Row >
               <Body.HalfContent percentage={49}>
                    <Input placeholder="NOME" text={p_nome} setText={setName} />
               </Body.HalfContent>
               <Body.HalfContent percentage={49}>
                    <Input placeholder="SOBRENOME" text={s_nome} setText={setSobrenome} />
               </Body.HalfContent>
            </Body.Row>
            <Input placeholder="EMAIL" text={email} setText={setEmail} />
            <Body.Row >
               <Body.HalfContent percentage={49.5}>
                    <Body.PasswordContent>
                        <Body.Icon src={passVisible?EyeIcon:EyeSplashIcon} 
                                onClick={()=>toggleVisibility(old => !old)}/>
                        <Input placeholder="SENHA" text={senha} setText={setSenha}
                            secret={passVisible} paddingRight={60} />
                    </Body.PasswordContent>
               </Body.HalfContent>
               <Body.HalfContent percentage={49.5}>
                <Body.Row >
                    <Body.HalfContent percentage={30}>
                        <Input placeholder="DDD" text={telefone_ddd} setText={setDDD} 
                            maxLenght={2}/>
                    </Body.HalfContent>
                    <Body.HalfContent percentage={68}>
                        <Input placeholder="Telefone" text={telefone_numero} setText={telefoneInsert} 
                            maxLenght={10}/>
                    </Body.HalfContent>
                    </Body.Row>
               </Body.HalfContent>
            </Body.Row>
            <div style={{marginBottom: 20}} />
           <Button text="Cadastrar" action={send}/></>
    }

     const trySendCode = () => {

        axios.post(process.env.REACT_APP_TECNICO_CREATE!, 
            {p_nome, s_nome, email, telefone_ddd, telefone_numero, senha, codigo_tfa},
            {withCredentials: true})
            .then((response)=>{
                dispatch({type: 'ADD_NOTIFICATION', status: 1, title: 'Usuário cadastrado com sucesso'})
                toggle(false)
            })
            .catch((error)=>{
                dispatch({type: 'ADD_NOTIFICATION', status: 3, title: error.response.data})
            })
    }

    const resendCode = () => {
		axios.get(process.env.REACT_APP_RESEND_URL!, {withCredentials: true})
			.then((response) => {	
                dispatch({type: 'ADD_NOTIFICATION', status: 1, title: "Código reenviado com sucesso"})	
			})
			.catch((error) => {
                dispatch({type: 'ADD_NOTIFICATION', status: 3, title: "Erro ao reenviar código " + error.response.status})
			})
	}


    const insertCode = () => {
        return <>
            <Body.Text>Enviamos um código de autenticação para seu celular, 
                insira-o para prossguir com alteração dos dados</Body.Text>
            <CodeInput setText={setCode} />
            <div style={{marginBottom: 20}} />
            <Body.Row style={{alignItems: 'center', justifyContent: 'center',
                gridGap: '0px 10px'}}>
                <Button text="Continuar" action={trySendCode} />
                <Button text="Reenviar código" action={resendCode} />
            </Body.Row>
        </>
    }

    const getContent = () => {
        switch(content){
            case "SEND": 
                return inputData()
            case "INSERT":
                return insertCode()
        }
    }

    return <>
     <Modal toggleModal={toggle} title="Cadastrar usuário" >
       <Body.Content>
        {getContent()}
       </Body.Content>
    </Modal>
    </>
}

export const ChangeEmail: React.FC <ModalProp> = ({
    toggle, 
}) => {
    const [email, setEmail] = useState('')
    const [content, toggleContent] = useState("SEND")
    const [loading, toggleLoading] = useState(false)

    const [errorEmail, toggleErrorEmail] = useState(false)
    const dispatch = useDispatch()
    const [codigo_tfa, setCode] = useState("")

    const send = () => {
        if(!Functions.verifyEmail(email)){
            toggleErrorEmail(true)
            return
        }
        toggleErrorEmail(false)
        toggleLoading(true)
        axios.put(process.env.REACT_APP_EMAIL_URL!, {email}, {withCredentials: true})
            .then((response)=> {
                toggleContent("INSERT")
                toggleLoading(false)
            })
            .catch((error)=> {
                dispatch({type: 'ADD_NOTIFICATION', status: 2, title: error.response.data.msg})
                toggleLoading(false)
            })
    }

    const insertEmail = () => {
        return <>
            <Input  placeholder="NOVO EMAIL" text={email} 
                setText={setEmail} error={errorEmail} />
            <div style={{marginBottom: 20}} />
            <Button text="Alterar"  action={()=>send()}/>
        </>
    }

     const trySendCode = () => {

        toggleLoading(true)
        axios.put(process.env.REACT_APP_EMAIL_URL!, {email, codigo_tfa},{withCredentials: true})
            .then((response)=>{
                dispatch({type: 'ADD_NOTIFICATION', status: 1, title: 'Email alterado com sucesso'})
                toggle(false)
                toggleLoading(false)
            })
            .catch((error)=>{
                dispatch({type: 'ADD_NOTIFICATION', status: 3, title: error.response.data.msg})
                toggleLoading(false)
            })
    }

    const resendCode = () => {
		axios.get(process.env.REACT_APP_RESEND_URL!, {withCredentials: true})
			.then((response) => {	
                dispatch({type: 'ADD_NOTIFICATION', status: 1, title: "Código reenviado com sucesso"})	
			})
			.catch((error) => {
                dispatch({type: 'ADD_NOTIFICATION', status: 3, title: "Erro ao reenviar código " + error.response.status})
			})
	}


    const insertCode = () => {
        return <>
            <Body.Text>Enviamos um código de autenticação para seu celular, 
                insira-o para prossguir com alteração dos dados</Body.Text>
            <CodeInput setText={setCode} />
            <div style={{marginBottom: 20}} />
            <Body.Row style={{alignItems: 'center', justifyContent: 'center',
                gridGap: '0px 10px'}}>
                <Button text="Continuar" action={trySendCode} />
                <Button text="Reenviar código" action={resendCode} />
            </Body.Row>
        </>
    }


    const getContent = () => {
        switch(content){
            case "SEND":
                return insertEmail()
            case "INSERT":
                return insertCode()
        }
    }

    return <Modal title="Alterar email" toggleModal={toggle}>
        <Body.Content>
            {getContent()}
        {loading?<Loading />:null}
        </Body.Content>
    </Modal>
}

export const ChangePassword: React.FC <ModalProp> = ({
    toggle, 
}) => {
    const [content, toggleContent] = useState("SEND_CODE")

    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')
    const dispatch = useDispatch()

    const [passVisible, toggleVisibility] = useState(true)
    const [errorPass, toggleErrorPass] = useState(false)
    const [celular, setCelular] = useState("")
    const [codigo_tfa, setCode] = useState("")

    const send = () => {
        if(Functions.verifyEmail(email) === false){
            return
        }
        let verify = Functions.checkPasswordRestrictions(senha)
        console.log(verify, senha)
        toggleErrorPass(false)
        if(!verify.lenght || !verify.lowercase || !verify.special || !verify.uppercase){
            toggleErrorPass(true)
            return
        }

        axios.post(process.env.REACT_APP_RECOVER_SEND_URL!, {email, celular})
            .then((response)=> {
                toggleContent("RESET")
            })
            .catch((error)=> {
                switch(error.response.status){
                    case 400:
                        dispatch({type: 'ADD_NOTIFICATION', status: 3, title: "Bad request"})
                        toggle(false)
                        break
                    case 403:
                        dispatch({type: 'ADD_NOTIFICATION', status: 2, title: error.response.data.msg})
                        break
                }
            })
    }

    const sendCode = () => {
        return <Body.Content>
            <Body.Text>Insira os quatro últimos digitos de seu telefone e seu email para prosseguir com a redefinição.</Body.Text>
                <NumberInput setText={setCelular} />
                <div style={{marginBottom: 10}} />
                <Input placeholder="EMAIL" text={email} 
                    setText={setEmail} error={errorPass} />
                <Body.PasswordContent>
                    <Body.Icon src={passVisible?EyeIcon:EyeSplashIcon} 
                            onClick={()=>toggleVisibility(old => !old)}/>
                    <Input placeholder="NOVA SENHA" text={senha} 
                        setText={setSenha} error={errorPass}
                        secret={passVisible} />
                </Body.PasswordContent>
                <div style={{marginBottom: 20}} />
                <Button text="Alterar" action={()=>send()}/>
            </Body.Content>
    }

    const sendNewPass = () => {

        axios.post(process.env.REACT_APP_RECOVER_RECOVER_URL!, {email, senha, codigo_tfa})
            .then((response) => {
                dispatch({type: 'ADD_NOTIFICATION', status: 1, title: 'Senha alterada com sucesso'})
                toggle(false)
            })
            .catch((error)=> {
                switch(error.response.status){
                    case 400:
                        dispatch({type: 'ADD_NOTIFICATION', status: 3, title: 'Bad request'})
                        toggle(false)
                        break
                    case 403:
                        dispatch({type: 'ADD_NOTIFICATION', status: 2, title: error.response.data.msg})
                        break
                }
            })
    }

    const resendCode = () => {
		axios.get(process.env.REACT_APP_RESEND_URL!, {withCredentials: true})
			.then((response) => {	
                dispatch({type: 'ADD_NOTIFICATION', status: 1, title: "Código reenviado com sucesso"})	
			})
			.catch((error) => {
                dispatch({type: 'ADD_NOTIFICATION', status: 3, title: "Erro ao reenviar código " + error.response.status})
			})
	}

    const changePassword = () => {
        return <Body.Content>
                <Body.Text>Enviamos um código de para seu telefone, insira-o e forneça a nova senha para prosseguir.</Body.Text>
                <CodeInput setText={setCode} />
                <div style={{marginBottom: 20}} />
                <Body.Row style={{alignItems: 'center', justifyContent: 'center',
                    gridGap: '0px 10px'}}>
                    <Button text="Continuar" action={sendNewPass} />
                    <Button text="Reenviar código" action={resendCode} />
                </Body.Row>
                </Body.Content>
    }

    const getContent = () => {
        switch(content){
            case "SEND_CODE":
                return sendCode()
            case "RESET":
                return changePassword()
        }
    }

    return <Modal title="Alterar Senha" toggleModal={toggle}>
        {getContent()}
    </Modal>
}

// export const ChangePhoto: React.FC <ModalProp> = ({
//     toggle, 
// }) => {
//     const [attachament, setAttachament] = useState()
//     const [mensagem, setMensagem] = useState("")
//     const navigate = useNavigate()

//     const send = () => {

//         axios.put(process.env.REACT_APP_PASSWORD_URL!, {})
//             .then((response)=> {
//                 switch(response.data.code){
//                     case 200:
//                         toggle(false)
//                         break
//                     case 403:
//                         setMensagem("Token/Código inválido")
//                         break
//                     case 500:
//                         alert("Erro interno")
//                         break
//                 }
//             })
//             .catch((error)=> {
//                 alert('Erro: '+ error)
//             })
//     }


//     return <Modal title="Alterar foto" toggleModal={toggle}>
//         <Body.Content>
//             <Body.Row style={{alignItems: 'center'}}>
//                 <Body.Photo style={{width: '150px', height: '150px'}}
//                     src={attachament?attachament:avatar} />
//                 <Button text="Selecionar arquivo..." />
//             </Body.Row>
//             <Button text="Alterar" width={40} action={()=>send()}/>
//         </Body.Content>
//     </Modal>
// }


type ModalProps = {
    toggleModal: Dispatch<SetStateAction<boolean>>,
    user?: Types.User,
    getUsers?: any
}


const EditUser: React.FC <ModalProps> = ({
    toggleModal, user, getUsers
}) => {

    const [p_nome, setNome] = useState<string | undefined>(undefined)
    const [s_nome, setSobrenome] = useState<string | undefined>(undefined)
    const [email, setEmail] = useState<string | undefined>(undefined)
    const [telefone_ddd, setDDD] = useState<string | undefined>(undefined)
    const [telefone_numero, setTelefone] = useState<string | undefined>(undefined)

    const [content, toggleContent] = useState("SEND")
    const [loading, toggleLoading] = useState(false)

    const [codigo_tfa, setCode] = useState("")

    const [confirmModal, toggleConfirm] = useState(false)

    const dispatch = useDispatch()

    const tryDelete = () => {
        const token = user?.token
        axios.delete(process.env.REACT_APP_DELETE_USER_URL!+"?token="+token, {withCredentials: true})
            .then((response)=>{
                dispatch({type: 'ADD_NOTIFICATION', status: 1, title: 'Usuário removido com sucesso'})
                getUsers()
                toggleModal(false)
            })
            .catch((error)=>{
                dispatch({type: 'ADD_NOTIFICATION', status: 3, title: error.response.data.msg})
            })
    }

    const tryChange = () => {
        const token = user?.token
        toggleLoading(true)
        axios.put(process.env.REACT_APP_UPDATE_USER_URL!, {token, email, p_nome, s_nome, telefone_ddd, telefone_numero},
            {withCredentials: true})
            .then((response)=>{
                toggleContent("INSERT")
                toggleLoading(false)
            })
            .catch((error)=>{
                toggleLoading(false)
                dispatch({type: 'ADD_NOTIFICATION', status: 3, title: error.response.data.msg})
            })
    }

    const telefoneInsert = (text: string) => {
        if(telefone_numero?.length === 0){
            setTelefone(text)
            return
        }
        setTelefone(Regex.numberRegex(text, telefone_numero!))
    }

    const sendContent = () => {
        return <>
            <Body.Text>{user?.p_nome} {user?.s_nome}</Body.Text>
            <Body.Row>
                <Body.Half>
                    <Input text={p_nome!} setText={setNome} placeholder="NOME"/>
                </Body.Half>
                <Body.Half>
                    <Input text={s_nome!} setText={setSobrenome} placeholder="SOBRENOME"/>
                </Body.Half>
            </Body.Row>
            <Body.Row>
                <Body.Half style={{width: '100%'}}>
                    <Input text={email!} setText={setEmail} placeholder="EMAIL"/>
                </Body.Half>
            </Body.Row>
            <Body.Row style={{justifyContent: 'flex-start'}}>
                <Body.Half style={{width: '15%', marginRight: '15px', marginLeft: '5px'}}>
                    <Input text={telefone_ddd!} setText={setDDD} placeholder="DDD"/>
                </Body.Half>
                <Body.Half style={{width: '84%'}}>
                    <Input text={telefone_numero!} maxLenght={10} setText={telefoneInsert} placeholder="TELEFONE"/>
                </Body.Half>
            </Body.Row>
            <div style={{marginBottom: 20}} />
            <Button text="Alterar" action={tryChange} />        
            </>
    }

     const trySendCode = () => {
        const token = user?.token

        axios.put(process.env.REACT_APP_UPDATE_USER_URL!, {token, email, p_nome, s_nome, telefone_ddd, telefone_numero, codigo_tfa},
            {withCredentials: true})
            .then((response)=>{
                dispatch({type: 'ADD_NOTIFICATION', status: 1, title: 'Usuário alterado com sucesso'})
                getUsers()
                toggleModal(false)
            })
            .catch((error)=>{
                dispatch({type: 'ADD_NOTIFICATION', status: 3, title: error.response.data.msg})
            })
    }

    const resendCode = () => {
		axios.get(process.env.REACT_APP_RESEND_URL!, {withCredentials: true})
			.then((response) => {	
                dispatch({type: 'ADD_NOTIFICATION', status: 1, title: "Código reenviado com sucesso"})	
			})
			.catch((error) => {
                dispatch({type: 'ADD_NOTIFICATION', status: 3, title: "Erro ao reenviar código " + error.response.status})
			})
	}


    const insertCode = () => {
        return <>
            <Body.Text>Enviamos um código de autenticação para seu celular, 
                insira-o para prossguir com alteração dos dados</Body.Text>
            <CodeInput setText={setCode} />
            <div style={{marginBottom: 20}} />
            <Body.Row style={{alignItems: 'center', justifyContent: 'center',
                gridGap: '0px 10px'}}>
                <Button text="Continuar" action={trySendCode} />
                <Button text="Reenviar código" action={resendCode} />
            </Body.Row>
        </>
    }

    const getContent = () => {
        switch(content){
            case "SEND":
                return sendContent()
            case "INSERT":
                return insertCode()     
        }
    }

    return <> <Modal title="Alterar dados" remove={()=>toggleConfirm(true)} toggleModal={toggleModal}>
       <Body.Content>
        {getContent()}
       {loading? <Loading />:null}
       </Body.Content>
    </Modal> 
       {confirmModal?<Modal title="Deseja confirmar exlcusão?" >
        <div style={{display: 'flex', gridGap: '0px 20px' }}>
            <Button text="Confirmar" bg="#FF0000" bordercolor="#FF0000" action={tryDelete} />
            <Button text="Recusar" action={()=>toggleConfirm(false)} />
        </div>
       </Modal>:null}
    </>
}


export const EditUsers: React.FC <ModalProps> = ({
    toggleModal
}) => {

    const [users, setUsers] = useState<Types.UserList>()
    const [editUser, toggleEditUser] = useState(false)
    const [user, setUser] = useState<Types.User>()
    const dispatch = useDispatch()

    const selectUser = (item: Types.User) => {
        setUser(item)
        toggleEditUser(true)
    }

    const getData = () => {
        axios.get(process.env.REACT_APP_USERS_URL!, {withCredentials: true})
            .then((response)=>{
                setUsers(response.data)
            })
            .catch((error)=>{
                dispatch({type: 'ADD_NOTIFICATION', status: 2, title: error.response.data.msg})
            })
    }

    useEffect(()=>{
        getData()
    },[])

    return <><ListModal toggleModal={toggleModal} title="Editar usuários">
        <Body.UsersContent>
            {users?.map((item, index) => 
                <Body.UserBtn key={index.toString()} onClick={()=>selectUser(item)}>
                    <Body.Pefil style={{marginBottom: 0, alignItems: 'center'}}>
                        <Body.PerfilTextContent>
                            <Body.PerfilName style={{fontSize: '18px'}}>
                                {item.p_nome} {item.s_nome}
                            </Body.PerfilName>
                        </Body.PerfilTextContent>
                    </Body.Pefil>
                </Body.UserBtn>
                )}
        </Body.UsersContent>
    </ListModal>
    {editUser?<EditUser toggleModal={toggleEditUser} user={user!} getUsers={getData} />:null}
    </>
}