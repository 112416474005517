import styled from "styled-components";
import DatePicker from 'react-datepicker'

export const ChartCard = styled.div`
    height: 100%;
    width: calc(100% - 10px);
    background: rgba(94, 94, 94, 0.1);
    backdrop-filter: blur(4px);
    border: none;
    border-radius: 20px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
export const MultipleCharts = styled.div`
    width: 100%;
    height: 50%;
    `

export const Content = styled.div`
    height: calc(100% - 80px);
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    grid-gap: 15px 2%;
    justify-content: flex-start;
    overflow-y: scroll;
    ::-webkit-scrollbar{
        display:none;
    }
`
export const Color = styled.div<{color: string}>`
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background-color: ${props => props.color};
    margin-right: 10px;
`