import { MoendaState, NotificationsType } from "../types";
import { MoendaAction, SELECT_MOENDA } from "./actionsType";
import { dataMoenda } from "../data";

const notificacoes: NotificationsType[] = ([])

const initialState: MoendaState = dataMoenda[0]

const moenda = (
    state: MoendaState = initialState,
    action: MoendaAction  
) => {
    return state;
}

export default moenda