import React, {Dispatch, SetStateAction, useState, useRef, useEffect} from "react";
import * as Body from './Styles'
import * as functions from '../../utils/functions/functions'
import axios from "axios";
import Loading from "../../components/Loading/Loading";

/*Components*/
import Input from '../../components/Input/Input'
import { useNavigate } from "react-router-dom";

import EyeIcon from '../../assets/icons/eye-regular.svg'
import EyeSplashIcon from '../../assets/icons/eye-slash-regular.svg'
import {useDispatch} from 'react-redux'
import CodeInput from "../../components/CodeInput/CodeInput";

type Props = {
    toggleAuth: Dispatch<SetStateAction<boolean>>
}

type LoginProps = {
    setEmailLogin: Dispatch<SetStateAction<string>>,
    setPasswordLogin: Dispatch<SetStateAction<string>>,
    toggleContent: Dispatch<SetStateAction<string>>,
    setName: Dispatch<SetStateAction<string>>,
    toggleLoading: Dispatch<SetStateAction<boolean>>
}

type AuthProps = {
    name: string,
    email: string,
    senha: string,
    toggleLoading: Dispatch<SetStateAction<boolean>>
}

const DinamoLogo = require('../../assets/images/dinamo_logo.png')

const LoginContent: React.FC <LoginProps> = ({setEmailLogin, setPasswordLogin, toggleContent, setName, toggleLoading}) => {
    const [email, setEmail] = useState("")
    const [senha, setPassword] = useState("")
    const [seePassword, toggleSee] = useState(true)
    const [errorPass, toggleErrorPass] = useState(false)

    const [errorEmail, toggleErrorEmail] = useState(false)
    const navigate = useNavigate()

    const dispatch = useDispatch()

    const login = () => {
        const verify = functions.checkCredentials(email, senha)
        if(verify.email && verify.password){
            toggleErrorPass(false)
            toggleErrorEmail(false)
            loginRequest()
            return
        }else if(verify.email && !verify.password){
            toggleErrorPass(false)
            toggleErrorEmail(true)
        }else if(!verify.email && !verify.password){
            toggleErrorPass(true)
            toggleErrorEmail(true)
        }else if(!verify.email && verify.password){
            toggleErrorPass(true)
            toggleErrorEmail(false)
        }
    }

    const loginRequest = () => {
        toggleLoading(true)
        
        axios.post('https://www.dhm.dinamoautomacao.com.br/api/login', {email, senha}, {withCredentials: true})
            .then((response)=>{
                setEmailLogin(email)
                setPasswordLogin(senha)
                toggleLoading(false)
                toggleContent("AUTH")
            })
            .catch((error)=>{
                if(error.response.data.code === "TFA-3"){
                    toggleContent("AUTH")
                    toggleLoading(false)
                    return
                }
                toggleLoading(false)
                functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            })
    }

    


    return <>
        <Input text={email} setText={setEmail} 
                placeholder="EMAIL" error={errorEmail} />
            <Body.PasswordContent>  
                <Input text={senha} setText={setPassword}
                    secret={seePassword}  placeholder="SENHA"
                    error={errorPass} />
                <Body.Icon src={seePassword?EyeIcon:EyeSplashIcon} 
                    onClick={()=>toggleSee(old => !old)}/>
            </Body.PasswordContent>
            <Body.ButtonLogin onClick={()=>login()}>
                Entrar
            </Body.ButtonLogin>
            <Body.TextBtn onClick={()=>window.open("https://www.conecta.tectrolnet.com.br","_blank")}>Esqueceu sua senha?</Body.TextBtn>    
    </>
}

const AuthLogin: React.FC <AuthProps> = ({
    name, email, senha, toggleLoading
}) => {
    const navigate = useNavigate()
    const [codigo_tfa, setCode] = useState("")

    const dispatch = useDispatch()

    const isLogged = () => {
        try{
            localStorage.setItem("@logged", "true")
        }
        catch(e){
            console.log(e)
        }
    }

    const sendAuth = () => {
        toggleLoading(true)
        axios.post('https://www.dhm.dinamoautomacao.com.br/api/login', {email, senha,  tfaCode: codigo_tfa},  {withCredentials: true} )
            .then((response) => {
                isLogged()
                navigate("/inicio")
                toggleLoading(false)
            })
            .catch((error)=>{
                toggleLoading(false)
                functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            })

    }

    const resendCode = () => {
        toggleLoading(true)
		axios.post('https://www.dhm.dinamoautomacao.com.br/api/login', {email, senha}, {withCredentials: true})
			.then((response) => {	
                toggleLoading(false)
                dispatch({type: 'ADD_NOTIFICATION', status: 1, title: "Código reenviado com sucesso"})	
			})
			.catch((error) => {
                toggleLoading(false)
                functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
			})
	}

    return <>
            <Body.InputTitle style={{marginBottom: 0, fontSize: '16px'}}>
                Enviamos um código de segurança para seu celular. Insira-o para prosseguirmos 
                com seu login</Body.InputTitle>
            <CodeInput setText={setCode} />
            <Body.ButtonLogin onClick={()=>sendAuth()}>
                Continuar
            </Body.ButtonLogin>  
            <Body.TextBtn onClick={()=>resendCode()}>Reenviar código</Body.TextBtn>
            </>
}

const Login: React.FC = () => {
    const navigate = useNavigate()

    const [name, setName] = useState("")
    const [email, setEmailLogin] = useState("")
    const [password, setPasswordLogin] = useState("") 
    const [content, toggleContent] = useState("LOGIN")
    const [loading, toggleLoading] = useState(false)

    useEffect(()=>{
        let isMounted = true;

        const getLogged = async () => {
            try {
              let isLogged = localStorage.getItem("@logged");
              if (isLogged) {
                navigate("/inicio")
              }
            } catch (error) {
            } finally {
                if(isMounted){
                    toggleLoading(false);
                }
            }
          };
        
          getLogged();

          return () => {
            isMounted = false;
          };
    }, [])
   
    const getContent = () => {
        switch(content){
            case "LOGIN":
                return <LoginContent setEmailLogin={setEmailLogin} setPasswordLogin={setPasswordLogin}
                    toggleContent={toggleContent} setName={setName} toggleLoading={toggleLoading} />
            case "AUTH":
                return <AuthLogin name={name} email={email} senha={password} toggleLoading={toggleLoading} />
        }
        return <></>
    }

   
    return <Body.Container >
        <div style={{display: 'flex'}}>
           <div>
           <Body.Title>
                DHM4.0
            </Body.Title>
            <Body.Subtitle>
                App de monitoramento
            </Body.Subtitle>
           </div>
        </div>
        <Body.Card>
            <Body.CardTitle> 
                Login
            </Body.CardTitle>
            {getContent()}

        </Body.Card>
        <Body.Logo src={DinamoLogo} />
        <Body.Wave1 />
        <Body.Wave2 />
        <Body.Wave3 />
        {loading?<Loading />:null}
    </Body.Container>
}

export default Login