import styled , {keyframes} from 'styled-components'
import * as Define from '../../utils/defines/defines'

export const Container = styled.div`    
    z-index: 90;
`

export const AnimateOpen = keyframes`
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
`

export const Notify = styled.div`
    width: 350px;
    background: ${Define.ModalBackground};
    border-radius: 20px;
    padding: 10px;
    box-sizing: border-box;
    animation-name: ${AnimateOpen};
    animation-duration: .3s;
    animation-timing-function: ease-in;
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    z-index: 4;
`

export const Row = styled.div`
    display: flex;
`

export const Circle = styled.div<{color: string}>`
    width: 28px;
    height: 28px;
    border: 2px solid ${props => props.color};
    border-radius: 50%;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
`

export const Icon = styled.img`
    height: 16px;
`

export const Title = styled.p`
    margin: 0;
    font-family: "Nunito";
    font-weight: 600;
    font-size: 16px;
    color: rgba(255,255,255,0.8)
`

export const Text = styled.p`
    margin: 0;
    font-family: "Nunito";
    font-weight: 300;
    font-size: 14px;
    color: rgba(255,255,255,0.8)
`


export const TextContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;    
    width: calc(100% - 50px)
`