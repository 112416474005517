import { User } from "../types"

export type NotificationProps = {
    type?: string,
    status: number,
    title: string,
    link?: boolean,
    text?: string
}

export const ADD_NOTIFICATION = "ADD_NOTIFICATION"
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION"