import styled, {keyframes} from 'styled-components'
import * as Define from '../../utils/defines/defines'

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background: ${Define.BackgroundContainer};
    display: flex;
    padding: 40px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background-size: cover;
`


export const Title = styled.p`
    margin: 0;
    font-family: "Montserrat";
    background: -webkit-linear-gradient(45deg, rgb(62, 147, 216), rgb(76, 185, 49));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    font-weight: bold;
    font-size: 48px;
    letter-spacing: 1.5px;
`

export const Subtitle = styled.p`
    margin: 0;
    font-family: 'Nunito';
    font-size: 20px;
    color: rgba(255,255,255,0.5);
`


export const Row = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
`
export const ButtonImg = styled.img`
    width: 45%;
`

export const TextBtn = styled.p`
    margin: 0;
    color: rgba(255,255,255,0.5);
    cursor: pointer;
    text-transform: uppercase;

    font-family: 'Nunito';
    font-size: 12px;
    letter-spacing: 1.5px;
    margin-top: 20px;
    width: 100%;
    text-align: right;
    transition: .3s;
    :hover{
        transition: .3s;
        color: #fff;
    }
`

export const Logo = styled.img`
    width: 150px;
    position: absolute;
    bottom: 40px;
    z-index: 2;
`

const animateWave1 = keyframes`
    0%{bottom: -300px;}
    30%{bottom: -200px;}
    100%{bottom: -300px;}
`

const animateWave2 = keyframes`
    0%{bottom: -300px;}
    10%{bottom: -300px;}
    40%{bottom: -200px}
    100%{bottom: -300px;}
`
const animateWave3 = keyframes`
    0%{bottom: -300px;}
    20%{bottom: -300px;}
    50%{bottom: -200px}
    100%{bottom: -300px;}
`

const AnimateDuration = 5

export const Wave1 = styled.div`
    position: absolute;
    background-color: #5292D1;
    height: 250px;
    width: 20vw;
    z-index: 1;
    border-radius: 50px;
    left: 20vw;
    transition: .5s ease-in-out;    
    animation-name: ${animateWave1};
    animation-duration: ${AnimateDuration}s;
    animation-iteration-count: infinite;
    filter: blur(4em)
`
export const Wave2 = styled.div`
    position: absolute;
    background-color: #5292D1;
    height: 20vw;
    width: 20vw;
    z-index: 1;
    border-radius: 50px;
    transition: .5s ease-in-out;
    animation-name: ${animateWave2};
    animation-duration: ${AnimateDuration}s;
    animation-iteration-count: infinite;
    filter: blur(4em);
`
export const Wave3 = styled.div`
    position: absolute;
    background-color: #5292D1;
    height: 250px;
    width: 20vw;
    z-index: 1;
    border-radius: 50px;
    left: 40vw;
    transition: .5s ease-in-out;
    animation-name: ${animateWave3};
    animation-duration: ${AnimateDuration}s;
    animation-iteration-count: infinite;
    filter: blur(4em)
`