import * as Body from './Styles'

import warningIcon from '../../assets/icons/warning-icon.svg'
import checkIcon from '../../assets/icons/check-icon.svg'
import errorIcon from '../../assets/icons/error-icon.svg'
import { useEffect, useRef } from 'react'

import { useSelector, useDispatch } from "react-redux";
import { State } from "../../utils/redux/store";
import Button from '../Button/Button'
import { Link } from 'react-router-dom'

type NotifyProps = {
    status: number,
    title: string,
    link?: true,
    text?: string
}

const STATUS_CODE = {
    OK: 1,  
    WARNING: 2,
    ERROR: 3
}

const Notify = () => {
    const notifications = useSelector((state: State) => state.notifications)
    const dispatch = useDispatch()

    const timer = useRef(0)

    const getColor = (status: number) => {
        switch(status){
            case STATUS_CODE.OK:
                return "#C5EB7F"
            case STATUS_CODE.WARNING:
                return "#E5D34A"
            case STATUS_CODE.ERROR:
                return '#F03F3F'
            default:
                return "#fff"
        }
    }

    const getIcon = (status: number) => {
        switch(status){
            case STATUS_CODE.OK:
                return checkIcon
            case STATUS_CODE.WARNING:
                return warningIcon
            case STATUS_CODE.ERROR:
                return errorIcon
            default:
                return "#fff"
        }
    }


    useEffect(()=>{
        if(notifications.length > 0){
            const interval = setInterval(()=>{
                timer.current -= 1
    
                if(timer.current == 0){
                    dispatch({type:'REMOVE_NOTIFICATION'})           
                }else if(timer.current == -1){
                    timer.current = 5
    
                }
                
            },  1000)
    
            return function cleanup(){
                clearInterval(interval)
            }
        }

    },[notifications])

    return <Body.Container>
        {notifications?.map((item, index)=>{
            return <Body.Notify key={index.toString()} onClick={()=>dispatch({type: 'REMOVE_NOTIFICATION'})}
                        style={{bottom: 'calc(20px + '+(105 * index)+'px' }}>
                    <Body.Row>
                        <Body.Circle color={getColor(item.status)} >
                            <Body.Icon src={getIcon(item.status)} />
                        </Body.Circle>
                        <Body.TextContent>
                            <Body.Title>
                                {item.title}
                            </Body.Title>
                            {item.text?<Body.Text>{item.text}</Body.Text>:null}
                        </Body.TextContent>
                    </Body.Row>
                    {item.link?<Button action={()=>window.open("https://www.conecta.tectrolnet.com.br/", '_blank')} text='Acessar Conecta' />:null}
            </Body.Notify>})}
    </Body.Container>
}

export default Notify