import { ADD_INTERVENTION, InterventionAction, SELECT_INTERVENTION } from "./actionType";
import { data } from "../data";
import { Intervencao } from "../types";

const intervencoes = (
    state: Intervencao[] = data[0].intervencoes,
    action: InterventionAction,
) => {
    return state
}

export default intervencoes