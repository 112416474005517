import * as Body from './Styles'
import ListModal from '../ListModal/ListModal'
import { Dispatch, SetStateAction } from 'react'
import { title } from 'process'

type Props = {
    items: string[],
    selectItem: any,
    toggleModal: any,
    title: string
}

const SelectItem: React.FC <Props> = ({items, selectItem, toggleModal, title }) => {

    const select = (item: string) => {
        selectItem(item)
        toggleModal()
    }

    return <ListModal toggleModal={toggleModal} title={title} zindex={99999} >
        <>
        {items.map((item, index) => {
            return <Body.Button key={index.toString()} onClick={()=>select(item)}>
                {item}
            </Body.Button>
        })}
        </>
    </ListModal>
}

export default SelectItem