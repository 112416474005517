import styled, {keyframes} from 'styled-components'
import * as Define from '../../utils/defines/defines'

const ButtonAnimate = keyframes`
    0%{
        background: -webkit-linear-gradient(65deg, ${Define.tecdinBlue}, ${Define.tecdinGreen});
    },
    33%{
        background: ${Define.tecdinBlue};
    },
    66%{
        background: -webkit-linear-gradient(65deg, ${Define.tecdinGreen},  ${Define.tecdinBlue});
    },
    100%{
        background: ${Define.tecdinGreen}
    }
`

export const Button = styled.button`
    height: 45px;
    border: none;
    border-radius: 25px;
    background: -webkit-linear-gradient(65deg, ${Define.tecdinGreen}, ${Define.tecdinBlue});

    color: #000;
    font-family: "Nunito";
    font-size: 18px;
    cursor: pointer;
    transition: .3s;

    :hover{
        opacity: 0.8;
        animation: 5s ${ButtonAnimate} linear;
    }

    :active{
        transition: .3s;
    }

`