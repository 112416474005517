import React, { useEffect, useState } from "react";
import {
    BrowserRouter ,
    Routes,
    Route
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "../redux/store";
import { isMobile } from "react-device-detect";

import Login from "../../pages/login/Login";
import Recover from "../../pages/login/recover/Recover";
import Home from "../../pages/home/Home";
import Config from "../../pages/config/Config";
import Moenda from "../../pages/moenda/Moenda";
import Charts from "../../pages/moenda/charts/Charts";
import Notify from "../../components/Notify/Notify";
import Mobile from "../../pages/unsupporteDevice/Mobile";
import LGPD from "../../pages/lgpd/LGPD";
import Privacy from "../../pages/privacy/Privacy";
import PassRetrieve from "../../pages/passRetrieve/PassRetrieve";
import WaitComponent from "../../components/Wait/Wait";

const RoutesApp = () => {

    if(isMobile){
        return <Mobile />
    }

    return <BrowserRouter>
            <Provider store={store}>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/recuperar" element={<Recover />} />
                <Route path="/inicio" element={<Home />} />
                <Route path="/privacidade" element={<Privacy />} />
                <Route path="/LGPD" element={<LGPD />} />
                {/* <Route path="/config" element={<Config />} /> */}
                <Route path="/moenda" element={<Moenda />} />
                <Route path="/charts" element={<Charts />} />
                <Route path="/senha-expirada" element={<PassRetrieve />} />
            </Routes>
            <Notify />
            <WaitComponent />
            </Provider>
    </BrowserRouter>
}

export default RoutesApp