import React, {SetStateAction, Dispatch, useState, useEffect} from 'react';
import * as Body from './Styles'
import axios from "axios";
import Loading from "../../components/Loading/Loading";
import ButtonGradient from "../../components/ButtonGradient/ButtonGradient";

/*Components*/
import Input from '../../components/Input/Input'
import { useNavigate } from "react-router-dom";
import CodeInput from '../../components/CodeInput/CodeInput';
import EyeIcon from '../../assets/icons/eye-regular.svg'
import EyeSplashIcon from '../../assets/icons/eye-slash-regular.svg'
import LockIcon from '../../assets/icons/lock.svg'
import Shield from '../../assets/icons/shield.svg'
import ShieldIcon from '../../assets/icons/shield-icon.svg'
import DeleteIcon from '../../assets/icons/delete-icon.svg'
import TrashIcon from '../../assets/icons/trash-icon.svg'
import PhoneIcon from '../../assets/icons/phone-block.svg'
import {useDispatch} from 'react-redux'
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';

const DinamoLogo = require('../../assets/images/dinamo_logo.png')

type Props = {
    toggleContent: Dispatch<SetStateAction<string>>,
}

type AgreeProps = {
    toggleContent: Dispatch<SetStateAction<string>>,
    toggleLoading: Dispatch<SetStateAction<boolean>>,
    action: any
}

type DeleteProps = {
    toggleContent: Dispatch<SetStateAction<string>>,
    toggleModal: Dispatch<SetStateAction<boolean>>,
    setCode: Dispatch<SetStateAction<string>>,
    setSenha: Dispatch<SetStateAction<string>>,
    senha: string
}

type ModalProp = {
    toggleModal: Dispatch<SetStateAction<boolean>>,
    action: any
}

const ModalDelete: React.FC <ModalProp> = ({
    toggleModal, action
}) => {
    return <Modal title='Confirmação' toggleModal={toggleModal} >
        <Body.ModalContent>
        <div style={{display: 'flex', marginBottom: 10, alignItems: 'center'}}>
            <Body.Icon src={TrashIcon} style={{marginRight: 10, width: 20}}/>
            <Body.Text>
                <strong>Todos os dados</strong> armazenados sobre você serão 
                <strong> excluídos.</strong>
            </Body.Text>
        </div>
        <div style={{display: 'flex', alignItems: 'center', marginBottom: 20}}>
            <Body.Icon src={PhoneIcon} style={{marginRight: 10,  width: 20}}/>
            <Body.Text>
                <strong>Você perderá acesso ao aplicativo web e mobile</strong>, 
                e só poderá acessá-los novamente caso alguem realize seu cadastro.
            </Body.Text>
        </div>
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <Button text='Confirmar' action={action} />
        </div>
        </Body.ModalContent>
    </Modal>

}

const Agree: React.FC <AgreeProps> = ({toggleContent, toggleLoading, action}) => {

    return <Body.Card>
        <div style={{display: 'flex', alignItems: 'center', marginBottom: 35, justifyContent: 'space-between'}}>
            <Body.CardTitle style={{fontWeight: 300, marginBottom: 0, width: 'calc(100% - 100px)'}}> 
                Seu direito à privacidade
            </Body.CardTitle>
            <Body.HeaderIcon src={ShieldIcon} />
        </div>
        <Body.Text style={{marginBottom: 10}}>
        <strong>Acreditamos que você deve estar ciente dos dados que coletamos de você</strong>.
         Ao utilizar o nosso app, essas são as informações que armazenamos sobre você:
        </Body.Text>
        <Body.Text style={{marginBottom: 20}}>
            <strong>
            · Nome completo<br/>
            · E-mail<br/>
            · Número de celular<br/>
            · Dispositivos e endereços IPs utilizados para acesso ao app
            </strong>
        </Body.Text>
        <div style={{display: 'flex', marginBottom: 10, alignItems: 'center'}}>
            <Body.Icon src={Shield} style={{marginRight: 10}}/>
            <Body.Text>
                Todos os seus dados são criptografados e armazenados em um ambiente seguro.
            </Body.Text>
        </div>
        <div style={{display: 'flex', alignItems: 'center'}}>
            <Body.Icon src={LockIcon} style={{marginRight: 10}}/>
            <Body.Text>
                Nós não compartilhamos seus dados com terceiros.
            </Body.Text>
        </div>
        <ButtonGradient text="Aceito o uso dos dados" margintop={25} action={action}/>
        <Body.TextBtn style={{textAlign: 'center'}} onClick={()=>toggleContent("REFUSE")}>
            Não aceito o uso dos dados
        </Body.TextBtn>
    </Body.Card>
}

const Refuse: React.FC <Props> = ({toggleContent}) => {
    const dispatch = useDispatch()

    const send = () => {
        axios.post(process.env.REACT_APP_DELETE_FOR_PRIVACY_URL!, {withCredentials: true})
            .then(()=>{
                toggleContent("DELETE")
            })
            .catch(()=>{
                dispatch({type: 'ADD_NOTIFICATION', status: 3, title: "Erro ao enviar código"})
            })
    }

    return <Body.Card>
        <div style={{display: 'flex', alignItems: 'center', marginBottom: 35, justifyContent: 'space-between'}}>
            <Body.CardTitle style={{fontWeight: 300, marginBottom: 0, width: 'calc(100% - 100px)'}}> 
                Seu direito à privacidade
            </Body.CardTitle>
            <Body.HeaderIcon src={ShieldIcon} />
        </div>
        <Body.Text style={{marginBottom: 10}}>
        <strong>Para utilizar o app, você precisa aceitar o uso de dados</strong>.
         Se não aceitar, as seguintes ações serão realizadas:
        </Body.Text>
        <div style={{display: 'flex', marginBottom: 10, alignItems: 'center'}}>
            <Body.Icon src={TrashIcon} style={{marginRight: 10, width: 20}}/>
            <Body.Text>
                <strong>Todos os dados</strong> armazenados sobre você serão 
                <strong> excluídos.</strong>
            </Body.Text>
        </div>
        <div style={{display: 'flex', alignItems: 'center'}}>
            <Body.Icon src={PhoneIcon} style={{marginRight: 10,  width: 20}}/>
            <Body.Text>
                <strong>Você perderá acesso ao aplicativo web e mobile</strong>, 
                e só poderá acessá-los novamente caso alguem realize seu cadastro.
            </Body.Text>
        </div>
        <ButtonGradient text="Voltar" action={()=>toggleContent("AGREE")} margintop={25}/>
        <Body.TextBtn style={{textAlign: 'center'}} onClick={()=>send()}>
            Não aceito o uso dos dados
        </Body.TextBtn>
    </Body.Card>
}

const Delete: React.FC <DeleteProps> = ({toggleContent, toggleModal, setCode, setSenha, senha}) => {
    const [seePass, toggleSee] = useState(false)

    return <Body.Card>
        <div style={{display: 'flex', alignItems: 'center', marginBottom: 35, justifyContent: 'space-between'}}>
            <Body.CardTitle style={{fontWeight: 300, marginBottom: 0, width: 'calc(100% - 100px)'}}> 
                Excluir conta e dados salvos
            </Body.CardTitle>
            <Body.HeaderIcon src={DeleteIcon} />
        </div>
        <Body.Text style={{marginBottom: 10}}>
            <strong>
                Enviamos um código de verificação para seu número de celular.
            </strong>
        </Body.Text>
        <Body.Text>
            Insira-o abaixo, junto com sua senha atual, para prosseguir com a exclusão de sua conta e dados.
        </Body.Text>
        <CodeInput setText={setCode} />
        <div style={{marginBottom: 20}}/>
        <Body.PasswordContent >
            <Input text={senha} setText={setSenha} secret={seePass} placeholder="SENHA" />
            <Body.EyeIcon src={seePass?EyeIcon:EyeSplashIcon} onClick={()=>toggleSee(old => !old)} />
        </Body.PasswordContent>
        <div style={{marginBottom: 25}}/>
        <Button text='Excluir conta e dados' bg="#FF0000" bordercolor='#FF0000' 
            action={()=>toggleModal(true)}/>
        <ButtonGradient text='Voltar' action={()=>toggleContent("REFUSE")}
            margintop={10} />
    </Body.Card>
}


const Privacy = () => {
    const [loading, toggleLoading] = useState(false)
    const [content, toggleContent] = useState("AGREE")
    const [modalDelete, toggleModal] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [codigo_tfa, setCode] = useState("")
    const [senha, setSenha] = useState("")

    const getContent = () => {
        switch(content){
            case "AGREE":
                return <Agree toggleContent={toggleContent} toggleLoading={toggleLoading}
                    action={send}/>
            case "REFUSE":
                return <Refuse toggleContent={toggleContent} />
            case "DELETE":
                return <Delete toggleContent={toggleContent} toggleModal={toggleModal} 
                        senha={senha} setCode={setCode} setSenha={setSenha}/>
        }
    }
    const send = () => {
        toggleLoading(true)
        axios.post(process.env.REACT_APP_LGPD_URL!, {}, {withCredentials: true})
            .then(()=>{
                navigate("/inicio")
                dispatch({type: "ADD_NOTIFICATION", status: 1, title: "Termo aceito com sucesso"})
                toggleLoading(false)
            })
            .catch((error)=>{
                dispatch({type: "ADD_NOTIFICATION", status: 3, title: error.response.data.msg})
                toggleLoading(false)
            })

    }

    const deleteUser = () => {
        toggleLoading(true)
        axios.post(process.env.REACT_APP_DELETE_FOR_PRIVACY_URL!, {senha, codigo_tfa},
             {withCredentials: true})
            .then(()=>{
                dispatch({type: 'ADD_NOTIFICATION', status: 1, title: "Usuário excluido com sucesso"})
                navigate("/")
                toggleLoading(false)
            })
            .catch(()=>{
                dispatch({type: 'ADD_NOTIFICATION', status: 3, title: "Erro ao excluir usuário"})
                toggleLoading(false)
            })
    }

    return <Body.Container >
            <div style={{display: 'flex'}}>
            <div>
            <Body.Title>
                    DHM4.0
                </Body.Title>
                <Body.Subtitle>
                    App de monitoramento
                </Body.Subtitle>
            </div>
                <Body.Subtitle style={{fontSize: '20px', marginTop: '15px'}}>
                    Beta
                </Body.Subtitle>
            </div>
            {getContent()}
            <Body.Logo src={DinamoLogo} />
            <Body.Wave1 />
            <Body.Wave2 />
            <Body.Wave3 />
            {loading?<Loading />:null}
            {modalDelete?<ModalDelete toggleModal={toggleModal} action={deleteUser}/>:null}
        </Body.Container>
}

export default Privacy