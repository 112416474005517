import {createGlobalStyle} from "styled-components"

import MontserratLight from './assets/fonts/Montserrat/Montserrat-Light.ttf';
import MontserratRegular from "./assets/fonts/Montserrat/Montserrat-Regular.ttf"
import MontserratSemiBold from "./assets/fonts/Montserrat/Montserrat-SemiBold.ttf"
import MontserratSemiBoldItalic from "./assets/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf"
import MontserratBold from "./assets/fonts/Montserrat/Montserrat-Bold.ttf"

import NunitoExtraLight from "./assets/fonts/Nunito/Nunito-ExtraLight.ttf"
import NunitoLight from "./assets/fonts/Nunito/Nunito-Light.ttf"
import NunitoRegular from "./assets/fonts/Nunito/Nunito-Regular.ttf"
import NunitoItalic from "./assets/fonts/Nunito/Nunito-Italic.ttf"
import NunitoSemiBold from "./assets/fonts/Nunito/Nunito-SemiBold.ttf"
import NunitoSemiBoldItalic from "./assets/fonts/Nunito/Nunito-SemiBoldItalic.ttf"
import NunitoBold from "./assets/fonts/Nunito/Nunito-Bold.ttf"
import NunitoBoldItalic from "./assets/fonts/Nunito/Nunito-BoldItalic.ttf"

import RobotoRegular from "./assets/fonts/Roboto/Roboto-Regular.ttf"
import RobotoItalic from "./assets/fonts/Roboto/Roboto-Italic.ttf"

import SignaBold from "./assets/fonts/Signa/Signa-Bold.ttf"

export const GlobalStyles = createGlobalStyle`

  @font-face {
    font-family: "Montserrat";
    font-style: light;
    font-weight: 400;
    src: url(${MontserratLight});
  }

  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    src: url(${MontserratRegular});
  }

  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    src: url(${MontserratSemiBold});
  }

  @font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 600;
    src: url(${MontserratSemiBoldItalic});
  }

  @font-face {
    font-family: "Montserrat";
    font-style: bold;
    font-weight: bold;
    src: url(${MontserratBold});
  }

  @font-face {
    font-family: "Nunito";
    font-style: extra-light;
    font-weight: 300;
    src: url(${NunitoExtraLight});
  }

  @font-face {
    font-family: "Nunito";
    font-style: light;
    font-weight: 400;
    src: url(${NunitoLight});
  }

  @font-face {
    font-family: "Nunito";
    font-style: normal;
    font-weight: normal;
    src: url(${NunitoRegular});
  }

  @font-face {
    font-family: "Nunito";
    font-style: italic;
    font-weight: normal;
    src: url(${NunitoItalic});
  }

  @font-face {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    src: url(${NunitoSemiBold});
  }
  @font-face {
    font-family: "Nunito";
    font-style: normal;
    font-weight: bold;
    src: url(${NunitoBold});
  }

  @font-face {
    font-family: "Nunito";
    font-style: italic;
    font-weight: bold;
    src: url(${NunitoBoldItalic});
  }

  @font-face {
    font-family: "Nunito";
    font-style: italic;
    font-weight: 600;
    src: url(${NunitoSemiBoldItalic});
  }




  @font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    src: url(${RobotoRegular});
  }

  @font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: normal;
    src: url(${RobotoItalic});
  }



  @font-face {
    font-family: "Signa";
    font-style: normal;
    font-weight: bold;
    src: url(${SignaBold});
  }

`