import React, {Dispatch, ReactChild, SetStateAction, useRef, useState } from 'react'
import * as Body from './Styles'

import {Button} from '../Button/Styles'
import closeIconActive from '../../assets/icons/close-icon-active.svg'
import closeIconInactive from '../../assets/icons/close-icon.svg'
import trashIcon from '../../assets/icons/trash-icon.svg'
import saveIcon from '../../assets/icons/save.svg'
import uploadIcon from '../../assets/icons/upload.svg'
import Spinner from '../../assets/icons/spinner.svg'

type Props = {
    toggleModal?: Dispatch<SetStateAction<boolean>>,
    title?: string;
    subtitle?: string,
    remove?: any,
    terno?: boolean,
    manual?: boolean,
    funcionando?: boolean,
    sendParams?: any,
    save?: any,
    width?: number,
    loading?: boolean,
    sending?: boolean,
    children?: ReactChild
}

const Modal: React.FC <Props> = ({
    toggleModal, 
    title, 
    subtitle,
    remove,
    save,
    manual,
    terno,
    funcionando,
    sendParams,
    loading,
    width,
    sending,
    children
}) => { 
    const modalRef = useRef<any>()
    const [closeIcon, toggleClose] = useState(closeIconInactive)

    return <Body.Container>
        <Body.Modal ref={modalRef} style={width && loading? {width: width+"px"}:{}}>
            <Body.Header>
                <Body.Row style={{alignItems: 'flex-start'}}>
                    <Body.TitleContent>
                        {title?<Body.Title>{title}</Body.Title>:null}
                        {subtitle?<Body.SubTitle>{subtitle}</Body.SubTitle>:null}
                    </Body.TitleContent>
                    {terno?<>
                    <Body.CircleTitle color='#FFF' title={manual?'Manual':'Automático'}>
                        <Body.TextoCircle>{manual?'Manual':'Automático'}</Body.TextoCircle>
                    </Body.CircleTitle>
                    <Body.CircleTitle color={funcionando?'#92B258':'#F03F3F'} 
                        style={{background: funcionando?'#92B258':'#F03F3F'}} 
                        title={funcionando?'Moendo':'Parado'}>
                            <Body.TextoCircle>{funcionando?"Moendo":"Parado"}</Body.TextoCircle>
                        </Body.CircleTitle>
                    </>
                    :null
                    }
                </Body.Row>
                <Body.Row>
                    {remove?<Body.DeleteBtn onClick={remove} >
                        <Body.BtnIcon src={trashIcon}/>
                    </Body.DeleteBtn>:null}
                    {save?<Body.DeleteBtn onClick={save} style={{backgroundColor: 'green', 
                        border: '#C5EB7F'}}>
                        <Body.BtnIcon src={saveIcon}/>
                    </Body.DeleteBtn>:null}
                    {sendParams?<Body.DeleteBtn onClick={sendParams} style={{backgroundColor: 'green', gridGap: '0px 5px',
                        border: '#C5EB7F'}} disabled={loading}>
                        <Body.BtnIcon src={uploadIcon}/>
                        <Body.TextoCircle>Enviar parâmetros</Body.TextoCircle>
                    </Body.DeleteBtn>:null}
                    {toggleModal?<Button onMouseEnter={()=>toggleClose(closeIconActive)}
                        onMouseLeave={()=>toggleClose(closeIconInactive)}
                        onClick={()=>toggleModal(false)} disabled={loading && sending}>
                        <Body.BtnIcon src={closeIcon} />
                    </Button>:null}
                </Body.Row>
            </Body.Header>
            <Body.Content>
                {loading || sending?<Body.Centralize>
                    <Body.Icon src={Spinner} />
                    {sending?<Body.TextoCircle style={{fontSize: '14px', marginTop: '5px'}}>
                        Enviando parâmetros. Isso pode demorar um pouco.</Body.TextoCircle>:null}
                </Body.Centralize>:children}
            </Body.Content>
        </Body.Modal>
    </Body.Container>
}

export default Modal