import React, { Dispatch, forwardRef, SetStateAction, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as Body from '../Styles'
import * as ChartBody from './Styles'
import { Line } from 'react-chartjs-2'
import DatePicker from 'react-datepicker'
import './DataPicker.css'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'

import "react-datepicker/dist/react-datepicker.css";
import { ChartProps } from "react-chartjs-2";
import * as Functions from '../../../utils/functions/functions'
import * as Regex from '../../../utils/Regex/Regex'

import Button from '../../../components/Button/Button'
import * as Btn from '../../../components/Button/Styles'
import homeIcon from '../../../assets/icons/home-icon.svg'
import gearIcon from '../../../assets/icons/Gear.svg'
import gearActiveIcon from '../../../assets/icons/Gear-active.svg'
import barsIcon from '../../../assets/icons/back.svg'
import barsActiveIcon from '../../../assets/icons/back-active.svg'

import { useSelector } from "react-redux";
import { State } from "../../../utils/redux/store";
import Modal from "../../../components/Modal/Modal";
import Input from "../../../components/Input/Input";
import { ChartDataProps, ChartDataPropsTerno } from "../../../utils/redux/types";
import Loading from "../../../components/Loading/Loading";
import ListModal from "../../../components/ListModal/ListModal";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
import { useDispatch } from "react-redux";
import axios from "axios";
import { dataChart } from "../../../utils/redux/data";
registerLocale('pt-BR', ptBR)

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)


type User = {
    nome: string,
    empresa: string,
    usina: string,
    cargo: number
}

type ChartPage = {
    toggleContent: Dispatch<SetStateAction<number>>,
    chart?: ChartProps,
    user: User,
    togglePerfil: Dispatch<SetStateAction<boolean>>,
}

type ChartCard = {
    selected: string,
    toggleLoading: Dispatch<SetStateAction<boolean>>,
    setContent: Dispatch<SetStateAction<number>>,
    token: string
}

type IndexProps = {
    setContent: Dispatch<SetStateAction<number>>,
    nivelAcesso: number,
    token: string
}

const chartContent = {
    index: 0,
    autoteste: 1,
    temperatura: 2,
    umidade: 3,
    filtros: 4,
    nivelamento: 5,
    pda: 6,
    bit_terno: 7,
    terno: 8
}

const ChartFunction: React.FC<ChartCard> = ({ selected, toggleLoading, token, setContent }) => {
    const current = new Date()
    const [finalHour, setFinalHour] = useState(current);
    const [finalDate, setFinalDate] = useState(current);
    const newStartHour = new Date(finalHour);
    const [loading, toggleFilterLoading] = useState(false)

    const [min, setMin] = useState("")
    const [max, setMax] = useState("")
    const [min2, setMin2] = useState("")
    const [max2, setMax2] = useState("")
    const [yManual, toggleY] = useState(false)

    if(selected === "autoteste"){
        newStartHour.setDate(newStartHour.getDate() - 7)
    }else{
        newStartHour.setTime(newStartHour.getTime() - 30 * 60 * 1000);
    }
    const [startHour, setStartHour] = useState(newStartHour);
    const [startDate, setStartDate] = useState(newStartHour);
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const getOptions = (item: any) => {

        console.log(Number(min), (Number(item.min) - Number(item.min * 0.1)), Number(max), (Number(item.max) + Number(item.max * 0.1)))
        return {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    display: true
                },
                y: {
                    display: true,
                    beginAtZero: false,
                    suggestedMin: yManual?Number(min):(Number(item.min) - Number(item.min * 0.1)),
                    suggestedMax: yManual?Number(max):(Number(item.max) + Number(item.max * 0.1))
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
            }
        }
    }

    const getOptions2 = (item: any) =>{
        return {
            responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        display: true,
                        ticks: {color: "rgba(0,0,0,0)"}
                    },
                    y: {
                        display: true,
                        beginAtZero: false,
                        suggestedMin: yManual?Number(min2):(Number(item.min2) - Number(item.min2 * 0.1)),
                        suggestedMax: yManual?Number(max2):(Number(item.max2) + Number(item.max2 * 0.1))
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                }
            }
        };

    const [chartConfig, setChartConfig] = useState<ChartDataProps>([])
    const [chartConfigTerno, setChartConfigTerno] = useState<ChartDataPropsTerno>([])

    const filter = () => {
        const startTimestamps = Functions.getTimestamps(startDate, startHour)
        const finalTimestamps = Functions.getTimestamps(finalDate, finalHour)

        const start = new Date(startTimestamps)
        const end = new Date(finalTimestamps)
        const now = new Date()

        if((start > now) || (end > now)){
            dispatch({ type: 'ADD_NOTIFICATION', status: 3, title: "Erro", text: "As datas de inicio e/ou fim não podem ser maiores que a data atual. Corrija e tente novamente." })
            return
        }

        if (start >= end) {
            dispatch({ type: 'ADD_NOTIFICATION', status: 3, title: "Erro", text: "A data final não pode ser menor ou igual a data inicial. Corrija e tente novamente." })
            return
        }

        getDataWithNewRange()
    }
      

    useEffect(() => {
        let init = Regex.convertDateForUTC(startHour.toISOString())
        let end = Regex.convertDateForUTC(finalHour.toISOString())

        toggleLoading(true)
        axios.get("https://www.dhm.dinamoautomacao.com.br/api/graficos?grafico="+selected+"&inicio="+
            Functions.getIsoString(init.ano, init.mes, init.dia, init.hora)+"&fim="+Functions.getIsoString(end.ano, end.mes, end.dia, end.hora)+
            "&token="+Functions.ReplaceSpecialCharacters(token), 
            {withCredentials: true})
            .then(response  => {
                if(selected === "ternos"){
                    setChartConfigTerno(Functions.getChartDataTerno(response.data, selected))
                }else if(selected === "autoteste"){
                    setChartConfig(Functions.getChartData(response.data, selected))
                }else{
                    setChartConfig(Functions.getChartDataGen(response.data, selected))
                }
               toggleLoading(false)
            })
            .catch(error => {
                setContent(chartContent.index)
                toggleLoading(false)
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            })
    }, [])

    const getDataWithNewRange = () => {
        
        const startTimestamps = Functions.getTimestamps(startDate, startHour)
        const finalTimestamps = Functions.getTimestamps(finalDate, finalHour)
        const init = new Date(startTimestamps)
        const end = new Date(finalTimestamps)

        toggleFilterLoading(true)
        axios.get("https://www.dhm.dinamoautomacao.com.br/api/graficos?grafico="+selected+"&inicio="+
            init.toISOString()+"&fim="+end.toISOString()+
            "&token="+Functions.ReplaceSpecialCharacters(token), 
            {withCredentials: true})
            .then(response  => {
                if(selected === "ternos"){
                    setChartConfigTerno(Functions.getChartDataTerno(response.data, selected))
                }else if(selected === "autoteste"){
                    setChartConfig(Functions.getChartData(response.data, selected))
                }else{
                    setChartConfig(Functions.getChartDataGen(response.data, selected))
                }
                toggleFilterLoading(false)
            })
            .catch(error => {
                toggleFilterLoading(false)
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            })
    }

    const getChart = () => {
        if(selected === "ternos"){
            return <Body.Scroll style={{height: 'calc(100vh -  160px)'}} >
                {chartConfigTerno.map((item, index) => {
                    return <ChartBody.ChartCard key={index.toString()} style={{marginBottom: '10px', position: 'relative'}}>
                            <Body.ListText style={{marginTop: '5px', marginBottom: '10px'}}>{item.name}</Body.ListText>
                            <ChartBody.MultipleCharts>
                                <Line data={item.config.pressao} key={index.toString()} options={getOptions2(item.ranges.pressao)} /> 
                            </ChartBody.MultipleCharts>
                            <ChartBody.MultipleCharts style={{marginTop: '-30 px'}}>
                                <Line data={item.config.deslocamento} key={index.toString()} options={getOptions(item.ranges.deslocamento)} /> 
                            </ChartBody.MultipleCharts>
                        </ChartBody.ChartCard>
                })}
            </Body.Scroll>
        }
        if(chartConfig.length > 1){
            return <Body.Scroll style={{height: 'calc(100vh -  160px)'}} >
                {chartConfig.map((item, index) => {
                    return <ChartBody.ChartCard key={index.toString()} style={{marginBottom: '10px', position: 'relative'}}>
                            <Body.ListText style={{marginTop: '5px', marginBottom: '10px'}}>{item.name}</Body.ListText>
                            <Line data={item.config} key={index.toString()} options={getOptions(item.ranges)} /> 
                        </ChartBody.ChartCard>
                })}
            </Body.Scroll>
        }else if(chartConfig.length === 1){
            return <Body.Scroll style={{height: 'calc(100vh -  160px)'}} >
                        <ChartBody.ChartCard style={{height: 'calc(100vh - 160px)'}}>
                        <Line data={chartConfig[0].config} options={getOptions(chartConfig[0].ranges)} /> 
                    </ChartBody.ChartCard>
                </Body.Scroll>
        }
    }

    const getCaption = () => {
        switch(selected){
            case "autoteste":
                return <Body.Row>
                    <ChartBody.Color color="rgba(134, 65, 244, 0.8)" />
                    <Body.ListText>Pressão</Body.ListText>
                </Body.Row>
            case "temperatura-oleo":
                return <Body.Row>
                        <ChartBody.Color color="rgba(134, 65, 244, 0.8)" />
                        <Body.ListText>Temperatura</Body.ListText>
                    </Body.Row>
            case "umidade-oleo":
                return <Body.Row>
                        <ChartBody.Color color="rgba(134, 65, 244, 0.8)" />
                        <Body.ListText>Umidade</Body.ListText>
                    </Body.Row>
            case "ternos":
                return <><Body.Row>
                        <ChartBody.Color color="rgba(134, 65, 244, 0.8)" />
                        <Body.ListText>Pressão Direito</Body.ListText>
                    </Body.Row>
                    <Body.Row>
                        <ChartBody.Color color="rgba(255, 87, 51, 0.8)" />
                        <Body.ListText>Pressão Esquerdo</Body.ListText>
                    </Body.Row>
                    <Body.Row>
                        <ChartBody.Color color="rgba(42, 182, 96, 0.8)" />
                        <Body.ListText>Des. Direito</Body.ListText>
                    </Body.Row>
                    <Body.Row>
                        <ChartBody.Color color="rgba(75,217,0,0.8)" />
                        <Body.ListText>Des. Esquerdo</Body.ListText>
                    </Body.Row>
                </>
            case "terno-critico":
                return <Body.Row>
                        <ChartBody.Color color="rgba(134, 65, 244, 0.8)" />
                        <Body.ListText></Body.ListText>
                    </Body.Row>
            case "pressao-filtro":
                return <Body.Row>
                        <ChartBody.Color color="rgba(134, 65, 244, 0.8)" />
                        <Body.ListText>Pressão</Body.ListText>
                    </Body.Row>
        }
    }

    return (
        <Body.Row style={{ width: '100%', justifyContent: 'space-between', overflow: 'hidden' }}>
            {chartConfig ? getChart(): null}
            <Body.Scroll style={{width: "24%", gridGap: '10px 10px', height: 'calc(100vh -  160px)',
                display: 'flex', flexDirection: 'column-reverse'}} >
                <Body.Card style={{ marginBottom: '10px',zIndex: 0 }}>
                    <Body.NavSubtitle style={{ marginBottom: '10px' }}>Intervalo eixo Y</Body.NavSubtitle>
                    {selected === "ternos"?<Body.ListText style={{marginBottom: '-5px'}}>Gráfico de deslocamento</Body.ListText>:null}
                    <Body.Row style={{gridGap: '0px 10px'}}>
                        <Input text={min} setText={setMin} placeholder="Mínimo" />
                        <Input text={max} setText={setMax} placeholder="Máximo" />
                    </Body.Row>
                    {selected === "ternos"?<>
                    <Body.ListText style={{marginBottom: '-5px', marginTop: '5px'}}>Gráfico de pressão</Body.ListText>
                    <Body.Row style={{gridGap: '0px 10px'}}>
                        <Input text={min2} setText={setMin2} placeholder="Mínimo" />
                        <Input text={max2} setText={setMax2} placeholder="Máximo" />
                    </Body.Row></>:null}
                    <Body.Row style={{gridGap: '5px 5px', flexWrap: 'wrap'}}>
                        <Button text="Definir limites manualmente" action={()=>toggleY(true)} disabled={yManual}/>
                        <Button text="Restaurar padrão" width={175} action={()=>toggleY(false)} disabled={!yManual}/>
                    </Body.Row>
                </Body.Card>                
                <Body.Card style={{ height: 'auto', maxHeight: '49%',marginBottom: '10px' }}>
                    <Body.NavSubtitle style={{ marginBottom: '10px' }}>Legendas</Body.NavSubtitle>
                    {getCaption()}
                </Body.Card>
                <Body.Card style={{ height: '49%',marginBottom: '10px', justifyContent: 'space-between' }}>
                    <Body.ListText>
                        Data de inicio
                    </Body.ListText>
                    <Body.Row style={{ gridGap: '0px 10px' }}>
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            selected={startDate} onChange={(date) => setStartDate(date!)} />
                        <DatePicker
                            selected={startHour}
                            onChange={(date) => setStartHour(date!)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Hora"
                            dateFormat="HH:mm"
                        />
                    </Body.Row>
                    <Body.ListText style={{ marginTop: '10px' }}>
                        Data final
                    </Body.ListText>
                    <Body.Row style={{ gridGap: '0px 10px' }}>
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            selected={finalDate} onChange={(date) => setFinalDate(date!)} />
                        <DatePicker
                            selected={finalHour}
                            onChange={(date) => setFinalHour(date!)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Hora"
                            dateFormat="HH:mm"
                        />
                    </Body.Row>
                    <div style={{height: '5px'}} />
                    <Button text="Filtrar" action={() => filter()} loading={loading} />
                </Body.Card>
            </Body.Scroll>
        </ Body.Row>
    )

}

const Defines = () => {
    const [umidade, setUmidade] = useState('0')
    const [temperatura, setTemperatura] = useState('0')
    const [filtro1, setFiltro1] = useState('0')
    const [filtro2, setFiltro2] = useState('0')

    return <Body.Card style={{ gridGap: '5px 0px' }}>
        <Body.MoendaTitle>Definição de alarmes</Body.MoendaTitle>
        <Body.Row style={{ gridGap: '0px 5px' }}>
            <Body.Card style={{ width: '200px' }}>
                <Body.MoendaText>Umidade do óleo</Body.MoendaText>
                <Body.MoendaTitle style={{ marginBottom: '5px' }}>{umidade}%</Body.MoendaTitle>
                <input type="range" min={0} max={100} onChange={e => setUmidade(e.target.value)}
                    value={umidade} />
            </Body.Card>
            <Body.Card style={{ width: '200px' }}>
                <Body.MoendaText>Temperatura do óleo</Body.MoendaText>
                <Body.MoendaTitle style={{ marginBottom: '5px' }}>{temperatura}ºC</Body.MoendaTitle>
                <input type="range" min={0} max={100} onChange={e => setTemperatura(e.target.value)}
                    value={temperatura} />
            </Body.Card>
        </Body.Row>
        <Body.Row style={{ gridGap: '0px 5px' }}>
            <Body.Card style={{ width: '200px' }}>
                <Body.MoendaText>Filtro 1</Body.MoendaText>
                <Body.MoendaTitle style={{ marginBottom: '5px' }}>{filtro1} bar</Body.MoendaTitle>
                <input type="range" min={0} max={5} step={0.1}
                    onChange={e => setFiltro1(e.target.value)}
                    value={filtro1} />
            </Body.Card>
            <Body.Card style={{ width: '200px' }}>
                <Body.MoendaText>Filtro 2</Body.MoendaText>
                <Body.MoendaTitle style={{ marginBottom: '5px' }}>{filtro2} bar</Body.MoendaTitle>
                <input type="range" min={0} max={5} step={0.1}
                    onChange={e => setFiltro2(e.target.value)}
                    value={filtro2} />
            </Body.Card>
        </Body.Row>
    </Body.Card>
}

const Index: React.FC<IndexProps> = ({ setContent, nivelAcesso, token }) => {
    return <>
        <Body.HalfContent percentage={100} style={{
            display: 'flex',
            flexDirection: 'column', gridGap: '10px 10px'
        }}>
            {/* <Body.Card style={{gridGap: '10px 0px'}}> */}
            <Body.Card>
                <Body.MoendaTitle>Pressão de autoteste bombas</Body.MoendaTitle>
                <Body.Row style={{ gridGap: '0px 10px' }}>
                    <Button text="Visualizar gráfico" action={() => setContent(chartContent.autoteste)} />
                    <Button text=" Abrir em nova guia" action={()=>window.open('/charts?acess='+nivelAcesso+'&chart='+chartContent.autoteste+"&token="+token, '_blank')} />
                </Body.Row>
            </Body.Card>
            <Body.Card>
                <Body.MoendaTitle>Pressão e desolcamentos dos ternos</Body.MoendaTitle>
                <Body.Row style={{ gridGap: '0px 10px' }}>
                    <Button action={() => setContent(chartContent.terno)} text="Visualizar gráfico" />
                    <Button text="Abrir em nova guia" action={()=>window.open('/charts?acess='+nivelAcesso+'&chart='+chartContent.terno+"&token="+token, '_blank')} />
                </Body.Row>
            </Body.Card>
            <Body.Card>
                <Body.MoendaTitle>Temperatura do óleo</Body.MoendaTitle>
                <Body.Row style={{ gridGap: '0px 10px' }}>
                    <Button action={() => setContent(chartContent.temperatura)} text=" Visualizar gráfico" />
                    <Button action={()=>window.open('/charts?acess='+nivelAcesso+'&chart='+chartContent.temperatura+"&token="+token, '_blank')} text="Abrir em nova guia" />
                </Body.Row>
            </Body.Card>
            <Body.Card>
                <Body.MoendaTitle>Umidade do óleo</Body.MoendaTitle>
                <Body.Row style={{ gridGap: '0px 10px' }}>
                    <Button action={() => setContent(chartContent.umidade)} text="Visualizar gráfico" />
                    <Button action={()=>window.open('/charts?acess='+nivelAcesso+'&chart='+chartContent.umidade+"&token="+token, '_blank')} text="Abrir em nova guia" />
                </Body.Row>
            </Body.Card>
            <Body.Card>
                <Body.MoendaTitle>Pressão de cada filtro</Body.MoendaTitle>
                <Body.Row style={{ gridGap: '0px 10px' }}>
                    <Button action={() => setContent(chartContent.filtros)} text="Visualizar gráfico" />
                    <Button action={()=>window.open('/charts?acess='+nivelAcesso+'&chart='+chartContent.filtros+"&token="+token, '_blank')} text="Abrir em nova guia"/>
                </Body.Row>
            </Body.Card>
            <Body.Card>
                <Body.MoendaTitle>Tempo de nivelamento diário</Body.MoendaTitle>
                <Body.Row style={{ gridGap: '0px 10px' }}>
                    <Button action={() => setContent(chartContent.nivelamento)} text="Visualizar gráfico" />
                    <Button action={()=>window.open('/charts?acess='+nivelAcesso+'&chart='+chartContent.nivelamento+"&token="+token, '_blank')} text="Abrir em nova guia" />
                </Body.Row>
            </Body.Card>
            {/* <Body.Card>
                <Body.MoendaTitle>Pressão e deslocamento de válvulas</Body.MoendaTitle>
                <Body.Row style={{ gridGap: '0px 10px' }}>
                    <Button action={() => setContent(chartContent.pda)} text="Visualizar gráfico" />
                    <Button action={()=>window.open('/charts?acess='+nivelAcesso+'&chart='+chartContent.pda+"&token="+token, '_blank')} text="Abrir em nova guia"/>
                </Body.Row>
            </Body.Card> */}
            <Body.Card >
                <Body.MoendaTitle>Bit de terno crítico por terno</Body.MoendaTitle>
                <Body.Row style={{ gridGap: '0px 10px' }}>
                    <Button action={() => setContent(chartContent.bit_terno)} text="Visualizar gráfico"/>
                    <Button text="Abrir em nova guia" action={()=>window.open('/charts?acess='+nivelAcesso+'&chart='+chartContent.bit_terno+"&token="+token, '_blank')} />
                </Body.Row>
            </Body.Card>
            {/* </Body.Card> */}
        </Body.HalfContent>
        {/* {nivelAcesso === 1?<Body.HalfContent percentage={38}>
            {Defines()}
        </Body.HalfContent>:null} */}
    </>
}

const Charts = () => {
    const navigate = useNavigate()
    const [params] = useSearchParams()
    const nivelAcesso = params.get('acess')
    const chart = params.get('chart')
    const token = params.get('token')

    useEffect(()=>{
        if(chart){
            toggleLoading(true)
            setContent(Number(chart))
        }
    },[])

    const [umidade, setUmidade] = useState('0')
    const [temperatura, setTemperatura] = useState('0')
    const [filtro1, setFiltro1] = useState('0')
    const [filtro2, setFiltro2] = useState('0')

    const [loading, toggleLoading] = useState(false)
    const [content, setContent] = useState(chartContent.index)

    const [modalDefine, toggleModal] = useState(false)
    const [gear, toggleGear] = useState(gearIcon)
    const [bars, toggleBars] = useState(barsIcon)
    const [menu, toggleMenu] = useState(false)

    const data = useSelector((state: State) => state)
    const btnStyle = { background: '#FFF', color: '#000' }


    const getContent = () => {
        switch (content) {
            case chartContent.index:
                return <Index setContent={setContent} nivelAcesso={Number(nivelAcesso!)} token={token!} />
            case chartContent.autoteste:
                return <ChartFunction selected="autoteste" toggleLoading={toggleLoading} token={token!} setContent={setContent}/>
            case chartContent.bit_terno:
                return <ChartFunction selected="terno-critico" toggleLoading={toggleLoading} token={token!} setContent={setContent}/>
            case chartContent.terno:
                return <ChartFunction selected="ternos" toggleLoading={toggleLoading} token={token!} setContent={setContent}/>
            case chartContent.filtros:
                return <ChartFunction selected="pressao-filtro" toggleLoading={toggleLoading} token={token!} setContent={setContent}/>
            case chartContent.nivelamento:
                return <ChartFunction selected="tempo-nivelamento" toggleLoading={toggleLoading} token={token!} setContent={setContent}/>
            case chartContent.temperatura:
                return <ChartFunction selected="temperatura-oleo" toggleLoading={toggleLoading} token={token!} setContent={setContent}/>
            case chartContent.umidade:
                return <ChartFunction selected="umidade-oleo" toggleLoading={toggleLoading} token={token!} setContent={setContent}/>
            default:
                return <></>

        }
    }

    const getTitle = () => {
        switch (content) {
            case chartContent.index:
                return "Gráficos"
            case chartContent.autoteste:
                return "Pressão de autoteste bombas"
            case chartContent.bit_terno:
                return "Bit de terno crítico por terno"
            case chartContent.filtros:
                return "Pressão de cada filtro"
            case chartContent.nivelamento:
                return "Tempo de nivelamento diário"
            // case chartContent.pda:
            //     return "Pressão e deslocamento de válvula"
            case chartContent.temperatura:
                return "Temperatura do óleo"
            case chartContent.umidade:
                return "Umidade do óleo"
            case chartContent.terno:
                return "Pressão e deslocamento dos ternos"
            default:
                return ''
        }
    }

    const goHome = () => {
        if (content != chartContent.index) {
            setContent(chartContent.index)
            return
        }
        navigate(-1)
    }

    return <Body.Container>
        <Body.Nav>
            <Body.NavTitle>
                {getTitle()}
            </Body.NavTitle>
            <Body.NavButtons>
                {/* {(content != chartContent.index) && (Number(nivelAcesso) === 1)? <Btn.Button onClick={() => toggleModal(true)} onMouseEnter={() => toggleGear(gearActiveIcon)}
                    onMouseLeave={() => toggleGear(gearIcon)}>
                    <Body.BtnIcon src={gear} />
                </Btn.Button> : null} */}
                {content === chartContent.index ? <Btn.Button onClick={() => goHome()}>
                    <Body.BtnIcon src={homeIcon} />
                </Btn.Button> : null}
                {content != chartContent.index ? <Btn.Button onClick={() => setContent(chartContent.index)}>
                    <Body.BtnIcon src={bars} onMouseEnter={() => toggleBars(barsActiveIcon)}
                        onMouseLeave={() => toggleBars(barsIcon)} />
                </Btn.Button> : null}
            </Body.NavButtons>
        </Body.Nav>
        <ChartBody.Content style={content === chartContent.index?{height: 'calc(100% - 0px)'}:{}}>
            {getContent()}
        </ChartBody.Content>
        {/* {modalDefine ? <Modal title="Configurações" toggleModal={toggleModal} save={() => { }}>
            <Body.Scroll style={{ maxHeight: '60vh' }}>
                <Defines />
            </Body.Scroll>
        </Modal> : null} */}
        {loading ? <Loading /> : null}
    </Body.Container>
}

export default Charts