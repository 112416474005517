import { data } from "../../data";
import { SELECT_MOENDA } from "../../moenda/actionsType";
import { ParametrizationUnidadeHidraulica } from "../../types";
import { ParametrizationUnidadeHidraulicaAction } from "./actionType";

const parametrizacaoUnidadeHidraulica = (
    state: ParametrizationUnidadeHidraulica = data[0].parametrizacao.unidade_hidraulica,
    action: ParametrizationUnidadeHidraulicaAction
) => {
    return state
}

export default parametrizacaoUnidadeHidraulica;