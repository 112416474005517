import styled from "styled-components";

export const VisualizeTernoContent = styled.div`
    width: 550px;
`

export const VisualizePerformanceContent = styled.div`
    width: 800px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    grid-gap: 0px 7.5px;
    overflow-y: scroll;
    ::-webkit-scrollbar{
        display: none;
    }
`

export const CircleTitle = styled.div<{color: string}>`
    height: 20px;
    border: 1px solid ${props => props.color};
    border-radius: 10px;
    padding-left: 5px; 
    padding-right: 5px;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const RegisterInterventionContent = styled.div`
    width: 900px;
`

export const Card = styled.div`
    width: 100%;
    background: rgba(94, 94, 94, 0.1);
    backdrop-filter: blur(4px);
    border: none;
    border-radius: 20px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: 5px;
`

export const ModalSelectContent = styled.div`
    cursor: pointer;
    display: flex;
    margin-bottom: 10px;
    position: relative;
`

export const ModalSelectTitle = styled.p`
    margin: 0;
    font-family: 'Nunito';
    color: rgba(255,255,255,0.8);
    font-size: 18px;
    margin-right: 10px;
`

export const Arrow = styled.img`
    width: 10px;
    height: 10px;
    margin-top: 7px; 
    margin-left: 5px; 
`

export const Dropdown = styled.div`
    position: absolute;
    width: 90px;
    background: #202833;
    top: 30px;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    padding: 5px;
    z-index: 200;
`

export const ModalSelectText = styled.p`
    margin: 0;
    font-family: 'Nunito';
    color: #FFF;
    font-size: 18px;
    margin-botto: 20px;
`

export const TextArea = styled.textarea`
    width: 100%;
    min-height: 180px;
    font-family: 'Nunito';
    color:#000;
    background-color: rgba(255,255,255,0.8);
    resize: none;
    outline: none;
    border-radius: 20px;
    border: none;
    padding: 20px;
    box-sizing: border-box;
`

export const PerformanceCard = styled.button`
    padding: 10px;
    box-sizing: border-box;
    background: none;
    border: 1px solid #FFF;
    color: #FFF;
    border-radius: 20px;    
    cursor: pointer;
    width: 260px;
    margin-bottom: 15px;
    transition: .3s;

    :hover{
        background: #2F3B4B;
        border: 1px solid rgba(100,100,100,0.5);
        transition: .3s;
    }
`
export const TextoCircle = styled.p`
    color: #FFF;
    font-family: "Nunito";
    font-weight: 700;
    font-size: 12px;
`
export const Input = styled.input`
    width: 100%;
    height: 30px;
    
    background: none;
    border: none;
    border-bottom: 2px solid rgba(255,255,255,0.1);

    outline: none;
    box-sizing: border-box;
    color: #FFF;
    letter-spacing: 1.5px;
    transition: .5s;
    text-align: center;
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    :focus{
        border-bottom: 2px solid rgba(255,255,255,0.8);
    }
`