import styled , {keyframes} from 'styled-components'
import * as Define from '../../utils/defines/defines'

export const Container = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: 1;
`

export const AnimateOpen = keyframes`
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
`
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Centralize = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Icon = styled.img`
    height: 50px;
    width: 50px;
    animation: ${rotate} .8s linear infinite;
`

export const Modal = styled.div`
    min-height: 100px;
    max-height: 90vh;
    min-width: 350px;
    max-width: 80%;
    background: ${Define.ModalBackground};
    border-radius: 20px;
    padding: 30px;
    box-sizing: border-box;
    animation-name: ${AnimateOpen};
    animation-duration: .3s;
    animation-timing-function: ease-in;
`

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`

export const TitleContent = styled.div`
    display: flex;
    flex-direction: column
`

export const Title = styled.p`
    margin: 0;
    font-family: "Montserrat";
    font-size: 20px;
    color: #FFF;
    font-weight: 600;
`
export const SubTitle = styled.p`
    margin: 0;
    font-family: "Nunito";
    font-size: 18px;
    color: #FFF;
    opacity: 0.8;
`

export const Content = styled.div`
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none; 
    }
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: calc(90vh - 110px);
`
export const BtnIcon = styled.img`
    height: 16px;
    width: 16px;
`

export const DeleteBtn = styled.button`
    height: 40px;
    background: #C5282C;
    border: solid 1px #C5282C;
    transition: .3s;
    color: #FFF;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    border-radius: 25px;
    align-items: center;
    text-align: center;
    justify-content: center;

    cursor: pointer;
    position: relative;
    display: flex;
    grid-gap: 0px 15px;

    :hover{
        transition: .3s;
        opacity: 0.8
    }

    :disabled{
        cursor: default;
        color: #fff;
        opacity: 0.6;
    }
`
export const Row = styled.div`
    display: flex;
    grid-gap: 0px 5px;
`
export const CircleTitle = styled.div<{color: string}>`
    height: 20px;
    border: 1px solid ${props => props.color};
    border-radius: 10px;
    padding-left: 5px; 
    padding-right: 5px;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const TextoCircle = styled.p`
    color: #FFF;
    font-family: "Nunito";
    font-weight: 700;
    font-size: 12px;
`