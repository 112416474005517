import styled from "styled-components";

export const Button = styled.button`
    width: 100%;
    height: 50px;
    padding: 12px;
    color: #fff;
    border-radius: 12px;
    margin-bottom: 10px;

    background: rgba(94, 94, 94, 0.1);
    cursor: pointer;
    border: none;
    outline: none;

`