import styled from "styled-components";
import * as Defines from '../../utils/defines/defines'

export const Container = styled.div`
    width: 100w;
    height: 100vh;
    background: ${Defines.BackgroundContainer};
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 40px;
    box-sizing: border-box;
    position: relative;
`
export const Nav = styled.div`
    height: 80px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: flex-start;
`

export const NavTitleContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const NavTitle = styled.p`
    margin: 0;
    font-family: "Montserrat";
    background: -webkit-linear-gradient(45deg, rgb(62, 147, 216), rgb(76, 185, 49));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    font-weight: bold;
    font-size: 35px;
    letter-spacing: 1.5px;
`
export const NavSubtitle = styled.p`
    margin: 0;
    font-family: "Montserrat";
    color: ${Defines.TitleColor};
    font-weight: 300;
    font-size: 20px;
`

export const UserImg = styled.img`
    height: 25px;
    width: 25px;
    border-radius: 50%;
`

export const NavButtons = styled.div`
    display: flex;
    grid-gap: 0px 5px;
    align-items: center;
`

export const BtnIcon = styled.img`
    height: 30px;
    width: 20px
`

export const Card = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(94, 94, 94, 0.1);
    backdrop-filter: blur(4px);
    border: none;
    border-radius: 20px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const Content = styled.div`
    height: calc(100% - 60px );
    max-height: calc(100% - 60px );
    min-height: calc(100% - 60px );
    width: 100%;
    display: flex;
    justify-content: space-between;
`

export const HalfContent = styled.div<{percentage: number}>`
    height: 100%;
    width: ${props => props.percentage}%;
`

export const Title = styled.p`
    margin: 0;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 25px;
    margin-bottom: 10px;
    color: ${Defines.TitleColor};
`

export const Scroll = styled.div`
    width: 100%;
    overflow-y: scroll;
    ::-webkit-scrollbar{
        display: none;
    }
`

export const MoendaCard = styled.button`
    cursor: pointer;
    background: rgba(94, 94, 94, 0.1);
    backdrop-filter: blur(4px);
    border: none;
    border-radius: 20px;

    width: 100%;
    height: 90px;
    margin-bottom: 5px;
    padding: 20px;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;

    display: flex;
    justify-content: space-between;
    align-items: center;

`
export const ButtonContent = styled.div`
    display: flex;
    width: 80%;
    justify-content: space-evenly;
    margin-top: 40px;
`
export const InputFind = styled.input`
    width: 35px;
    height: 35px;
    position: relative;
    border-radius: 25px;

    background: rgba(94, 94, 94, 0.1);
    border: 1px solid rgba(82, 146, 209, 0.1);
    box-sizing: border-box;
    outline: none;
    box-sizing: border-box;

    font-family: 'Nunito';
    font-weight: bold;
    font-size: 14px;
    color: #FFFFFF;
    padding-left: 10px;

    transition: width 0.3s, border 0.3s;
    ::placeholder,
    ::-webkit-input-placeholder {
        font-style: italic;
    }
    :focus{
        width: 300px;
    }

`
export const ListText = styled.p`
    margin: 0;
    font-family: "Nunito";
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 5px;
    color: rgba(255,255,255,0.8)
`

export const ContentTimeUpdate = styled.div`
    position: absolute;
    bottom: 10px;
`

export const IconInput = styled.img`
    width: 20px;
    height: 20px;
    position: absolute;
    top: 28px;
    right: 28px;
`

export const CardHeader = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
`

export const NotificationsCard = styled.button`
    cursor: pointer;
    background: rgba(94, 94, 94, 0.1);
    backdrop-filter: blur(4px);
    border: none;
    border-radius: 20px;

    width: 100%;
    margin-bottom: 5px;
    padding: 20px;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;

    display: flex;
    justify-content: space-between;
    align-items: center;

`

export const Circle = styled.div<{color: string}>`
    width: 40px;
    height: 40px;
    border: 2px solid ${props => props.color};
    border-radius: 50%;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const MoendaIcon = styled.img`
    height: 25px;
`

export const MoendaTextContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`

export const MoendaTitle = styled.p`
    margin: 0;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
    color: ${Defines.TitleColor};
`

export const MoendaText = styled.p`
    margin: 0;
    font-family: "Nunito";
    font-weight: 300;
    font-size: 16px;
    color: rgba(255,255,255,0.8)
`
export const Logo = styled.img`
    width: 150px;
    z-index: 2;
`

export const NavText = styled.p`
    margin: 0;
    color: #FFF;
    font-family: 'Nunito';
    font-size: 16px;
    margin-right: 10px;
`

export const Row = styled.div`
    display: flex;
    grid-gap: 0px 5px
`