import { User } from "../types";

const initialState = {p_nome: '', s_nome: '', empresa: ''}

const user = (
    state: User = initialState
) => {
    return state
}

export default user