import React, {Dispatch, SetStateAction, useState, useRef} from "react";
import * as Body from './Styles'
import * as functions from '../../utils/functions/functions'
import axios from "axios";
import Loading from "../../components/Loading/Loading";
import ButtonGradient from "../../components/ButtonGradient/ButtonGradient";

/*Components*/
import Input from '../../components/Input/Input'
import { useNavigate } from "react-router-dom";

import EyeIcon from '../../assets/icons/eye-regular.svg'
import EyeSplashIcon from '../../assets/icons/eye-slash-regular.svg'
import ShieldIcon from '../../assets/icons/shield-icon.svg'
import {useDispatch} from 'react-redux'

type Props = {
    toggleAuth: Dispatch<SetStateAction<boolean>>
}

const DinamoLogo = require('../../assets/images/dinamo_logo.png')


const LGPD: React.FC = () => {

    const [loading, toggleLoading] = useState(false)
    const history = useNavigate()
    const dispatch = useDispatch()

    const logout = () => {
        toggleLoading(true)
        axios.get('https://www.conecta.tectrolnet.com.br/api/Logout', {withCredentials: true})
            .then(()=>{
                history("/")
            })
            .catch((error)=>{
                switch(error.response.status){
                    case 400:
                        dispatch({type: "ADD_NOTIFICATION", status: 3, title: error.response.data.msg})
                        break
                    case 403:
                        history("/")
                        dispatch({type: "ADD_NOTIFICATION", status: 2, title: 'Usuário desconectado'})
                        break
                    case 401:
                        history("/")
                        dispatch({type: 'ADD_NOTIFICATION', status: 2, title: error.response.status.concat(": ", error.response.data.msg)})
                        return
                }
                dispatch({type: "ADD_NOTIFICATION", status: 3, title: error.response.data.msg})
                toggleLoading(false)
            })
    }

   
    return <Body.Container >
        <div style={{display: 'flex'}}>
           <div>
           <Body.Title>
                DHM4.0
            </Body.Title>
            <Body.Subtitle>
                App de monitoramento
            </Body.Subtitle>
           </div>
            <Body.Subtitle style={{fontSize: '20px', marginTop: '15px'}}>
                Beta
            </Body.Subtitle>
        </div>
        <Body.Card>
            <div style={{display: 'flex', alignItems: 'center', marginBottom: 35, justifyContent: 'space-between'}}>
                <Body.CardTitle style={{fontWeight: 300, marginBottom: 0, width: 'calc(100% - 100px)'}}> 
                    Lei geral de proteção de dados
                </Body.CardTitle>
                <Body.HeaderIcon src={ShieldIcon} />
            </div>
            <Body.Text style={{marginBottom: 10}}>
                A <strong>Dínamo</strong> está em conformidade com a <strong>Lei Geral de Proteção de 
                Dados</strong> (LGPD) que garantem a <strong>privacidade</strong> e <strong>segurança</strong> 
                dos seus dados.
            </Body.Text>

            <Body.Text style={{marginBottom: 10}}>
                Para utilizar o app, sua empresa precisa aceitar o <strong>Termo de Compartilhamento de 
                Dados da LGPD.</strong>
            </Body.Text>

            <Body.Text>
            Toque no botão abaixo para acessar o site onde está disponível o formulário de aceite. <strong>
            Apenas o encarregados de dados da sua empresa pode realizar o aceite.</strong>
            </Body.Text>
            <a href="https://lgpd.dinamoautomacao.com.br" target="_blank" 
                style={{width: '100%', marginTop: 25}}>
                <ButtonGradient text="Acessar site do termo" width={100} />
            </a>
            <Body.TextBtn style={{textAlign: 'center'}} onClick={logout}>
                SAIR</Body.TextBtn>

        </Body.Card>
        <Body.Logo src={DinamoLogo} />
        <Body.Wave1 />
        <Body.Wave2 />
        <Body.Wave3 />
        {loading?<Loading />:null}
    </Body.Container>
}

export default LGPD