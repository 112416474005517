import React, {Dispatch} from 'react';
import * as Body from './Styles'
import Spinner from '../../assets/icons/spinner.svg'

type Props = {
    text: string,
    width?: number,
    action?: any,
    bg?: string,
    bordercolor?: string,
    disabled?: boolean,
    loading?: boolean
}

const Button: React.FC <Props> = ({
    text, width, action, bg, bordercolor,disabled, loading
}) => {
    return <>{!loading?
    <Body.Button style={{width: width? width:'', backgroundColor: bg? bg:'', 
        borderColor: bordercolor?bordercolor:''}} onClick={action} disabled={disabled} >
        { text}
    </Body.Button>:<Body.Icon src={Spinner} />}
    </>
}

export default Button