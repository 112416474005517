import React, { SetStateAction, Dispatch, useRef,Ref, useState, useEffect } from 'react';
import * as Body from './Style'

type Props = {
    setText: any,
    placeholder?: string,
    disabled?: boolean,
    maxLenght?: number,
    code?: number,
    secret?: boolean,
    error?: boolean,
    ref?: Ref<any>
    align?: string,
    paddingRight?: number
}

const NumberInput: React.FC <Props> = ({
    setText
}) => {

    const [digito1, setDigito1] = useState("")
    const [digito2, setDigito2] = useState("")
    const [digito3, setDigito3] = useState("")
    const [digito4, setDigito4] = useState("")

    const number1 = useRef<any>()
    const number2 = useRef<any>()
    const number3 = useRef<any>()
    const number4 = useRef<any>()

    const field = (text: string, input: any) => {
        let string = 'xxxx'
        switch(input){
            case number1:
                setDigito1(text)
                setText(text.concat(digito2, digito3, digito4))
                if(text.length > 0) number2.current.focus()
                break
            case number2:
                setDigito2(text)
                setText(digito1.concat(text, digito3, digito4))
                text.length > 0? number3.current.focus():number1.current.focus()
                break
            case number3:
                setDigito3(text)
                setText(digito1.concat(digito2, text, digito4))
                text.length > 0? number4.current.focus():number2.current.focus()
                break
            case number4:
                setDigito4(text)
                setText(digito1.concat(digito2, digito3,  text))
                if(text.length === 0) number3.current.focus()
                break
        }
    }

    return   <Body.DigitsContent>
                <Body.NumberInput ref={number1} maxLength={1} 
                    onChange={e => field(e.target.value, number1)}/>
                <Body.NumberInput ref={number2} maxLength={1} 
                    onChange={e => field(e.target.value, number2)}/>
                <Body.NumberInput ref={number3} maxLength={1} 
                    onChange={e => field(e.target.value, number3)}/>
                <Body.NumberInput ref={number4} maxLength={1} 
                    onChange={e => field(e.target.value, number4)}/>
            </Body.DigitsContent>
}

export default NumberInput;