import React, { SetStateAction, Dispatch, Ref, RefObject, useState, useEffect } from 'react';
import * as Body from './Style'

type Props = {
    text: string,
    setText: any,
    placeholder?: string,
    disabled?: boolean,
    maxLenght?: number,
    code?: number,
    secret?: boolean,
    error?: boolean,
    ref?: Ref<any>
    align?: string,
    paddingRight?: number
}

const Status = {
    error: 1,
    warning: 2,
}

const Input: React.FC <Props> = ({
    text, setText, placeholder, disabled, maxLenght, code, secret, error, ref, align, paddingRight
}) => {

    return <Body.Input value={text} placeholder={placeholder} onChange={e => setText(e.target.value)}
                disabled={disabled} maxLength={maxLenght?maxLenght:500}
                type={secret?'password':'text'} align={align?align:'left'} 
                paddingRight={paddingRight?paddingRight:0}
                style={error?{borderColor: '#FF2B2B'}:{}}/>
}

export default Input;