import React from 'react';
import ReactDOM from 'react-dom';
import { GlobalStyles } from './GlobalStyle';
import Routes from './utils/routes/routes';
import "./index.css"

ReactDOM.render(
  <React.StrictMode>
      <GlobalStyles />
      <Routes />
  </React.StrictMode>,
  document.getElementById('root')
);
