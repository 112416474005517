import styled , {keyframes} from 'styled-components'
import * as Define from '../../utils/defines/defines'

export const Container = styled.div`
    position: absolute;
    width: 100%;
    height: 100vh;
    background: ${Define.BackgroundContainer};
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: 10;
    flex-direction: column;
    grid-gap: 10px 0px;
`

export const Subtitle = styled.p`
    margin: 0;
    font-family: 'Nunito';
    font-size: 20px;
    color: rgba(255,255,255,0.5);
`

const TextAnimate = keyframes`
    0%{background-position: 0%;}
    25%{background-position: 50%;}
    50%{background-position: 100%;}
    75%{background-position: 50%;}
    10%{background-position: 0%;}
`


export const Title = styled.p`
    margin: 0;
    font-family: "Montserrat";
    background-image: -webkit-linear-gradient(45deg, rgb(62, 147, 216), rgb(76, 185, 49),rgb(62, 147, 216) );
    background-size: 200%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    font-weight: bold;
    font-size: 48px;
    letter-spacing: 1.5px;
    transition: 1s ease-in;
    animation-name: ${TextAnimate};
    animation-duration: 2s;
    animation-iteration-count: infinite;

`
export const SubTitle = styled.p`
    margin: 0;
    font-family: "Nunito";
    font-size: 18px;
    color: #FFF;
    opacity: 0.8;
`

export const Icon = styled.img`
    height: 25px;
`

export const Logo = styled.img`
    width: 150px;
    position: absolute;
    left: calc(50vw - 75px);
    bottom: 40px;
    z-index: 2;
`
