import {useState, useEffect, useRef} from 'react'
import * as Body from './Styles.'
import {Button} from '../../components/Button/Styles'
import findIconActive from '../../assets/icons/lupa-active.svg'
import * as functions from '../../utils/functions/functions'
import * as ModalsConfig from '../config/modals/Modals'
import Loading from '../../components/Loading/Loading'

import warningIcon from '../../assets/icons/warning-icon.svg'
import checkIcon from '../../assets/icons/check-icon.svg'
import cloudIcon from '../../assets/icons/cloud.svg'
import cloudDisconnectedIcon from '../../assets/icons/cloud-disconnected.svg'
import connectedIcon from '../../assets/icons/connected.svg'
import disconnectedIcon from '../../assets/icons/disconnected.svg'
import externalIcon from '../../assets/icons/external-icon.svg'

import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { State } from '../../utils/redux/store'
import axios from 'axios'
import {HomeState} from '../../utils/redux/types'
import { title } from 'process'
import { homeFakeData } from '../../utils/redux/data'
type MoendaProp = { 
    token: string,
    nome: string,
    status: number,
    qtd_ternos: number,
    clp_status: number,
    nuvem_status: number
}

const DinamoLogo = require('../../assets/images/dinamo_logo.png')

const Home = () => {

    const [data, setData] = useState<HomeState>()
    const [filtered, setFiltered] = useState<MoendaProp[]>([])
    const [hasLength, toggleHasLength] = useState(false)
    const [loading, toggleLoading] = useState(true)
    
    const history = useNavigate()
    const dispatch = useDispatch()
    
    const isRequesting = useRef(false)
    const [seeTime, setSeeTime] = useState(0)
    const [seeIsRequesting, toggleIsRequesting] = useState(false)
    const timer = useRef(0)
    
    const [addUser, toggleAddUser] = useState(false)
    const [editUsers, toggleEditUsers] = useState(false)
    const [changeEmail, toggleChangeEmail] = useState(false)
    const [changePassword, toggleChangePassword] = useState(false)

    const logout = () => {
        toggleLoading(true)
        axios.get('https://www.conecta.tectrolnet.com.br/api/Logout', {withCredentials: true})
            .then(()=>{
                localStorage.setItem("@logged", "")
                history("/")
            })
            .catch((error)=>{
                dispatch({type: "ADD_NOTIFICATION", status: 3, title: error.response.data.msg})
                toggleLoading(false)
            })
    }

    useEffect(()=>{
        
        //Puxando dados da aplicacao
        isRequesting.current = true
        toggleIsRequesting(true)
        axios.get('https://www.dhm.dinamoautomacao.com.br/api/moendas', {withCredentials: true}  )
            .then((response) => {
                let user = response.data.usuario
                setData(response.data)
                setFiltered(response.data.moendas!)
                dispatch({type: 'SET_USER}', nome: user.nome, sobrenome: user.sobrenome})
                toggleLoading(false)
            })
            .catch((error)=> {
                toggleLoading(false)
                functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, history, dispatch, error.response.data.content)
            })
            .finally(() => {
                isRequesting.current = false
                toggleIsRequesting(false)
            })
 
        
        const interval = setInterval(()=>{
            if(isRequesting.current)
            return

            timer.current -= 1

            if(timer.current == 0){
                isRequesting.current = true
                toggleIsRequesting(true)
                axios.get('https://www.dhm.dinamoautomacao.com.br/api/moendas', {withCredentials: true} )
                    .then((response) => {
                        let user = response.data.usuario
                        setData(response.data)
                        setFiltered(response.data.moendas!)
                        dispatch({type: 'SET_USER}', nome: user.nome, sobrenome: user.sobrenome})
                        toggleLoading(false)
                    })
                    .catch((error)=> {
                        toggleLoading(false)
                        functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, history, dispatch, error.response.data.content)
                    })
                    .finally(() => {
                        isRequesting.current = false
                        toggleIsRequesting(false)
                    })                
            }else if(timer.current == -1){
                setSeeTime(20)
                timer.current = 20
            }
            
        }, 1000)

        return function cleanup(){
            clearInterval(interval)
        }

    },[])
        

    const getText = (nuvem: number, clp: number, status: number) => {
        if(nuvem === 2){
            return "A conexão com o DHM foi perdida"
        }
        if(clp === 2){
            return "A conexão com o clp foi perdida"
        }
        switch(status){
            case 1: 
                return "A moenda está funcionando normalmente"
            case 2:
        }
    }

    const filterData = (name: string) => {
        if(name.length === 0){
            setFiltered(data?.moendas!)
            toggleHasLength(false)
            return
        }
        toggleHasLength(true)
        const filtered = data?.moendas.filter((item) => item.nome.toUpperCase().includes(name.toUpperCase()))
        setFiltered(filtered!)
    }

    const getColor = (item: MoendaProp) => {
        // if(!item.nuvem_status){
        //     return "#F03F3F"
        // }
        // if(!item.clp_status){
        //     return "#F03F3F"
        // }
        return "#FFF"
    }

    const getIcon = (nuvem: number, clp: number) => {
        if((clp === 2) || (nuvem === 2)){
            return disconnectedIcon
        }
        switch(nuvem){
            case 1:
                return connectedIcon
            case 2:
                return disconnectedIcon
        }
    }

    const getTextConnection = (item: MoendaProp) => {
        if(item.nuvem_status === 2){
            return 'Nuvem desconectada'
        }
        if(item.clp_status === 2){
            return "CLP desconectado"
        }
        return "CLP conectado"
    }

    return <Body.Container>
        <Body.Nav>
            <Body.NavTitleContent>
                <Body.NavTitle>
                    DHM4.0
                </Body.NavTitle>
            </Body.NavTitleContent>

            <Body.NavButtons>
                <Body.NavText>
                    Olá, {data?.usuario.p_nome}
                </Body.NavText >
                <Button onClick={()=>logout()}>
                    Sair
                </Button>
            </Body.NavButtons>
        </Body.Nav>

        <Body.Content >
            <Body.HalfContent percentage={59} >
                <Body.Card>
                    <Body.CardHeader>
                        <Body.Title>
                            Moendas
                        </Body.Title>
                        <div>
                        <Body.IconInput src={findIconActive} />
                        <Body.InputFind onChange={e => filterData(e.target.value)} 
                            style={hasLength?{width: '300px'}:{}}/>
                    </div>
                    </Body.CardHeader>
                   <Body.Scroll>
                    {filtered.map((item, index)=> {
                        return item.status === 2? <Body.MoendaCard key={index.toString()} 
                        onClick={()=>history("/moenda?token="+item.token+"&nome-usina="+item.nome)}>
                                <Body.MoendaTextContent>
                                    <Body.MoendaTitle>{item.nome}</Body.MoendaTitle>
                                    <Body.MoendaText>{getText(item.nuvem_status, item.clp_status, item.status)}</Body.MoendaText>
                                </Body.MoendaTextContent>
                                <Body.Row>
                                <Body.Circle color={getColor(item)} title={getTextConnection(item)}>
                                    <Body.MoendaIcon src={getIcon(item.nuvem_status, item.clp_status)} />
                                </Body.Circle>
                                <Body.Circle color={"#E5D34A"} >
                                    <Body.MoendaIcon src={warningIcon} />
                                </Body.Circle>
                                </Body.Row>
                        </Body.MoendaCard>:null
                    })}
                   {filtered.map((item, index)=> {
                        return item.status === 1? <Body.MoendaCard key={index.toString()} 
                            onClick={()=>history("/moenda?token="+item.token+"&nome-usina="+item.nome)}>
                                <Body.MoendaTextContent>
                                    <Body.MoendaTitle>{item.nome}</Body.MoendaTitle>
                                    <Body.MoendaText>{getText(item.nuvem_status, item.clp_status, item.status)}</Body.MoendaText>
                                </Body.MoendaTextContent>
                                <Body.Row>
                                    <Body.Circle color={getColor(item)} title={getTextConnection(item)}>
                                        <Body.MoendaIcon src={getIcon(item.nuvem_status, item.clp_status)} />
                                    </Body.Circle>
                                    <Body.Circle color={"#92B258"} >
                                        <Body.MoendaIcon src={checkIcon} />
                                    </Body.Circle>
                                </Body.Row>
                        </Body.MoendaCard>:null
                    })}
                   </Body.Scroll>
                </Body.Card>
            </Body.HalfContent>
            <Body.HalfContent percentage={40} >
                <Body.Card style={{height: 'auto'}}>
                    <Body.Title>
                        Gerenciamento
                    </Body.Title>
                    <Body.MoendaText style={{textAlign: 'center'}}>
                        Para alterar dados, gerenciar usuários, empresas e usinas é necessario acessar nosso app de gerenciamento
                    </Body.MoendaText>
                    <Body.ButtonContent style={{marginTop:  '20px'}}>
                        <Button onClick={()=>window.open('https://www.conecta.tectrolnet.com.br/', "_blank")}>
                            Acessar conecta
                            <Body.BtnIcon src={externalIcon} />
                        </Button>
                    </Body.ButtonContent>
                </Body.Card>
            </Body.HalfContent>
        </Body.Content>
        {/* {ternoModal?<VisualizarTerno toggleModal={toggleTernoModal} id={0} moenda={0} />:null} */}
        {addUser?<ModalsConfig.RegisterUser toggle={toggleAddUser} />:null}
        {editUsers?<ModalsConfig.EditUsers toggleModal={toggleEditUsers} />:null}
        {changeEmail?<ModalsConfig.ChangeEmail toggle={toggleChangeEmail}  /> :null}
        {changePassword?<ModalsConfig.ChangePassword toggle={toggleChangePassword}  />:null}
        {loading?<Loading />:null}
        <Body.ContentTimeUpdate>
            <Body.ListText>{seeIsRequesting?"Atualizando dados, aguarde...":`Atualizando dados em ${functions.adjustTime(seeTime)}`}</Body.ListText>
        </Body.ContentTimeUpdate>
    </Body.Container>
}

export default Home