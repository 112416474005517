export const cpfRegex = (text: string, cpf: string) => {
    if((text.length === 3 && text.length > cpf.length) || 
       (text.length === 7 && text.length > cpf.length)){
        text += '.'
    }
    if(text.length === 11 && text.length > cpf.length){
        text += '-'
    }
    return text
}

export const cnpjRegex = (text: string, cpf: string) => {
    if((text.length === 2 && text.length > cpf.length) || 
       (text.length === 6 && text.length > cpf.length)){
        text += '.'
    }
    if(text.length === 10 && text.length > cpf.length){
        text += '/'
    }
    if(text.length === 15 && text.length > cpf.length){
        text += '-'
    }
    return text
}

export const rgRegex = (text: string, rg: string) => {
    if((text.length === 2 && text.length > rg.length) ||
       (text.length === 6 && text.length > rg.length) ){
        text += '.'
    }
    if(text.length === 10 && text.length > rg.length ){
         text += '-'
     }
     
    return text
}

export const dateRegex = (text: string, date: string) => {
    if((text.length === 2 && text.length > date.length) ||
       (text.length === 5 && text.length > date.length)){
            text += '/'
    }

    return text
}

export const cepRegex = (text: string, cep: string) => {
    if(text.length === 5 && text.length > cep.length) {
        text += '-'
    }
    return text
}

export const hourRegex = (text: string, hour: string) => {
    if(text.length === 2 && hour.length === 1) {
        text += ':'
    }

    if(text.length === 3 && hour.length === 4){
        text = text.slice(0,2)
    }

    if(text.length === 3 && hour.length === 2){
        text = text.slice(0,2) + ":" + text.slice(2)
    }

    return text
}

export const numberRegex = (text: string, number: string) => {
    let newText = text

    if(text.length === 10 && text.length > number.length){
        newText = text.slice(0, 4) + text.slice(5,6) + '-' + text.slice(6)
    }
    if(text.length === 4 && text.length > number.length){
        newText = text+'-'
    }
    if(number.length===4 && text.length > number.length){
        newText = text.slice(0,4 ) + '-' + text.slice(4)
    }

    if(number.length === 10) {
        newText = text.slice(0,4) + '-' + text.slice(6)
    }

    return newText
}

export const getUTC = (date: string) => {
    const timeOffset = new Date().getTimezoneOffset() * 60 * 1000
    const current = new Date(date)
    const converted = new Date(current.getTime() + timeOffset)

    return converted
}

export const convertUTCToBrasilia = (utc: string) => {
    const dateUTC = new Date(utc)
    const dateBrasilia = new Date(dateUTC.getTime() - (3 * 60 * 60 * 1000));
    
    const format = (num: number) => {
        if(num < 10){
            return "0".concat(num.toString())
        }
        return num.toString()
    }

    const data = {
        dia: format(Number(dateBrasilia.getDate())),
        mes: format(Number(dateBrasilia.getMonth()) + 1),
        ano: dateBrasilia.getFullYear(),
        hora: format(dateBrasilia.getHours()).concat(":", format(dateBrasilia.getMinutes()))
    }
    return data
}

export const convertDateForUTC = (utc: string) => {
    const date = new Date(utc)
    const dateUTC = new Date(date.getTime() + (3 * 60 * 60 * 1000))
    
    const format = (num: number) => {
        if(num < 10){
            return "0".concat(num.toString())
        }
        return num.toString()
    }

    const data = {
        dia: format(Number(dateUTC.getDate())),
        mes: format(Number(dateUTC.getMonth()) + 1),
        ano: dateUTC.getFullYear().toString(),
        hora: format(dateUTC.getHours()).concat(":", format(dateUTC.getMinutes()))
    }
    return data
}

export const getDateFromUTC = (utc: string) => {
    const date = new Date(utc)
    
    const format = (num: number) => {
        if(num < 10){
            return "0".concat(num.toString())
        }
        return num.toString()
    }

    const data = {
        dia: format(Number(date.getDate())),
        mes: format(Number(date.getMonth()) + 1),
        ano: date.getFullYear(),
        hora: format(date.getHours()).concat(":", format(date.getMinutes()))
    }
    return data
}