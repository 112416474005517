import React, {useState, useRef, Dispatch, SetStateAction} from "react";
import * as Body from '../Styles'
import * as functions from '../../../utils/functions/functions'
import axios from "axios";
import Loading from "../../../components/Loading/Loading";

/*Components*/
import Input from '../../../components/Input/Input'
import * as BodyInput from '../../../components/Input/Style'
import { useNavigate } from "react-router-dom";

import EyeIcon from '../../../assets/icons/eye-regular.svg'
import EyeSplashIcon from '../../../assets/icons/eye-slash-regular.svg'
import { useDispatch } from "react-redux";
import CodeInput from "../../../components/CodeInput/CodeInput";
import NumberInput from "../../../components/NumberInput/NumberInput";
const DinamoLogo = require('../../../assets/images/dinamo_logo.png')

type SendProps = {
    toggleContent: Dispatch<SetStateAction<number>>,
    toggleLoading: Dispatch<SetStateAction<boolean>>,
    setEmailRecover: Dispatch<SetStateAction<string>>,
    email?: string
}
type RecoverProps = {
    toggleContent: Dispatch<SetStateAction<number>>,
    toggleLoading: Dispatch<SetStateAction<boolean>>,
    email?: string
}

const Contents = {
    SEND: 1,
    RECEIVED: 2,
    RECOVER: 3
}

const SendCode: React.FC <SendProps> = ({
    toggleContent, setEmailRecover, toggleLoading
}) => {
    const [email, setEmail] = useState("")
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [celular, setCelular] = useState("")
    const [errorEmail, toggleErrorEmail] = useState(false)

    const send = () => {

        if(!functions.verifyEmail(email)) {
            toggleErrorEmail(true)
            return
        }else{
            toggleErrorEmail(false)
            toggleLoading(true)
            axios.post(process.env.REACT_APP_RECOVER_SEND_URL!, {email, celular},  {withCredentials: true} )
                .then((response) => {
                    toggleContent(Contents.RECEIVED)
                    setEmailRecover(email)
                    toggleLoading(false)
                })
                .catch((error) => {
                    switch(error.response.status){
                        case 400:
                            dispatch({type: "ADD_NOTIFICATION", status: 3 ,title: "Bad request"})
                            break;
                        case 403:
                            dispatch({type: "ADD_NOTIFICATION", status: 3 ,title: error.response.data.msg})
                    }
                    toggleLoading(false)
                })

        }
    }

    return <>
            <Input text={email} setText={setEmail} error={errorEmail}
                placeholder="EMAIL"/>
            <Body.InputTitle style={{color: 'rgba(255,255,255,0.5)', marginBottom: 0,
                    textTransform: 'uppercase', fontSize: '12px', marginLeft: '10px'}}>
                Últimos digitos do seu celular</Body.InputTitle>
            <NumberInput setText={setCelular} />
            <Body.ButtonLogin onClick={()=>send()}>
                Enviar código
            </Body.ButtonLogin>
            <Body.TextBtn onClick={()=>navigate('/')}>Voltar para o login?</Body.TextBtn>    
    </>
}


const ReceivedCode: React.FC <RecoverProps> = ({
    toggleContent, email, toggleLoading
}) => {
    const [senha, setSenha] = useState("")
    const dispatch = useDispatch()
    const [seePassword, toggleSee] = useState(false)
    const [codigo_tfa, setCode] = useState("")
    const navigate = useNavigate()

    const [hasLow, toggleHasLow] = useState(false)
    const [hasUpper, toggleHasUpper] = useState(false)
    const [hasSpecial, toggleHasSpecial] = useState(false)
    const [hasLength, toggleHasLength] = useState(false)

    const fieldPassword = (text: string) => {
        setSenha(text)

        const verify = functions.checkPasswordRestrictions(text)

        toggleHasLow(verify.lowercase)
        toggleHasUpper(verify.uppercase)
        toggleHasSpecial(verify.special)
        toggleHasLength(verify.lenght)
    }


    const send = () => {
        toggleLoading(true)
         axios.post(process.env.REACT_APP_RECOVER_RECOVER_URL!, {email, senha ,codigo_tfa},  {withCredentials: true} )
                .then((response) => {
                    dispatch({type: 'ADD_NOTIFICATION', status: 1, title: 'Senha alterada com sucesso'})
                    toggleLoading(false)
                })
                .catch((error) => {
                    switch(error.response.status){
                        case 400:
                            dispatch({type: 'ADD_NOTIFICATION', status: 3, title: 'Bad request'})
                            break
                        case 403:
                            dispatch({type: 'ADD_NOTIFICATION', status: 2, title: error.response.data.msg})
                            break
                    }
                    toggleLoading(false)
                })

    }

    const resendCode = () => {
		axios.get(process.env.REACT_APP_RESEND_URL!.concat("?email="+email), {withCredentials: true})
			.then((response) => {	
                dispatch({type: 'ADD_NOTIFICATION', status: 1, title: "Código reenviado com sucesso"})	
			})
			.catch((error) => {
                dispatch({type: 'ADD_NOTIFICATION', status: 3, title: "Erro ao reenviar código " + error.response.status})
			})
	}

    return <>
            <Body.InputTitle style={{color: 'rgba(255,255,255,0.5)', marginBottom: 0,
                textTransform: 'uppercase', fontSize: '12px', marginLeft: '10px'}}>
                Enviamos um código para seu celular, se o número informado estiver correto em instantes você irá recebe-lo, insira-o para prosseguir com a redefinição</Body.InputTitle>
            <CodeInput setText={setCode} />
            <Body.InputTitle>
                A sua senha deve conter: letras 
                <strong style={{color: hasLow?'green':'red'}}> minúsculas, </strong>
                <strong style={{color: hasUpper?'green':'red'}}> maiúsculas, </strong>
                <strong style={{color: hasLength?'green':'red'}}> no minímo 12 caracters, </strong>
                <strong style={{color: hasSpecial?'green':'red'}}> caracteres especiais. </strong>
            </Body.InputTitle>
            <Body.PasswordContent>
                <BodyInput.Input value={senha}  placeholder="NOVA SENHA"
                    type={seePassword?"text":'password'}
                  onChange={e => fieldPassword(e.target.value)} />
                <Body.Icon src={seePassword?EyeIcon:EyeSplashIcon} 
                    onClick={()=>toggleSee(old => !old)}/>
            </Body.PasswordContent>
            <Body.ButtonLogin onClick={()=>send()}>
                Continuar
            </Body.ButtonLogin>
            <Body.TextBtn onClick={()=>resendCode()}>Reenviar código</Body.TextBtn> 
            <Body.TextBtn onClick={()=>navigate('/')}>Voltar para o login?</Body.TextBtn>    
        </>
}

const Recover = () => {
    const navigate = useNavigate()

    const [content, toggleContent] = useState(Contents.SEND)
    const [email, setEmailRecover] = useState("") 
    const [loading, toggleLoading] = useState(false)

    const getContent = () => {
        switch(content){
            case Contents.SEND:
                return <SendCode toggleContent={toggleContent} toggleLoading={toggleLoading}  setEmailRecover={setEmailRecover}/>
            case Contents.RECEIVED:
                return <ReceivedCode toggleContent={toggleContent} toggleLoading={toggleLoading} email={email} />
        }
    }


    return <Body.Container >
        <div>
            <Body.Title>
                DHM4.0
            </Body.Title>
            <Body.Subtitle>
                App de monitoramento
            </Body.Subtitle>
        </div>
        <Body.Card>
            <Body.CardTitle> 
                Recuperar senha
            </Body.CardTitle>
            {getContent()}

        </Body.Card>
        <Body.Wave1 />
        <Body.Wave2 />
        <Body.Wave3 />
        <Body.Logo src={DinamoLogo} />
        {loading?<Loading />:null}
    </Body.Container>
}

export default Recover