import { ChartDataProps, ChartDataPropsTerno, Terno } from "../redux/types"
import warningIcon from '../../assets/icons/warning-icon.svg'
import checkIcon from '../../assets/icons/check-icon.svg'
import errorIcon from '../../assets/icons/error-icon.svg'
import { Dispatch, SetStateAction } from "react"
import { SetState } from "immer/dist/internal"

const Cargos = {
    Administrador: 1
}

const yellow = '#E5D34A'
const green = '#C5EB7F'
const red = '#F03F3F'

export const ReplaceSpecialCharacters = (text: string) => {
    let newText = text
    newText = newText.replaceAll('+','%2B')
    newText = newText.replaceAll('$','%24')
    newText = newText.replaceAll('&','%26')
    newText = newText.replaceAll(',','%2C')
    newText = newText.replaceAll('/','%2F')
    newText = newText.replaceAll(':','%3A')
    newText = newText.replaceAll(';','%3B')
    newText = newText.replaceAll('=','%3D')
    newText = newText.replaceAll('?','%3F')
    newText = newText.replaceAll('@','%40')

    return newText
}

export const getCargo = (cargo: number) => {
    switch (cargo) {
        case Cargos.Administrador: 
            return "Administrador"
        default:
            return "Other"
    }
}

export const verifyEmail = (email: string) => {
    if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)){
        return false
    }
    return true
}

export const getPressurePickup = (pressure: number, minPressure: number, maxPressure: number ) => {
    if(pressure <= minPressure - (minPressure * 0.05)){
        return (pressure / (minPressure - (minPressure * 0.05))) * 5
    }
    if(pressure <= minPressure){
        return ((pressure / minPressure ) * 5) + 5
    }
    if(pressure <= maxPressure){
        return ((pressure / maxPressure) * 80) + 10
    }
    if(pressure <= maxPressure + (maxPressure * 0.05)){
        return ((pressure /(maxPressure + (maxPressure * 0.05))) * 5) + 90
    }
    return ((pressure / 5800) * 5) + 95
}

export const getDeslocamento = (item: Terno) => {
    return Math.abs(item.transmissor_posic_direito - item.transmissor_posic_esquerdo )
}

export const getDeslocamentoPickup = (item: Terno) => {
    let deslocamento = item.transmissor_posic_direito - item.transmissor_posic_esquerdo

    if(deslocamento !== 0){
        if(deslocamento < 0){
            deslocamento = Math.abs(deslocamento)
            if(deslocamento < item.zmax){
                return 50 - ((deslocamento / item.zmax) * 40)
            }else if(deslocamento < item.zmax + 4){
                return 10 - ((deslocamento / (item.zmax + 5)) * 5)
            }else if(deslocamento < 5800){
                return 5 - ((deslocamento / 5800) * 5) 
            }
        }
        else{
            if(deslocamento < item.zmax){
                return ((deslocamento / item.zmax) * 40) + 50
            }else if(deslocamento < item.zmax + 4){
                return ((deslocamento / (item.zmax + 4)) * 5) + 90
            }else if(deslocamento < 5800){
                return ((deslocamento / 5800) * 5) + 95
            }
        }
    }
    return 50
}

export const getPressaoMedia = (pressao1: number, pressao2: number) => {
    return (pressao1 + pressao2)/2
}

export const getTemperaturePickup = (temperature: number) => {
    if(temperature <= 65){
        return (temperature / 65 ) * 95
    }else if(temperature <= 70){
        return (temperature / 70 ) * 100
    }
    return 100
}

export const getSaturacaoStatus = (saturation: number) => {
    return (saturation / 5) * 100
}

export const getAutotestePickup = (pressao: number, pressaoMax: number) => {
    if(pressao <= pressaoMax){
        return (pressao / pressaoMax) * 5
    }
    if(pressao <= (pressaoMax + (pressaoMax * 0.10))){
        return ((pressao / (pressaoMax + (pressaoMax * 0.10))) * 5) + 5
    }
    if(pressao <= 5400){
        return (pressao / 5400) * 80 + 10
    }
    if(pressao <= 5500){
        return ((pressao / 5500) * 5 ) + 90
    }
    return ((pressao / 5800) * 5) + 95
}


export const checkPasswordRestrictions = (text:string) => {


    const getMatch = (regex: RegExp) => {

      const value = text.match(regex)
      return value == null? 0:value.length

    }

      const getLength = () => {
            return text.length >= 12 ? true:false
      }
      const getLowercase = () => {
          return getMatch(/[a-z]/g) > 0
      }

      const getUppercase = () => {
            return getMatch(/[A-Z]/g) > 0
      }

      const getSpecial = () => {
          return getMatch(/[!@#$%ˆ&*()-+~`_={}[\]|\\;:'"<>,.?/]/g) > 0
      }

      const verify = {lenght: getLength(), lowercase: getLowercase(), uppercase: getUppercase(),
                     special: getSpecial()}

      return verify
}

export const getColors = (index: number) => {
    switch(index){
        case 0:
            return ""
    }
}

export const AdjustObs = (obs: string) => {
    const newObs = obs.split(":")
    const observacao = newObs[3]
    const terno = newObs[2]
    const lado = newObs[1]
    let manual 
    let valvula
    if(newObs[0].includes('Manual')){
        valvula = newObs[0].split(' - ')[0]
        manual  = newObs[0].split(' - ')[1]
    }else{
        valvula = newObs[0]
    }

    return {valvula, lado, terno, observacao, manual}
}

export const checkCredentials = (email: string, password: string) => {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) || password.length < 12){
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) && password.length < 12){
            return {email: false,password: false}
        }else if(password.length < 12){
            return {email: false,password: true}
        }else{
            return {email: true,password: false}
        }
    }else{
        return {email: true,password: true}
    }
}

export const getStatusTerno = (status: number) => {
    switch(status){
        case 1:
            return {msg: 'Falha no transmissor de posicão lado oposto', color: yellow, icon: warningIcon}
        case 2:
            return {msg: 'Falha no transmissor de posição lado acionado', color: yellow, icon: warningIcon}
        case 3:
            return {msg: 'Falha no transmissor de pressão lado oposto', color: yellow, icon: warningIcon}
        case 4:
            return {msg: 'Falha no transmissor de pressão lado acionado', color: yellow, icon: warningIcon}
        case 5:
            return {msg: 'Reserva', color: yellow, icon: warningIcon}
        case 6:
            return {msg: 'Reserva', color: yellow, icon: warningIcon}
        case 7:
            return {msg: 'Carga inesperada no lado oposto', color: yellow, icon: warningIcon}
        case 8:
            return {msg: 'Carga inesperada no lado acionado', color: yellow, icon: warningIcon}
        case 9:
            return {msg: 'Vazamento no lado oposto', color: yellow, icon: warningIcon}
        case 10:
            return {msg: 'Vazamento no lado acionado', color: yellow, icon: warningIcon}
        case 11:
            return {msg: 'Problemas no acumulador no lado oposto', color: yellow, icon: warningIcon}
        case 12:
            return {msg: 'Problemas no acumulador no lado acionado', color: yellow, icon: warningIcon}
        case 13:
            return {msg: 'Pressão muito baixa no lado oposto', color: yellow, icon: warningIcon}
        case 14:
            return {msg: 'Pressão muito baixa no lado acionado', color: yellow, icon: warningIcon}
        case 15:
            return {msg: 'Valor inválido de pressão máxima no lado oposto', color: yellow, icon: warningIcon}
        case 16:
            return {msg: 'Valor inválido de pressão máxima no lado acionado', color: yellow, icon: warningIcon}
        case 17:
            return {msg: 'Valor inválido de pressão mínima no lado oposto', color: yellow, icon: warningIcon}
        case 18:
            return {msg: 'Valor inválido de pressão mínima no lado acionado', color: yellow, icon: warningIcon}
        case 19:
            return {msg: 'Set point inválido de pressão', color: yellow, icon: warningIcon}
        case 20:
            return {msg: 'Falha na descarga no lado oposto', color: yellow, icon: warningIcon}
        case 21:
            return {msg: 'Falha na descarga no lado acionado', color: yellow, icon: warningIcon}
        case 22:
            return {msg: 'Falha na carga no lado oposto', color: yellow, icon: warningIcon}
        case 23:
            return {msg: 'Falha na carga no lado acionado', color: yellow, icon: warningIcon}
        case 31:
            return {msg: 'Rolo superior nivelado', color: green, icon: checkIcon}
        case 32:
            return {msg: 'Sistema ajustando pressões', color: green, icon: checkIcon}
        case 33:
            return {msg: 'Diferença de pressão muito elevada', color: red, icon: errorIcon}
        case 34:
            return {msg: 'Diferença de altura muito elevada', color: red, icon: errorIcon}
        case 35:
            return {msg: 'Diferenças de pressão e altura muito elevadas', color: red, icon: errorIcon}
        case 36:
            return {msg: 'Altura e pressão máximas ultrapassadas no lado oposto', color: red, icon: errorIcon}
        case 36:
            return {msg: 'Altura e pressão máximas ultrapassadas no lado acionado', color: red, icon: errorIcon}
        default:
            return {msg: 'Erro ao encontrar status', color: red, icon: errorIcon}
    }
}

export const getStatusUH = (status: number) => {
    switch(status){
        case 1:
            return {msg: 'Falta de óleo na unidade hidráulica', color: yellow, icon: warningIcon}
        case 2:
            return {msg: 'Temperatura acima da máxima', color: yellow, icon: warningIcon}
        case 3:
            return {msg: 'Temperatura próxima da máxima', color: red, icon: errorIcon}
        case 4:
            return {msg: 'Falha no motor 1', color: yellow, icon: warningIcon}
        case 5:
            return {msg: 'Falha no motor 2', color: yellow, icon: warningIcon}
        case 20:
            return {msg: 'Unidade funcionando corretamente', color: green, icon: checkIcon}
        case 21:
            return {msg: 'Sistema operando em local', color: green, icon: checkIcon}
    }
}

export const getTimestamps = (date: Date, time: Date) => {
    let day:number|string = date.getDate()

    if(day < 10){
        day = "0"+day
    }

    let month

    if(date.getMonth() + 1 < 10){
        month = "0"+(date.getMonth() + 1)
    }
    else{
        month = date.getMonth() + 1
    }

    const year = date.getFullYear()

    let hour:number|string = time.getHours()

    if(hour < 10){
        hour = "0"+hour
    }

    let minutes:number|string = time.getMinutes()

    if(minutes < 10){
        minutes = "0"+minutes
    }

    return year+"-"+month+"-"+day+"T"+hour+":"+minutes+":00.0000"
}

export const adjustTime = (time: number) => {
    if(time < 10){
        return "0:0"+time
    }
    if(time < 60){
        return "0:"+time
    }
}

export const ResolveErrors = (status: number, code: string, message: string, navigate: any, dispatch: any, 
    content?: any) => {
console.log(content)
switch(status){
    case 400:
        if(code === 'LGN-1'){
            dispatch({type: 'ADD_NOTIFICATION', status: 3, title: '', text: message})
            return
        }
        if(code === 'LGN-3'){
            dispatch({type: 'ADD_NOTIFICATION', status: 3, title: '', text: message})
            return
        }   
        if(code === 'ACS-11'){
            dispatch({type: 'ADD_NOTIFICATION', status: 2, title: '', text: message+", você será redirecionado para o Conecta para prosseguir com a operação", link: true})
            return
        }
        if(code === 'DSS-1'){
            dispatch({type: 'ADD_NOTIFICATION', status: 2, title: '', text: message})
            // navigate('/privacidade-empresa', {state: {nivel: 1, nome: content.nomeUsuario, filial: content.nomeFilial,
            //     idFilial: content.idFilial}})
            return
        }
        if(code === 'DSS-2'){
            dispatch({type: 'ADD_NOTIFICATION', status: 2, title: '', text: message+", você será redirecionado para o Conecta para prosseguir com a operação", link: true})
            return
        }
        dispatch({type: 'ADD_NOTIFICATION', status: 3, title: '', text: message})
        break;
    case 401:
        if(code === 'ACS-9'){
            localStorage.setItem("@logged", "")
            navigate('/')
        }
        if(code === 'ACS-10'){            
            localStorage.setItem("@logged", "")
            navigate('/')
        }
        dispatch({type: 'ADD_NOTIFICATION', status: 3, title: '', text: message})
        break; 
    case 403:
        if(code === 'ACS-4'){
            dispatch({type: 'ADD_NOTIFICATION', status: 2, title: '', text: message+", você será redirecionado para o Conecta para prosseguir com a operação", link: true})
            return
        }
        // if(code === 'ACS-6'){
        //     dispatch({type: 'ADD_NOTIFICATION', status: 2, title: '', text: message})
        //     navigate('/autenticacao')
        //     return
        // }
        // if(code === 'ACS-7'){
        //     dispatch({type: 'ADD_NOTIFICATION', status: 2, title: '', text: message})
        //     navigate('/autenticacao')
        //     return
        // }
        // dispatch({type: 'ADD_NOTIFICATION', status: 3, title: '', text: message})
        break; 
    case 500:
        dispatch({type: 'ADD_NOTIFICATION', status: 3, title: '', text: "Internal server error"})
        break  
    default:
        dispatch({type: 'ADD_NOTIFICATION', status: 3, title: "", text: message})
        break   
}       
}

const getDate = (date: Date) => {
    const day = date.getDate()
    let month
    if(date.getMonth() + 1 < 10){
        month = "0"+(date.getMonth() + 1)
    }
    else{
         month = date.getMonth() + 1
    }
    const year = date.getFullYear()
 
    const getHour = () => {
        let value = ''
        if(date.getHours() < 10) {
            value = '0'+date.getHours()+":"
        }else{
            value = date.getHours()+":"
        }
        if(date.getMinutes() < 10){
            value = value + "0"+date.getMinutes()
        }else{
            value = value + date.getMinutes()
        }
        return value
    }

    return day+"/"+month+"/"+year+" "+getHour()
    
}


export const getChartDataTerno = (response: any, content: string) => {
    let dataToReturn: ChartDataPropsTerno = []
    let countTerno = 1
    
    let min = 100000
    let max = -10000
    
    let min2 = 100000
    let max2 = -10000

    const getConfigTerno = (arr: any) => {
        const timestamps = [];
        const pressao_ld = [];
        const pressao_le = [];
        const deslocamento_ld = [];
        const deslocamento_le = [];

        for (const objeto of arr) {
            timestamps.push(getDate(new Date(objeto.timestamp)));
            pressao_ld.push((objeto.pressao_ld).toFixed(0));
            pressao_le.push((objeto.pressao_le).toFixed(0));
            deslocamento_ld.push((objeto.deslocamento_ld).toFixed(2));
            deslocamento_le.push((objeto.deslocamento_le).toFixed(2));
        }

        return {timestamps, pressao_ld, pressao_le, deslocamento_ld, deslocamento_le}
    }

    const adjustDataTernoP = (arr: any) => {
        const {timestamps, pressao_ld, pressao_le} = getConfigTerno(arr)
        min = pressao_ld[0]
        max =  pressao_ld[0]
        for(let i = 0; i < pressao_ld.length; i++) {
            if(min > pressao_ld[i]){min = pressao_ld[i]}
            if(min > pressao_le[i]){min = pressao_le[i]}
            if(max < pressao_ld[i]){max = pressao_ld[i]}
            if(max < pressao_le[i]){max = pressao_le[i]}
        }
        return  {
            labels: timestamps,
            datasets: [{
                label: 'Pressão Direito',
                data: pressao_ld,
                fill: false,
                backgroundColor: 'rgba(134, 65, 244, 0.8)',
                borderColor: 'rgba(134, 65, 244, 0.8)'
            },
            {
                label: 'Pressão Esquerdo',
                data: pressao_le,
                fill: false,
                backgroundColor: 'rgba(255, 87, 51, 0.8)',
                borderColor: 'rgba(255, 87, 51, 0.8)'
            }]}
    } 

    const adjustDataTernoD = (arr: any) => {
        const {timestamps, deslocamento_ld, deslocamento_le} = getConfigTerno(arr)
        min2 = deslocamento_ld[0]
        max2 =  deslocamento_ld[0]
        for(let i = 0; i < deslocamento_ld.length; i++) {
            if(min2 > deslocamento_ld[i]){min2 = deslocamento_ld[i]}
            if(min2 > deslocamento_le[i]){min2 = deslocamento_le[i]}
            if(max2 < deslocamento_ld[i]){max2 = deslocamento_ld[i]}
            if(max2 < deslocamento_le[i]){max2 = deslocamento_le[i]}
        }
        return  {
            labels: timestamps,
            datasets: [
            {
                label: 'Deslocamento Direito',
                data: deslocamento_ld,
                fill: false,
                backgroundColor: 'rgba(42, 182, 96, 0.8)',
                borderColor: 'rgba(42, 182, 96, 0.8)'
            },{
                label: 'Deslocamento Esquerdo',
                data: deslocamento_le,
                fill: false,
                backgroundColor: 'rgba(75,217,0,0.8)',
                borderColor: 'rgba(75,217,0,0.8)'
            }]}
    } 

    for(let i = 0; i < response.length; i++){
        dataToReturn.push({name: 'Terno '+countTerno, config: {pressao: adjustDataTernoP(response[i]), deslocamento: adjustDataTernoD(response[i])},
        ranges: {pressao: {min: min, max: max}, deslocamento: {min: min2, max: max2}}})
        console.log(max)
        countTerno++
    }

    return dataToReturn
}

export const getChartDataGen = (response: any, content: string) => {
    let dataToReturn: ChartDataProps = []
    let countTerno = 1
    let min = 100000
    let max = -10000

    console.log(response)

    const getConfig = (arr: any) => {
        const timestamps = [];
        const valores = [];

        for (const objeto of arr) {
            timestamps.push(getDate(new Date(objeto.timestamp)));
            valores.push(objeto.value);
            console.log(objeto)
        }

        return {timestamps, valores}
    }

    const getConfigCritico = (arr: any) => {
        const timestamps = [];
        const valores = [];

        for (const objeto of arr) {
            timestamps.push(getDate(new Date(objeto.timestamp)));
            valores.push(objeto.countEqual31 + objeto.countNotEqual31);
        }

        return {timestamps, valores}
    }

    const adjustDataCritico = (arr: any, label: string) => {
        const {timestamps, valores} = getConfigCritico(arr)
        min = valores[0]
        max =  valores[0]
        for(let i = 0; i < valores.length; i++) {
            if(min > valores[i]){min = valores[i]}
            if(max < valores[i]){min = valores[i]}
        }
        return  {
            labels: timestamps,
            datasets: [{
                label: label,
                data: valores,
                fill: false,
                backgroundColor: 'rgba(134, 65, 244, 0.8)',
                borderColor: 'rgba(134, 65, 244, 0.8)'
            }]}
    } 

    const adjustData = (arr: any, label: string) => {
        const {timestamps, valores} = getConfig(arr)
        min = valores[0]
        max =  valores[0]
        for(let i = 0; i < valores.length; i++) {
            if(min > valores[i]){min = valores[i]}
            if(max < valores[i]){min = valores[i]}
        }
        return  {
            labels: timestamps,
            datasets: [{
                label: label,
                data: valores,
                fill: false,
                backgroundColor: 'rgba(134, 65, 244, 0.8)',
                borderColor: 'rgba(134, 65, 244, 0.8)'
            }]}
    } 
    
    switch(content){
        case 'temperatura-oleo': 
            dataToReturn.push({name: 'Temperatura óleo', config: adjustData(response, 'Temperatura Óleo'), ranges: {min: min, max: max}})     
            break
        case 'umidade-oleo':
            dataToReturn.push({name: 'Umidade óleo', config: adjustData(response, 'Umidade Óleo'), ranges: {min: min, max: max}})
            break
        case 'pressao-filtro':
            dataToReturn.push({name: 'Pressão filtro 1', config: adjustData(response[0], 'Pressão filtro'), ranges: {min: min, max: max}})
            dataToReturn.push({name: 'Pressão filtro 2', config: adjustData(response[1], 'Pressão filtro'), ranges: {min: min, max: max}})
            break
        case 'terno-critico':
            for(let i = 0; i < response.length; i++){
                dataToReturn.push({name: 'Terno '+countTerno, config: adjustDataCritico(response[i],'Terno '+countTerno), ranges: {min: min, max: max}})
                countTerno++
            }
            break
    }

    return dataToReturn

}

export const getChartData = (response: any, content: string) => {
    let dataToReturn: ChartDataProps = []
    let countTerno = 1
    let min = 100000
    let max = -10000

    const getConfig = (arr: any) => {
        const timestamps = [];
        const valores = [];

        for (const objeto of arr) {
            timestamps.push(getDate(new Date(objeto.timestamp)));
            valores.push(objeto.value);
        }

        return {timestamps, valores}
    }

    const getConfigCritico = (arr: any) => {
        const timestamps = [];
        const valores = [];

        for (const objeto of arr) {
            timestamps.push(getDate(new Date(objeto.timestamp)));
            valores.push(((objeto.falseCount / (objeto.trueCount + objeto.falseCount))*100));
        }

        return {timestamps, valores}
    }

    const adjustDataCritico = (arr: any, label: string) => {
        const {timestamps, valores} = getConfigCritico(arr)
        min = valores[0]
        max =  valores[0]
        for(let i = 0; i < valores.length; i++) {
            if(min > valores[i]){min = valores[i]}
            if(max < valores[i]){min = valores[i]}
        }
        return  {
            labels: timestamps,
            datasets: [{
                label: label,
                data: valores,
                fill: false,
                backgroundColor: 'rgba(134, 65, 244, 0.8)',
                borderColor: 'rgba(134, 65, 244, 0.8)'
            }]}
    } 

    const adjustData = (arr: any, label: string) => {
        const {timestamps, valores} = getConfig(arr)
        min = valores[0]
        max =  valores[0]
        for(let i = 0; i < valores.length; i++) {
            if(min > valores[i]){min = valores[i]}
            if(max < valores[i]){min = valores[i]}
        }
        return  {
            labels: timestamps,
            datasets: [{
                label: label,
                data: valores,
                fill: false,
                backgroundColor: 'rgba(134, 65, 244, 0.8)',
                borderColor: 'rgba(134, 65, 244, 0.8)'
            }]}
    } 
    
    switch(content){
        case 'autoteste':
            if(response.unidade_hidraulica_2){
                dataToReturn.push({name: 'Teste bomba 1 - UH1', config: adjustData(response.unidade_hidraulica_1.teste_bomba_1, 'Bomba 1'), ranges: {min: min, max: max}})
                dataToReturn.push({name: 'Teste bomba 2 - UH1', config: adjustData(response.unidade_hidraulica_1.teste_bomba_2, 'Bomba 2'), ranges: {min: min, max: max}})
                dataToReturn.push({name: 'Teste bomba 1 - UH2', config: adjustData(response.unidade_hidraulica_2.teste_bomba_1, 'Bomba 1'), ranges: {min: min, max: max}})
                dataToReturn.push({name: 'Teste bomba 2 - UH2', config: adjustData(response.unidade_hidraulica_2.teste_bomba_2, 'Bomba 2'), ranges: {min: min, max: max}})
            }else{
                dataToReturn.push({name: 'Teste bomba 1', config: adjustData(response.unidade_hidraulica_1.teste_bomba_1, 'Bomba 1'), ranges: {min: min, max: max}})
                dataToReturn.push({name: 'Teste bomba 2', config: adjustData(response.unidade_hidraulica_1.teste_bomba_2, 'Bomba 2'), ranges: {min: min, max: max}})
            }
            break
        case 'temperatura-oleo': 
            if(response.unidade_hidraulica_2){
                dataToReturn.push({name: 'Temperatura óleo - UH1', config: adjustData(response.unidade_hidraulica_1, 'Temperatura Óleo'), ranges: {min: min, max: max}})
                dataToReturn.push({name: 'Temperatura óleo - UH2', config: adjustData(response.unidade_hidraulica_2, 'Temperatura Óleo'), ranges: {min: min, max: max}})
            }else{
                dataToReturn.push({name: 'Temperatura óleo', config: adjustData(response.unidade_hidraulica_1, 'Temperatura Óleo'), ranges: {min: min, max: max}})
            }
            break
        case 'umidade-oleo':
            if(response.unidade_hidraulica_2){
                dataToReturn.push({name: 'Umidade óleo - UH1', config: adjustData(response.unidade_hidraulica_1, 'Umidade Óleo'), ranges: {min: min, max: max}})
                dataToReturn.push({name: 'Umidade óleo - UH2', config: adjustData(response.unidade_hidraulica_2, 'Umidade Óleo'), ranges: {min: min, max: max}})
            }else{
                dataToReturn.push({name: 'Umidade óleo', config: adjustData(response.unidade_hidraulica_1, 'Umidade Óleo'), ranges: {min: min, max: max}})
            }
            break
        case 'pressao-filtro':
            dataToReturn.push({name: 'Pressão filtro 1', config: adjustData(response.filtro_bomba_1, 'Pressão filtro'), ranges: {min: min, max: max}})
            dataToReturn.push({name: 'Pressão filtro 2', config: adjustData(response.filtro_bomba_2, 'Pressão filtro'), ranges: {min: min, max: max}})
            break
        case 'terno-critico':
            for(let i = 0; i < response.length; i++){
                dataToReturn.push({name: 'Terno '+countTerno, config: adjustDataCritico(response[i],'Terno '+countTerno), ranges: {min: min, max: max}})
                countTerno++
            }
            break
    }

    return dataToReturn

}

export const getDateRange = (time: Date, date: Date) => {
    const day = date.getDate()
    let month
    if(date.getMonth() + 1 < 10){
        month = "0"+(date.getMonth() + 1)
    }
    else{
            month = date.getMonth() + 1
    }
    const year = date.getFullYear()
    let hour
    if(time.getHours() < 10){
        hour = "0"+(time.getHours())
    }
    else{
        hour = time.getHours() 
    }
    let minutes
    if(time.getMinutes() < 10){
        minutes = "0"+(time.getMinutes() )
    }
    else{
        minutes = time.getMinutes()
    }

    return year+"-"+month+"-"+day+"T"+hour+":"+minutes+":00.000Z"
    
}

export const getIsoString = (year: string, month: string, day: string, hour: string) => {
    return year+"-"+month+"-"+day+"T"+hour+":00.000Z"
}