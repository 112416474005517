/*Definição de valores para elementos do style components para padronização do app */

export const BackgroundContainer = '-webkit-linear-gradient(65deg, #202833, #16202D)'

export const ModalBackground = '-webkit-linear-gradient(65deg, #16202D, #16202D)'

export const TitleColor = "#FFF"

export const tecdinBlue = "rgba(82, 146, 209, 1)"

export const tecdinGreen = "rgba(197, 235, 127, 1)"