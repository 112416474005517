import React from "react";
import * as Body from './Styles'
const DinamoLogo = require('../../assets/images/dinamo_logo.png')
const PlayStore = require('../../assets/images/android.png')
const AppStore = require('../../assets/images/ios.png')

const Mobile = () => {
    return <Body.Container>
        <div style={{display: 'flex', position: 'absolute', top: '40px'}}>
         <div>
           <Body.Title>
                DHM4.0
            </Body.Title>
            <Body.Subtitle>
                App de monitoramento
            </Body.Subtitle>
           </div>
            <Body.Subtitle style={{fontSize: '20px', marginTop: '15px'}}>
                Beta
            </Body.Subtitle>
        </div>
        <div>
            {/* <Body.TextBtn style={{textAlign: 'center', marginBottom: 20}}>
                Baixe o aplicativo na loja de aplicativos do seu smartphone para uma melhor experiência
            </Body.TextBtn>
            <Body.Row>
                <Body.ButtonImg src={PlayStore} />
                <Body.ButtonImg src={AppStore} />
            </Body.Row> */}
            <Body.TextBtn style={{textAlign: 'center', marginBottom: 20}}>
                Atualmente as funcionalidades de nosso aplicativo ainda não estão disponíveis para dispositivos movéis.
                 Por gentilize utilize um computador.
            
            </Body.TextBtn>
            
        </div>
        <Body.Wave1 />
        <Body.Wave2 />
        <Body.Wave3 />
        <Body.Logo src={DinamoLogo} />
    </Body.Container>

}

export default Mobile