import styled, { keyframes } from 'styled-components'

export const Button = styled.button`
    height: 40px;
    background: none;
    border: solid 1px #FFF;
    transition: .3s;
    color: #FFF;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    border-radius: 25px;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 18px;
    font-family: "Nunito";

    cursor: pointer;
    position: relative;
    display: flex;
    grid-gap: 0px 15px;

    :hover{
        transition: .3s;
        background: #FFF;
        opacity: 0.8;
        color: #000;
    }

    :disabled{
        background: none;
        cursor: default;
        color: #fff;
        opacity: 0.6;
    }
`
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Icon = styled.img`
    height: 30px;
    width: 30px;
    animation: ${rotate} .8s linear infinite;
`