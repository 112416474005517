import styled from "styled-components";

export const DigitsContent = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

export const NumberInput = styled.input`
    width: 23%;
    height: 40px;
    border: none;
    border-bottom: solid 2px rgba(255,255,255,0.5);
    background: none;
    outline: none;
    color: white;
    margin-top: 10px;
    padding-left: 9%;
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box;    
    box-sizing: border-box;
    outline: none;
    transition: .5s;

    :focus{
        transition: .5s;
        width: 25%;
        border-bottom: solid 2px #FFF;
    }
`

export const CodeInput = styled.input`
    width: 15%;
    height: 40px;
    border: none;
    border-bottom: solid 2px rgba(255,255,255,0.5);
    background: none;
    outline: none;
    color: white;
    margin-top: 10px;
    padding-left: 6%;
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box;    
    box-sizing: border-box;
    outline: none;
    transition: .5s;

    :focus{
        transition: .5s;
        width: 16%;
        border-bottom: solid 2px #FFF;
    }
`