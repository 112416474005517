import * as Body from './Styles'

import warningIcon from '../../assets/icons/warning-icon.svg'
import checkIcon from '../../assets/icons/check-icon.svg'
import SpinnerIcon from '../../assets/icons/spinner.svg'
import { useEffect, useRef } from 'react'

import { useSelector, useDispatch } from "react-redux";
import { State } from "../../utils/redux/store";

type NotifyProps = {
    status: number,
    title: string,
    text?: string
}


const WaitComponent = () => {
    const wait = useSelector((state: State) => state.wait)
    const dispatch = useDispatch()

    return <Body.Container>
        {wait?.map((item, index)=>{
            return <Body.Notify key={index.toString()} onClick={()=>dispatch({type: 'REMOVE_NOTIFICATION'})}
                        style={{bottom: 'calc(20px + '+(70 * index)+'px' }}>
                    <Body.TextContent>
                    <Body.Icon src={SpinnerIcon} size={50} />
                        <Body.Text>
                            {item.text}
                        </Body.Text>
                    </Body.TextContent>
            </Body.Notify>})}
    </Body.Container>
}

export default WaitComponent