import styled from "styled-components";

export const Content = styled.div`
    width: 500px;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const Row = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
`

export const Scroll = styled.div`
    width: 100%;
    overflow-y: scroll;
    ::-webkit-scrollbar{
        display: none;
    }`

export const HalfContent = styled.div<{percentage: number}>`
    height: 100%;
    width: ${props => props.percentage}%;
`
export const Half = styled.div`
    width: 48%;
`

export const Text = styled.p`
    margin: 0;
    font-family: "Nunito";
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 10px;
    color: rgba(255,255,255,0.8)
`
export const UserBtn = styled.div`
    width: 100%;
    height: 80px;
    border-radius: 25px;
    cursor: pointer;
    margin-bottom: 5px;
    background: rgba(255,255,255,0.1);
    border: none;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    :hover{
        background: rgba(255,255,255,0.05);
    }
`

export const SubmitImage = styled.label`
height: 40px;
background: none;
border: solid 1px #FFF;
transition: .3s;
color: #FFF;
padding-left: 15px;
padding-right: 15px;
box-sizing: border-box;
border-radius: 25px;
align-items: center;

cursor: pointer;
position: relative;
display: flex;
grid-gap: 0px 15px;

:hover{
    transition: .3s;
    background: #FFF;
    color: #000;
}
`
export const InputFile = styled.input`
    display: none;
`

export const PasswordContent = styled.div`
    position: relative;
    width: 100%;
`

export const Icon = styled.img`
    height: 20px;
    width: 50px;
    bottom: 30px;
    right: 20px;
    cursor: pointer;
    position: absolute;
`

export const DigitsContent = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
`

export const CodeInput = styled.input`
    width: 15%;
    height: 40px;
    border: none;
    border-bottom: solid 2px rgba(255,255,255,0.5);
    background: none;
    outline: none;
    color: white;
    margin-top: 10px;
    padding-left: 6%;
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box;    
    box-sizing: border-box;
    outline: none;
    transition: .5s;

    :focus{
        transition: .5s;
        width: 16%;
        border-bottom: solid 2px #FFF;
    }
`

export const NumberInput = styled.input`
    width: 23%;
    height: 40px;
    border: none;
    border-bottom: solid 2px rgba(255,255,255,0.5);
    background: none;
    outline: none;
    color: white;
    margin-top: 10px;
    padding-left: 10%;
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box;    
    box-sizing: border-box;
    outline: none;
    transition: .5s;

    :focus{
        transition: .5s;
        width: 24%;
        border-bottom: solid 2px #FFF;
    }
`


export const InputBtn = styled.button`
    width: 100%;
    height: 35px;
    background: none;
    border: none;
    border-bottom: 2px solid rgba(255,255,255,0.5);
    outline: none;
    padding-left: 15px;
    box-sizing: border-box;
    margin-bottom: 15px;
    transition: .5s;
    text-align: left;

    :hover{
        width: 100%;
        border-bottom: 2px solid rgba(255,255,255,1);
        transition: .5s;
    }
    :disabled{
        opacity: 0.5;
    }
`

export const BtnText = styled.p`
    margin: 0;
    color: #FFF;
    font-family: Nunito;
    letter-spacing: 1.5px; 
    cursor: pointer; 
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
`

export const Pefil = styled.div`
    width: 80%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`

export const PerfilTextContent = styled.div`

`

export const PerfilName = styled.p`
    margin: 0;
    color: #FFF;
    font-size: 20px;
    font-family: "Montserrat";
    font-weight: 600;
`
export const PerfilCargo = styled.p`
    margin: 0;
    color: #FFF;
    font-size: 20px;
    font-family: "Montserrat";
    font-weight: 400;
`

export const UsersContent = styled.div`
    width: 500px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow-y: scroll;
    ::-webkit-scrollbar{
        display: none;
    }
`


export const Photo = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 10px;
`
