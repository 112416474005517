import * as Body from './Styles'
const DinamoLogo = require('../../assets/images/dinamo_logo.png')

const Loading = () => { 

    return <Body.Container>
           <div>
            <div style={{display: 'flex'}}>
                <Body.Title>
                    DHM4.0
                </Body.Title>
            </div>
            <Body.Subtitle>
                App de monitoramento
            </Body.Subtitle>
           </div>
        <Body.Logo src={DinamoLogo} />
    </Body.Container>
}

export default Loading