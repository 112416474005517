import { HomeState } from "../types";
const foto = require('../../../assets/images/avatar-4.png')

const initialState: HomeState = {
    moendas: [],
    usuario: { p_nome: '',
                s_nome: '',
                empresa: ''
            },
    // notificacoes: []
}

const home = (
    state: HomeState = initialState,
) => {
    return state;
}

export default home