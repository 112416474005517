import styled, {keyframes} from 'styled-components'
import * as Define from '../../utils/defines/defines'

export const Container = styled.div`
    width: 100w;
    height: 100vh;
    background: ${Define.BackgroundContainer};
    display: flex;
    padding: 40px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    background-size: cover;
`
const CardAnimate = keyframes`
    from{
        right: -40vw;
    }
    to{
        right: 0;
    }
`

export const Card = styled.div`
    height: 100%;
    width: 45vw;
    background: rgb(38,45,55);
    backdrop-filter: blur(4px);
    border: none;
    padding: 60px;
    box-sizing: border-box;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    right: 0;
    animation: .5s ${CardAnimate} ease-in;
    top: 0;
    z-index: 2;
`

export const Title = styled.p`
    margin: 0;
    font-family: "Montserrat";
    background: -webkit-linear-gradient(45deg, rgb(62, 147, 216), rgb(76, 185, 49));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    font-weight: bold;
    font-size: 48px;
    letter-spacing: 1.5px;
`

export const CardTitle = styled.p`
    margin: 0;
    font-family: "Montserrat";
    color: #fff;
    margin-bottom: 45px; 
    font-weight: bold;
    font-size: 34px;
    letter-spacing: 1.5px;
`
export const Subtitle = styled.p`
    margin: 0;
    font-family: 'Nunito';
    font-size: 20px;
    color: rgba(255,255,255,0.5);
`

export const Text = styled.p`
    margin: 0;
    font-family: 'Nunito';
    font-size: 17px;
    color: rgba(255,255,255,0.9);
`

export const InputTitle = styled.p`
    margin: 0;
    font-family: "Nunito";
    font-size: 14px;
    font-weight: 400;
    color: #FFF;
    opacity: 0.8;
`

export const HeaderIcon = styled.img`
    width: 80px
`

export const TextBtn = styled.p`
    margin: 0;
    color: rgba(255,255,255,0.5);
    cursor: pointer;
    text-transform: uppercase;

    font-family: 'Nunito';
    font-size: 12px;
    letter-spacing: 1.5px;
    margin-top: 20px;
    width: 100%;
    text-align: right;
    transition: .3s;
    :hover{
        transition: .3s;
        color: #fff;
    }
`

export const ContactBtn = styled.p`
    margin: 0;
    position: absolute;
    bottom: 20px;
    text-align: center;
    width: 100px;
    right: calc(20vw + 10px);
    color: rgba(255,255,255,0.5);
    cursor: pointer;
    text-transform: uppercase;

    font-family: 'Nunito';
    font-size: 12px;
    letter-spacing: 1.5px;
`

export const Logo = styled.img`
    width: 150px;
    position: absolute;
    left: 40px;
    bottom: 40px;
    z-index: 2;
`

export const DigitsContent = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

export const NumberInput = styled.input`
    width: 23%;
    height: 40px;
    border: none;
    border-bottom: solid 2px rgba(255,255,255,0.5);
    background: none;
    outline: none;
    color: white;
    margin-top: 10px;
    padding-left: 9%;
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box;    
    box-sizing: border-box;
    outline: none;
    transition: .5s;

    :focus{
        transition: .5s;
        width: 25%;
        border-bottom: solid 2px #FFF;
    }
`

export const CodeInput = styled.input`
    width: 15%;
    height: 40px;
    border: none;
    border-bottom: solid 2px rgba(255,255,255,0.5);
    background: none;
    outline: none;
    color: white;
    margin-top: 10px;
    padding-left: 6%;
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box;    
    box-sizing: border-box;
    outline: none;
    transition: .5s;

    :focus{
        transition: .5s;
        width: 16%;
        border-bottom: solid 2px #FFF;
    }
`

const animateWave1 = keyframes`
    0%{bottom: -300px;}
    30%{bottom: -200px;}
    100%{bottom: -300px;}
`

const animateWave2 = keyframes`
    0%{bottom: -300px;}
    10%{bottom: -300px;}
    40%{bottom: -200px}
    100%{bottom: -300px;}
`
const animateWave3 = keyframes`
    0%{bottom: -300px;}
    20%{bottom: -300px;}
    50%{bottom: -200px}
    100%{bottom: -300px;}
`

const AnimateDuration = 5

export const Wave1 = styled.div`
    position: absolute;
    background-color: #5292D1;
    height: 250px;
    width: 20vw;
    z-index: 1;
    border-radius: 50px;
    left: 20vw;
    transition: .5s ease-in-out;    
    animation-name: ${animateWave1};
    animation-duration: ${AnimateDuration}s;
    animation-iteration-count: infinite;
    filter: blur(4em)
`
export const Wave2 = styled.div`
    position: absolute;
    background-color: #5292D1;
    height: 20vw;
    width: 20vw;
    z-index: 1;
    border-radius: 50px;
    transition: .5s ease-in-out;
    animation-name: ${animateWave2};
    animation-duration: ${AnimateDuration}s;
    animation-iteration-count: infinite;
    filter: blur(4em);
`
export const Wave3 = styled.div`
    position: absolute;
    background-color: #5292D1;
    height: 250px;
    width: 20vw;
    z-index: 1;
    border-radius: 50px;
    left: 40vw;
    transition: .5s ease-in-out;
    animation-name: ${animateWave3};
    animation-duration: ${AnimateDuration}s;
    animation-iteration-count: infinite;
    filter: blur(4em)
`
export const PasswordContent = styled.div`
    position: relative;
`

export const Icon = styled.img`
    height: 20px;
    width: 50px;
    bottom: 30px;
    right: 20px;
    cursor: pointer;
    position: absolute;
`