import styled from "styled-components";

export const Input = styled.input<{align?: string, paddingRight?: number}>`
    width: 98%;
    height: 45px;
    background: none;
    border: none;
    border-bottom: 2px solid rgba(255,255,255,0.5);
    outline: none;
    padding-left: 15px;
    box-sizing: border-box;
    color: #FFF;
    letter-spacing: 1.5px;  
    margin-bottom: 15px;
    transition: .5s;
    padding-right: ${props => props.paddingRight}px;
    
    text-align: ${props => props.align};

    :focus{
        width: 100%;
        border-bottom: 2px solid rgba(255,255,255,1);
        transition: .5s;
    }
    
`