import { data } from "../../data";
import { SELECT_MOENDA } from "../../moenda/actionsType";
import { ParametrizationTerno } from "../../types";
import { ParametrizationPorTernoAction, SELECT_PARAMS_TERNO } from "./actionType";

const parametrizacaoPorTerno = (
    state: ParametrizationTerno = data[0].parametrizacao.por_terno[0],
    action: ParametrizationPorTernoAction
) => {
    return state

}

export default parametrizacaoPorTerno