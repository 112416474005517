import styled, { keyframes } from 'styled-components'
import * as Define from '../../utils/defines/defines'

export const Container = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
`
export const BtnIcon = styled.img`
    height: 16px;
    width: 16px;
`

export const AnimateOpen = keyframes`
    from{
        right: -50%;
    }
    to{
        right: 0;
    }
`

export const AnimateClose = keyframes`
    from{
        right: 0;
    }
    to{
        right: -50%;
    }
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Centralize = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Icon = styled.img`
    height: 50px;
    width: 50px;
    animation: ${rotate} .8s linear infinite;
`

export const Modal = styled.div<{close: boolean}>`
    height: 100%;
    width: 50%;
    right: 0%;
    background: ${Define.ModalBackground};
    padding: 30px;
    box-sizing: border-box;
    position: absolute;
    animation-name: ${AnimateOpen};
    animation-duration: .5s;
    animation-timing-function: ease-in;
    padding-bottom: 70px;
`

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`

export const TitleContent = styled.div`
    display: flex;
    flex-direction: column
`

export const Title = styled.p`
    margin: 0;
    font-family: "Montserrat";
    font-size: 20px;
    color: #FFF;
    font-weight: 600;
`
export const SubTitle = styled.p`
    margin: 0;
    font-family: "Nunito";
    font-size: 18px;
    color: #FFF;
    opacity: 0.8;
`

export const Content = styled.div`
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none; 
    }
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`