import userList from "./userList/reducer";
import moenda from "./moenda/reducer";
import user from "./user/reducer";
import home from "./home/reducer";
import terno from "./terno/reducer";
import ternos from "./ternos/reducer";
import {combineReducers, createStore, applyMiddleware} from 'redux'
import intervencoes from "./intervencoes/reducer";
import parametrizacaoUnidadeHidraulica from "./parametrização/unidadeHidraulica/reducer";
import parametrizacaoPorTerno from "./parametrização/porTerno/reducer";
import notifications from "./notifications/reducer"; 
import wait from "./wait/reducer";

import { configureStore } from "@reduxjs/toolkit";

const rootReducer = combineReducers({
  users: userList,
  user: user,
  moenda: moenda,
  home: home,
  terno: terno,
  ternos: ternos,
  intervencoes: intervencoes,
  parametrizacaoUH: parametrizacaoUnidadeHidraulica,
  parametrizacaoPT: parametrizacaoPorTerno,
  notifications: notifications,
  wait: wait
})


const store = configureStore({
  reducer: rootReducer
})

export default store

export type State = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch