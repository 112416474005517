import React, {Dispatch, ReactChild, SetStateAction, useRef, useState } from 'react'
import * as Body from './Styles'

import {Button} from '../Button/Styles'

import closeIconActive from '../../assets/icons/close-icon-active.svg'
import closeIconInactive from '../../assets/icons/close-icon.svg'
import Spinner from '../../assets/icons/spinner.svg'

type Props = {
    toggleModal: Dispatch<SetStateAction<boolean>>,
    title?: string;
    subtitle?: string;
    loading?: boolean;
    children?: ReactChild,
    zindex?: number
}

const ListModal: React.FC <Props> = ({
    toggleModal, 
    title, 
    subtitle,
    loading,
    children,
    zindex
}) => { 
    const modalRef = useRef<any>()
    const close = false
    const [closeIcon, toggleClose] = useState(closeIconInactive)

    return <Body.Container style={{zIndex: zindex?zindex:1}}>
        <Body.Modal close={close} ref={modalRef} >
            <Body.Header>
                <Body.TitleContent>
                    {title?<Body.Title>{title}</Body.Title>:null}
                    {subtitle?<Body.SubTitle>{subtitle}</Body.SubTitle>:null}
                </Body.TitleContent>
                <Button onMouseEnter={()=>toggleClose(closeIconActive)}
                    onMouseLeave={()=>toggleClose(closeIconInactive)}
                    onClick={()=>toggleModal(false)}>
                    <Body.BtnIcon src={closeIcon} />
                </Button>
            </Body.Header>
            <Body.Content>
                {loading?<Body.Centralize><Body.Icon src={Spinner} /></Body.Centralize>:children}
            </Body.Content>
        </Body.Modal>
    </Body.Container>
}

export default ListModal