import React, { useEffect } from "react";
import { UserList, User } from "../types";
import { UserListAction } from "./actionTypes";
import * as actionTypes from './actionTypes'

const avatar1 = require('../../../assets/images/avatar-1.png')
const avatar2 = require('../../../assets/images/avatar-2.png')
const avatar3 = require('../../../assets/images/avatar-3.png')
const avatar4 = require('../../../assets/images/avatar-4.png')
const avatar5 = require('../../../assets/images/avatar-5.png')
const avatar6 = require('../../../assets/images/avatar-6.png')

export const initialState: UserList = [
]
const userList = (
    state: UserList = initialState,
    action: UserListAction) => {
    return state
}

export default userList