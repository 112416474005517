import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import * as Body from '../Styles'
import * as ModalBody from './Styles'
import * as functions from '../../../utils/functions/functions'
import * as Types from '../../../utils/redux/types'
import * as Regex from '../../../utils/Regex/Regex'

import ListModal from '../../../components/ListModal/ListModal'
import Modal from '../../../components/Modal/Modal'
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { State } from "../../../utils/redux/store";
import { Button } from "../../../components/Button/Styles";

import downArrow from '../../../assets/icons/down-arrow.svg'
import axios from "axios";

import warningIcon from '../../../assets/icons/warning-icon.svg'
import checkIcon from '../../../assets/icons/check-icon.svg'
import cloudEqualIcon from '../../../assets/icons/cloud-equal.svg'
import { Input } from "../../../components/Input/Style";
import Loading from "../../../components/Loading/Loading";
import SelectItem from "../../../components/SelectItem/SelectItem";
import { FakeTernoDefine, FakeUHDefine, FakeInterventions, intervencoes, dataMoenda, parametros_por_terno } from "../../../utils/redux/data";

import {desempenho} from '../../../utils/redux/data'
import { useNavigate } from "react-router-dom";

type Props = {
    toggleModal: Dispatch<SetStateAction<boolean>>,
    token?: string,
    uh?: number,
    id?: number
    nome?: string,
    ternos?: Types.Terno[]
}


type SelectProps = {
    items: string[],
    selectItem: any,
    toggleModal: any,
    title: string
}

type InterventionProps = {
    toggleModal: Dispatch<SetStateAction<boolean>>,
    token?: string,
    qtdTernos: number
}

type RegisterInterventionProps = {
    toggleModal: Dispatch<SetStateAction<boolean>>,
    token?: string,
    qtdTernos: number
}

type EditInterventionProps = {
    toggleModal: Dispatch<SetStateAction<boolean>>,
    token?: string,
    qtdTernos: number
    data: Types.Intervencao,
    getData: any
}

type NotificationProps = {
    toggleModal: Dispatch<SetStateAction<boolean>>
    notificacoes: {
        id: number,
        titulo: string,
        mensagem: string,
        resolvido: boolean,
        data?: string,
        hora?: string,
        responsavel: string,
    }[]
}
type VisualizarTernoProps = {
    toggleModal: Dispatch<SetStateAction<boolean>>
    terno: Types.Terno
}

type DefineLimitsProps = {
    toggleModal: Dispatch<SetStateAction<boolean>>
    id: number,
    isUH2?: boolean,
    token: string,
    ternos: Types.Terno[]
}


type ParametrizationPTProps = {
    toggleModal: Dispatch<SetStateAction<boolean>>,
    token: string,
    terno: number,
    nome_terno: string
}

export const NotificationModal: React.FC <NotificationProps> = ({
    toggleModal, notificacoes 
}) => {
    const [ternoModal, toggleTernoModal] = useState(false)

    const openTerno = () => {
        toggleTernoModal(true)
    }

    return <><ListModal title="Notificacões" toggleModal={toggleModal}>
        <Body.Scroll>
            {notificacoes.map(item => 
               !item.resolvido ? <Body.ListCard key={item.id.toString()}
                onClick={()=>openTerno()}>
                    <Body.ListTitle>{item.titulo}</Body.ListTitle>
                    <Body.ListText>{item.mensagem}</Body.ListText>
                    <Body.Row style={{gridGap: '0px 5px'}}>
                        <Body.ListText>Responsável: </Body.ListText>
                        <Body.ListText>{item.responsavel}</Body.ListText>
                    </Body.Row>
               </Body.ListCard>:null )}
               {notificacoes.map(item => 
               item.resolvido ? <Body.ListCard key={item.id.toString()} style={{opacity: 0.5, cursor: 'default'}}>
                    <Body.ListTitle>{item.titulo}</Body.ListTitle>
                    <Body.ListText>{item.mensagem}</Body.ListText>
                    <Body.Row style={{gridGap: '0px 15px', flexWrap: 'wrap'}}>
                        <Body.Row style={{gridGap: '0px 5px'}}>
                            <Body.ListText>Data: </Body.ListText>
                            <Body.ListText>{item.data}</Body.ListText>
                        </Body.Row>
                        <Body.Row style={{gridGap: '0px 5px'}}>
                            <Body.ListText>Hora: </Body.ListText>
                            <Body.ListText>{item.hora}</Body.ListText>
                        </Body.Row>
                        <Body.Row style={{gridGap: '0px 5px'}}>
                            <Body.ListText>Responsável: </Body.ListText>
                            <Body.ListText>{item.responsavel}</Body.ListText>
                        </Body.Row>
                    </Body.Row>
               </Body.ListCard>:null )}
        </Body.Scroll>
    </ListModal>
    {/* {ternoModal?<VisualizarTerno id={0} terno={} toggleModal={toggleTernoModal} />:null} */}
    </>
}

export const RegisterIntervention: React.FC <RegisterInterventionProps> = ({
    toggleModal,token, qtdTernos
}) => {
    const [area, setArea] = useState("")
    const [tipo, setTipo] = useState("")
    const [dia, setDia] = useState("")
    const [mes, setMes] = useState("")
    const [ano, setAno] = useState("")
    const [hora, setHora] = useState("")
    const [valvula, setValvula] = useState("")
    const [manual, setManual] = useState("")
    const [lado, setLado] = useState("")
    const [terno, setTerno] = useState("")
    const [observacoes, setObservacoes] = useState("")
    const [ternos, setTernos] = useState([''])
    const [dateSave, setDate] = useState<Date>()

    useEffect(()=>{
        setTernos([])
        Array.from({length: qtdTernos}, (item, index)=>{
            setTernos(old => [...old, (index+1).toString()])
        })
    },[])

    const areas = ["Bomba", "Óleo", "Transmissores", "Válvulas"]
    const [modalAreas, toggleAreas] = useState(false)

    const tipos = ["Inspeção", "Reparo", "Troca"]
    const [modalTipo, toggleTipo] = useState(false)

    const manuals = ["Carga", "Descarga", "Carga e descarga"]
    const [modalManual, toggleManual] = useState(false)

    const [modalLados, toggleLados] = useState(false)
    const [modalTernos, toggleTernos] = useState(false)

    const valvulas = ["Direcional", "Retenção", "Segurança", "Manual"]
    const [modalValvulas, toggleValvulas] = useState(false)

    const [loading, toggleLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const btnStyle = {width: '100%', marginBottom: '5px', justifyContent: 'center'}
    const btnActiveStyle = {width: '100%', marginBottom: '5px', justifyContent: 'center',
                            background: '#fff', color: '#000'}

    const send = () => {
        
        let newDate = Regex.convertDateForUTC(ano.concat("-",mes, "-", dia, "T", hora, ":00.000Z"))
        const data_intervencao = Regex.getUTC(newDate.ano.concat("-",newDate.mes, "-", newDate.dia, "T", newDate.hora, ":00.000Z"))
        toggleLoading(true)

        let observacao

        if(area === "Válvulas"){
            let msg = ''
            if(valvula === "Manual"){
                msg = msg.concat(valvula," - ",manual, ":",lado,":",terno)
            }
            else{
                msg = msg.concat("", valvula,":",lado,":",terno)
            }
            msg = msg.concat(":", observacoes)
            observacao = msg
        }else{
            observacao = observacoes
        }

        axios.post('https://www.dhm.dinamoautomacao.com.br/api/intervencoes?token='+token,  
            {area, tipo, data_intervencao, observacao}, {withCredentials: true})
            .then((response) => {
                dispatch({type: 'ADD_NOTIFICATION', status: 1, title: "Intervenção criada com sucesso"})
                toggleModal(false)
                toggleLoading(false)
            })
            .catch((error)=>{ 
                functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
    }

    const now = () => {
        const date = new Date()
        setDate(date)
        const dateObj = Regex.getDateFromUTC(date.toISOString())

        setAno(dateObj.ano.toString())
        setMes(dateObj.mes.toString())
        setDia(dateObj.dia.toString())
        setHora(dateObj.hora.toString())
    }


    return <>
         <Modal toggleModal={toggleModal} title="Cadastrar intervenção">
        <ModalBody.RegisterInterventionContent>
            <Body.Row style={{justifyContent: 'space-between',width: '100%'}}>
                <Body.HalfContent percentage={24}>
                    <Body.ListText>Área da intervenção</Body.ListText>
                    <Button onClick={()=>toggleAreas(true)} style={{width: '100%'}}>
                        {area === '' ?"Selecionar área...":area}
                    </Button>
                    <Body.ListText style={{marginTop: '5px'}}>Tipo de válvula</Body.ListText>
                    <Button onClick={()=>toggleValvulas(true)} style={{width: '100%'}}
                        disabled={!(area === 'Válvulas')}>
                        {valvula === '' ?"Selecionar válvula...":valvula}
                    </Button>
                    <Body.ListText style={{marginTop: '5px'}}>Terno</Body.ListText>
                    <Button onClick={()=>toggleTernos(true)} style={{width: '100%'}}
                        disabled={!(area === 'Válvulas')}>
                        {terno === '' ?"Selecionar terno...":terno}
                    </Button>
                </Body.HalfContent>
                <Body.HalfContent percentage={24}>
                    <Body.ListText >Tipo da intervenção</Body.ListText>
                    <Button onClick={()=>toggleTipo(true)} style={{width: '100%'}}>
                        {tipo === ''?"Selecionar tipo...":tipo}
                    </Button>
                    <Body.ListText style={{marginTop: '5px'}}>Lado</Body.ListText>
                    <Button onClick={()=>toggleLados(true)} style={{width: '100%'}}
                        disabled={!(area === 'Válvulas')}>
                        {lado === '' ?"Selecionar lado...":lado}
                    </Button>
                    <Body.ListText style={{marginTop: '5px'}}>Tipo de manual</Body.ListText>
                    <Button onClick={()=>toggleManual(true)} style={{width: '100%'}}
                        disabled={!(valvula === 'Manual')}>
                        {manual === '' ?"Selecionar manual...":manual}
                    </Button>
                </Body.HalfContent>
                
                <Body.HalfContent percentage={50}>
                    <Body.ListText>Observação (opcional)</Body.ListText>
                    <ModalBody.TextArea onChange={e => setObservacoes(e.target.value)}
                        value={observacoes} />
                </Body.HalfContent>
            </Body.Row>
            <Body.Row style={{width: '100%', alignItems: 'center', marginTop: '10px'}}>
            <Body.HalfContent percentage={30}>
                <Body.ListText>Data</Body.ListText>
                <Body.Row>
                <Input placeholder="DD" maxLength={2} value={dia}
                    onChange={e => setDia(e.target.value)}/>
                <Input placeholder="MM" maxLength={2} value={mes}
                    onChange={e => setMes(e.target.value)}/>
                <Input placeholder="AAAA" maxLength={4} value={ano}
                    onChange={e => setAno(e.target.value)}/>
                </Body.Row>
            </Body.HalfContent>
            <Body.HalfContent percentage={15}>
                <Body.ListText>Hora</Body.ListText>
                <Body.Row>
                <Input placeholder="XX:XX" maxLength={5} value={hora} 
                    onChange={e => setHora(Regex.hourRegex(e.target.value, hora))}/>
                </Body.Row>
            </Body.HalfContent>
            <Body.HalfContent percentage={40}>
                <Button onClick={()=>now()} style={{marginTop: '18px', marginLeft: '30px'}}>
                    Agora
                </Button>
            </Body.HalfContent>
            </Body.Row>
            <Body.Row style={{justifyContent: 'center', width: '100%', marginTop: '20px'}}>
                <Button onClick={()=>send()}>
                    Cadastrar intervenção
                </Button>
            </Body.Row>
            {loading? <Loading />:null}
            
        </ModalBody.RegisterInterventionContent>
        </Modal>   
        {modalAreas?<SelectItem title="Selecionar área" items={areas} 
            selectItem={setArea} toggleModal={()=>toggleAreas(false)} />:null}
        {modalTipo?<SelectItem title="Selecionar tipo" items={tipos} 
            selectItem={setTipo} toggleModal={()=>toggleTipo(false)} />:null}
        {modalLados?<SelectItem title="Selecionar lado" items={["Direito", "Esquerdo"]} 
            selectItem={setLado} toggleModal={()=>toggleLados(false)} />:null}
        {modalValvulas?<SelectItem title="Selecionar válvula" items={valvulas} 
            selectItem={setValvula} toggleModal={()=>toggleValvulas(false)} />:null}
        {modalManual?<SelectItem title="Selecionar manual" items={manuals} 
            selectItem={setManual} toggleModal={()=>toggleManual(false)} />:null}
        {modalTernos?<SelectItem title="Selecionar terno" items={ternos} 
            selectItem={setTerno} toggleModal={()=>toggleTernos(false)} />:null}
        </> 
}

export const EditIntervention: React.FC <EditInterventionProps> = ({
    toggleModal,token, qtdTernos, data, getData
}) => {
    const [area, setArea] = useState("")
    const [tipo, setTipo] = useState("")
    const [dia, setDia] = useState("")
    const [mes, setMes] = useState("")
    const [ano, setAno] = useState("")
    const [hora, setHora] = useState("")
    const [valvula, setValvula] = useState("")
    const [manual, setManual] = useState("")
    const [lado, setLado] = useState("")
    const [terno, setTerno] = useState("")
    const [observacoes, setObservacoes] = useState("")
    const [ternos, setTernos] = useState([''])

    useEffect(()=>{
        setArea(data.area)
        setTipo(data.tipo)
        const adjustDate = Regex.convertUTCToBrasilia(data.data_intervencao)
        setDia(adjustDate.dia.toString())
        setMes(adjustDate.mes.toString())
        setAno(adjustDate.ano.toString())
        setHora(adjustDate.hora.toString())
        if(data.observacao){
            const adjustedObs = functions.AdjustObs(data.observacao)
            setTerno(adjustedObs.terno)
            setObservacoes(adjustedObs.observacao)
            setValvula(adjustedObs.valvula)
            setLado(adjustedObs.lado)
            if(adjustedObs.valvula === 'Manual') setManual(adjustedObs.manual!)
        }
    },[])

    const areas = ["Bomba", "Óleo", "Transmissores", "Válvulas"]
    const [modalAreas, toggleAreas] = useState(false)

    const tipos = ["Inspeção", "Reparo", "Troca"]
    const [modalTipo, toggleTipo] = useState(false)

    const manuals = ["Carga", "Descarga", "Carga e descarga"]
    const [modalManual, toggleManual] = useState(false)

    const [modalLados, toggleLados] = useState(false)
    const [modalTernos, toggleTernos] = useState(false)

    const valvulas = ["Direcional", "Retenção", "Segurança", "Manual"]
    const [modalValvulas, toggleValvulas] = useState(false)

    const [loading, toggleLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const btnStyle = {width: '100%', marginBottom: '5px', justifyContent: 'center'}
    const btnActiveStyle = {width: '100%', marginBottom: '5px', justifyContent: 'center',
                            background: '#fff', color: '#000'}

    const send = () => {
        if(observacoes.length <= 0){
            dispatch({type: 'ADD_NOTIFICATION', status: 3, title: "Adicionar observação"})
            return
        }

        toggleLoading(true)

        let observacao

        if(area === "Válvulas"){
            let msg = ''
            if(valvula === "Manual"){
                msg = msg.concat(valvula," - ",manual, ":",lado,":",terno)
            }
            else{
                msg = msg.concat("", valvula,":",lado,":",terno)
            }
            msg = msg.concat(":", observacoes)
            observacao = msg
        }else{
            observacao = observacoes
        }

        axios.put('https://www.dhm.dinamoautomacao.com.br/api/intervencoes?token='+token,  
            {id_intervencao: data.id, observacao, token}, {withCredentials: true})
            .then((response) => {
                dispatch({type: 'ADD_NOTIFICATION', status: 1, title: "Intervenção alterada com sucesso"})
                getData()
                toggleModal(false)
                toggleLoading(false)
            })
            .catch((error)=>{ 
                toggleLoading(false)
                functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            })
    }


    return <>
         <Modal toggleModal={toggleModal} title="Resolver intervenção">
        <ModalBody.RegisterInterventionContent>
            <Body.Row style={{justifyContent: 'space-between',width: '100%'}}>
                <Body.HalfContent percentage={24}>
                    <Body.ListText>Área da intervenção</Body.ListText>
                    <Button onClick={()=>toggleAreas(true)} style={{width: '100%'}} disabled={true}>
                        {area === '' ?"Selecionar área...":area}
                    </Button>
                    <Body.ListText style={{marginTop: '5px'}}>Tipo de válvula</Body.ListText>
                    <Button onClick={()=>toggleValvulas(true)} style={{width: '100%'}}
                       disabled={true}>
                        {valvula === '' ?"Selecionar válvula...":valvula}
                    </Button>
                    <Body.ListText style={{marginTop: '5px'}}>Terno</Body.ListText>
                    <Button onClick={()=>toggleTernos(true)} style={{width: '100%'}}
                        disabled={true}>
                        {terno === '' ?"Selecionar terno...":terno}
                    </Button>
                </Body.HalfContent>
                <Body.HalfContent percentage={24}>
                    <Body.ListText >Tipo da intervenção</Body.ListText>
                    <Button onClick={()=>toggleTipo(true)} style={{width: '100%'}} disabled={true}>
                        {tipo === ''?"Selecionar tipo...":tipo}
                    </Button>
                    <Body.ListText style={{marginTop: '5px'}}>Lado</Body.ListText>
                    <Button onClick={()=>toggleLados(true)} style={{width: '100%'}}
                        disabled={true}>
                        {lado === '' ?"Selecionar lado...":lado}
                    </Button>
                    <Body.ListText style={{marginTop: '5px'}}>Tipo de manual</Body.ListText>
                    <Button onClick={()=>toggleManual(true)} style={{width: '100%'}}
                        disabled={true}>
                        {manual === '' ?"Selecionar manual...":manual}
                    </Button>
                </Body.HalfContent>
                
                <Body.HalfContent percentage={50}>
                    <Body.ListText>Observação</Body.ListText>
                    <ModalBody.TextArea onChange={e => setObservacoes(e.target.value)}
                        value={observacoes} />
                </Body.HalfContent>
            </Body.Row>
            <Body.Row style={{width: '100%', alignItems: 'center', marginTop: '10px'}}>
            <Body.HalfContent percentage={30}>
                <Body.ListText>Data</Body.ListText>
                <Body.Row>
                    <Body.ListTitle>{dia}/{mes}/{ano} - {hora}</Body.ListTitle>
                </Body.Row>
            </Body.HalfContent>
            </Body.Row>
            <Body.Row style={{justifyContent: 'center', width: '100%', marginTop: '20px'}}>
                <Button onClick={()=>send()}>
                    Resolver e salvar
                </Button>
            </Body.Row>
            {loading? <Loading />:null}
            
        </ModalBody.RegisterInterventionContent>
        </Modal>   
        {modalAreas?<SelectItem title="Selecionar área" items={areas} 
            selectItem={setArea} toggleModal={()=>toggleAreas(false)} />:null}
        {modalTipo?<SelectItem title="Selecionar tipo" items={tipos} 
            selectItem={setTipo} toggleModal={()=>toggleTipo(false)} />:null}
        {modalLados?<SelectItem title="Selecionar lado" items={["Direito", "Esquerdo"]} 
            selectItem={setLado} toggleModal={()=>toggleLados(false)} />:null}
        {modalValvulas?<SelectItem title="Selecionar válvula" items={valvulas} 
            selectItem={setValvula} toggleModal={()=>toggleValvulas(false)} />:null}
        {modalManual?<SelectItem title="Selecionar manual" items={manuals} 
            selectItem={setManual} toggleModal={()=>toggleManual(false)} />:null}
        {modalTernos?<SelectItem title="Selecionar terno" items={ternos} 
            selectItem={setTerno} toggleModal={()=>toggleTernos(false)} />:null}
        </> 
}

export const InterventionsModal: React.FC <InterventionProps> = ({
    toggleModal, token, qtdTernos
}) => {
    const [data, setData] = useState<Types.Intervencao[]>([])
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, toggleLoading] = useState(false)

    const [intervencao, setIntervenção] = useState<Types.Intervencao>()
    const [modalEdit, toggleEdit] = useState(false)

    useEffect(()=>{
        toggleLoading(true)
        axios.get("https://www.dhm.dinamoautomacao.com.br/api/intervencoes?token="+token, {withCredentials: true})
            .then((response)=> {
                setData(response.data)
                toggleLoading(false)
            })
            .catch((error)=>{
                toggleLoading(false)
                functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            })
    },[])

    const getData = () => {
        toggleLoading(true)
        axios.get("https://www.dhm.dinamoautomacao.com.br/api/intervencoes?token="+token, {withCredentials: true})
            .then((response)=> {
                setData(response.data)
                toggleLoading(false)
            })
            .catch((error)=>{
                toggleLoading(false)
                functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            })
    }

    const selectIntervention = (item: Types.Intervencao) => {
        setIntervenção(item)
        toggleEdit(true)
    }

    const getDate = (date: string) => {
        const {dia, mes, ano, hora} = Regex.convertUTCToBrasilia(date)
        return <Body.ListText>
            {dia}/{mes}/{ano} - {hora}
        </Body.ListText>
    }

    const getObservacao = (area: string, obs?: string) => {
        if(!obs){
            return
        }
        if(area === "Válvulas"){
                return <>
                    <Body.ListText>Tipo de válvula: {obs?.split(":")[0]}</Body.ListText>
                        <Body.ListText>Lado: {obs?.split(":")[1]}</Body.ListText>
                        <Body.ListText>Terno: {obs?.split(":")[2]}</Body.ListText>
                        {obs?.split(':')[3]?<Body.ListText>Observações adicionais: 
                            {obs?.split(":")[3]}</Body.ListText>:null}
                </>
        }else{
            if(obs.length > 0) {
                return <Body.ListText>Observações adicionais: {obs}</Body.ListText>
            }
        }
    }

    return <><ListModal title="Lista de Intervenções" toggleModal={toggleModal} loading={false}>
        <Body.Scroll>
            {data?.map((item, index) => !item.resolvido?
                <Body.ListCard key={index.toString()} onClick={()=>selectIntervention(item)}>
                    <Body.Row style={{gridGap: '0px 5px'}}>
                        <Body.ListTitle>{item.area} - {item.tipo}</Body.ListTitle>
                        <ModalBody.CircleTitle color={"#E5D34A"} style={{background: "#E5D34A"}} >
                            <Body.ListText style={{marginBottom: 0, color: "#000", fontWeight: 600}}>Precisa de atenção</Body.ListText>
                        </ModalBody.CircleTitle>
                    </Body.Row>
                    <Body.Row style={{gridGap: '0px 5px'}}>
                        <Body.ListText>Criador: </Body.ListText>
                        <Body.ListText>{item.nome}</Body.ListText>
                    </Body.Row>
                    <Body.Row style={{gridGap: '0px 15px'}}>
                        <Body.Row style={{gridGap: '0px 5px'}}>
                            <Body.ListText>Data: </Body.ListText>
                            {getDate(item.data_intervencao)}
                        </Body.Row>
                    </Body.Row>
                    {getObservacao(item.area, item.observacao)}
                    
               </Body.ListCard>:null)}
               {data?.map((item, index) => item.resolvido?
                <Body.ListCard key={index.toString()}>
                    <Body.ListTitle>{item.area} - {item.tipo}</Body.ListTitle>
                    <Body.Row style={{gridGap: '0px 5px'}}>
                        <Body.ListText>Criador: </Body.ListText>
                        <Body.ListText>{item.nome}</Body.ListText>
                    </Body.Row>
                    <Body.Row style={{gridGap: '0px 15px'}}>
                        <Body.Row style={{gridGap: '0px 5px'}}>
                            <Body.ListText>Data: </Body.ListText>
                            {getDate(item.data_intervencao)}
                        </Body.Row>
                    </Body.Row>
                    {getObservacao(item.area, item.observacao)}
                    
               </Body.ListCard>:null)}
        </Body.Scroll>
    </ListModal>
    {modalEdit?<EditIntervention data={intervencao!} toggleModal={toggleEdit} token={token} qtdTernos={qtdTernos} getData={getData}/>:null}
    </>
}

export const ParametrizationTernoModal: React.FC <ParametrizationPTProps> = ({
    toggleModal, token, terno, nome_terno
}) => {
    const [data, setData] = useState<Types.ParametrizationTerno>(parametros_por_terno[0])
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, toggleLoading] = useState(false)
    const [sending, toggleSending] = useState(false)

    const [setpoint, setSetpoint] = useState('')
    const [pressao_minima_le, setPMinLe] = useState('')
    const [pressao_minima_ld, setPMinLd] = useState('')
    const [pressao_maxima_le, setPMaxLe] = useState('0')
    const [pressao_maxima_ld, setPMaxLd] = useState('0')
    const [maxima_tolerancia_superior_pressao, setMaxTolSup] = useState('0')
    const [maxima_tolerancia_inferior_pressao, setMaxTolInf] = useState('0')
    const [tolerancia_superior_pressao, setMinTolSup] = useState('0')
    const [tolerancia_inferior_pressao, setMinTolInf] = useState('0')
    
    const [altura_maxima, setAltMax] = useState('0')
    const [altura_terno_critico, setAltTerno] = useState('0')
    const [diferenca_altura_correcao, setDifCorrecao] = useState('0')
    const [fracao_diferenca_altura_correcao, setFracao] = useState('0')

    const [pressao_nitrogenio_le, setNitroLe] = useState('0')
    const [pressao_nitrogenio_ld, setNitroLd] = useState('0')
    const [pressao_maxima_mancal_le, setPMaxMancalLe] = useState('0')
    const [pressao_maxima_mancal_ld, setPMaxMancalLd] = useState('0')
    const [pressao_trabalho_le, setPTrabLe] = useState('0')
    const [pressao_trabalho_ld, setPTrabLd] = useState('0')
    const [pressao_maxima_trabalho_acumulador_le, setPTrabAcLe] = useState('0')
    const [pressao_maxima_trabalho_acumulador_ld, setPTrabAcLd] = useState('0')

    useEffect(()=>{
        let value = (Number(pressao_trabalho_le) + Number(pressao_trabalho_ld))/2
        console.log(Number(pressao_trabalho_le), Number(pressao_trabalho_ld),  (Number(pressao_trabalho_le) + Number(pressao_trabalho_ld))/2)
        setSetpoint(value.toFixed(0).toString())
    },[pressao_trabalho_ld, pressao_trabalho_le])

    useEffect(()=>{
        let value = Math.min((Number(pressao_nitrogenio_le)/0.6), Number(pressao_maxima_trabalho_acumulador_le)*0.9, Number(pressao_maxima_mancal_le))
        setPMaxLe(value.toFixed(0).toString())
        let valueMin = Number(pressao_nitrogenio_le)/0.92
        setPMinLe(valueMin.toFixed(0).toString())
    },[pressao_nitrogenio_le, pressao_maxima_trabalho_acumulador_le,pressao_maxima_mancal_le ])

    useEffect(()=>{
        let value = Math.min((Number(pressao_nitrogenio_ld)/0.6), Number(pressao_maxima_trabalho_acumulador_ld)*0.9, Number(pressao_maxima_mancal_ld))
        setPMaxLd(value.toFixed(0).toString())
        let valueMin = Number(pressao_nitrogenio_ld)/0.92
        setPMinLd(valueMin.toFixed(0).toString())
    },[pressao_nitrogenio_ld, pressao_maxima_trabalho_acumulador_ld,pressao_maxima_mancal_ld ])

    const send = () => {
        toggleLoading(true)
        toggleSending(true)
        axios.post("https://www.dhm.dinamoautomacao.com.br/api/parametrizacao?terno="+terno+"&token="+functions.ReplaceSpecialCharacters(token!), 
            {setpoint: Number(setpoint), pressao_minima_le: Number(pressao_maxima_le), pressao_minima_ld: Number(pressao_maxima_ld), 
             pressao_maxima_le: Number(pressao_maxima_le), pressao_maxima_ld: Number(pressao_maxima_ld), tolerancia_inferior_pressao: Number(tolerancia_inferior_pressao),
             maxima_tolerancia_superior_pressao: Number(maxima_tolerancia_superior_pressao), maxima_tolerancia_inferior_pressao: Number(maxima_tolerancia_inferior_pressao),
             tolerancia_superior_pressao: Number(tolerancia_superior_pressao), altura_maxima: Number(altura_maxima), altura_terno_critico: Number(altura_terno_critico),
             diferenca_altura_correcao: Number(diferenca_altura_correcao), fracao_diferenca_altura_correcao: Number(fracao_diferenca_altura_correcao), 
             pressao_nitrogenio_le: Number(pressao_nitrogenio_le), pressao_nitrogenio_ld: Number(pressao_nitrogenio_ld), pressao_maxima_mancal_ld: Number(pressao_maxima_mancal_ld), 
             pressao_maxima_mancal_le: Number(pressao_maxima_mancal_le), pressao_trabalho_ld: Number(pressao_trabalho_ld), pressao_trabalho_le: Number(pressao_trabalho_le), 
             pressao_maxima_trabalho_acumulador_ld: Number(pressao_maxima_trabalho_acumulador_ld), pressao_maxima_trabalho_acumulador_le: Number(pressao_maxima_trabalho_acumulador_le)}, 
            {withCredentials: true})
            .then(() => {
                dispatch({type: 'ADD_NOTIFICATION', title: '', text: 'Parâmetros  enviados com sucesso!', status: 1})
                toggleLoading(false)
                toggleSending(false)
            })
            .catch((error)=>{
                toggleLoading(false)
                toggleSending(false)
                functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            })
    }


    useEffect(()=>{
        toggleLoading(true)
        axios.get("https://www.dhm.dinamoautomacao.com.br/api/parametrizacao?token="+functions.ReplaceSpecialCharacters(token)+"&terno="+terno, {withCredentials: true})
            .then((response)=>{
                setData(response.data)
                setMaxTolSup(response.data.nuvem.maxima_tolerancia_superior_pressao.toFixed(2).toString())
                setMaxTolInf(response.data.nuvem.maxima_tolerancia_inferior_pressao.toFixed(2).toString())
                setMinTolSup(response.data.nuvem.tolerancia_superior_pressao.toFixed(2).toString())
                setMinTolInf(response.data.nuvem.tolerancia_inferior_pressao.toFixed(2).toString())

                setAltMax(response.data.nuvem.altura_maxima.toString())
                setAltTerno(response.data.nuvem.altura_terno_critico.toString())
                setDifCorrecao(response.data.nuvem.diferenca_altura_correcao.toString())
                setFracao(response.data.nuvem.fracao_diferenca_altura_correcao.toString())

                setNitroLe(response.data.nuvem.pressao_nitrogenio_le.toString())
                setNitroLd(response.data.nuvem.pressao_nitrogenio_ld.toString())
                setPMaxMancalLe(response.data.nuvem.pressao_maxima_mancal_le.toString())
                setPMaxMancalLd(response.data.nuvem.pressao_maxima_mancal_ld.toString())
                setPTrabLe(response.data.nuvem.pressao_trabalho_le.toString())
                setPTrabLd(response.data.nuvem.pressao_trabalho_ld.toString())
                setPTrabAcLe(response.data.nuvem.pressao_maxima_trabalho_acumulador_le.toString())
                setPTrabAcLd(response.data.nuvem.pressao_maxima_trabalho_acumulador_ld.toString())
                toggleLoading(false)
            })
            .catch((error)=>{
                functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })

    },[])

    return <Modal title="Parametrização" subtitle={nome_terno} toggleModal={toggleModal} loading={loading} 
        width={900} sendParams={()=>send()} sending={sending}>
        <>

        <ModalBody.VisualizeTernoContent style={{width: '900px'}}>
        <Body.Scroll style={{maxHeight: 'calc(90vh - 200px)'}}>
        <Body.Row style={{width: '100%', justifyContent: 'space-between'}}>
        <Body.HalfContent percentage={49.5}>
            <ModalBody.Card>
                <Body.Title style={{fontSize: '20px', marginBottom: '0px'}}>Nuvem</Body.Title>
            </ModalBody.Card>
            <ModalBody.Card style={{paddingLeft: '10px', paddingRight: '10px', padding: '5px'}}>
            <Body.Row style={{width: '100%', justifyContent: 'space-between'}}>
                <Body.HalfContent percentage={23}>
                    <Body.ListText>
                        Pressão (psi)
                    </Body.ListText>
                    <ModalBody.Card style={{marginBottom: 0}}>
                        <Body.ListText>
                            SetPoint
                        </Body.ListText>
                        <Body.ListTitle>
                            {setpoint}
                        </Body.ListTitle>
                    </ModalBody.Card>
                </Body.HalfContent>
                <Body.HalfContent percentage={38}>
                    <ModalBody.Card style={{marginBottom: 0,
                        paddingLeft: '1.5px', paddingRight: '1.5px', padding: '0'}}>
                        <Body.ListText >
                            Esquerda
                        </Body.ListText>
                        <Body.Row style={{width: '100%', justifyContent: 'space-between'}}>
                            <Body.HalfContent percentage={49.5}>
                                <ModalBody.Card >
                                    <Body.ListText>min</Body.ListText>
                                    <Body.ListTitle>
                                        {Number(pressao_minima_le).toFixed(0)}
                                    </Body.ListTitle>
                                </ModalBody.Card>
                            </Body.HalfContent>
                            <Body.HalfContent percentage={49.5}>
                                <ModalBody.Card>
                                    <Body.ListText>max</Body.ListText>
                                    <Body.ListTitle>
                                        {Number(pressao_maxima_le).toFixed(0)}
                                    </Body.ListTitle>
                                </ModalBody.Card>
                            </Body.HalfContent>
                        </Body.Row>
                    </ModalBody.Card>
                </Body.HalfContent>
                <Body.HalfContent percentage={38}>
                    <ModalBody.Card style={{marginBottom: 0,
                        paddingLeft: '1.5px', paddingRight: '1.5px', padding: '0'}}>
                        <Body.ListText >
                            Direita
                        </Body.ListText>
                        <Body.Row style={{width: '100%', justifyContent: 'space-between'}}>
                            <Body.HalfContent percentage={49.5}>
                                <ModalBody.Card>
                                    <Body.ListText>min</Body.ListText>
                                    <Body.ListTitle>
                                        {Number(pressao_minima_ld).toFixed(0)}
                                    </Body.ListTitle>
                                </ModalBody.Card>
                            </Body.HalfContent>
                            <Body.HalfContent percentage={49.5}>
                                <ModalBody.Card>
                                    <Body.ListText>max</Body.ListText>
                                    <Body.ListTitle>
                                        {Number(pressao_maxima_ld).toFixed(0)}
                                    </Body.ListTitle>
                                </ModalBody.Card>
                            </Body.HalfContent>
                        </Body.Row>
                    </ModalBody.Card>
                </Body.HalfContent>
            </Body.Row>
            </ModalBody.Card>
            <ModalBody.Card>
                <Body.ListText>Tolerancia de pressão (%)</Body.ListText>
                <Body.Row style={{width: '100%', justifyContent: 'space-between'}}>
                    <Body.HalfContent percentage={24.8}>
                        <ModalBody.Card style={{marginBottom: 0, textAlign: 'center', height: '90px',
                            paddingLeft: '1.5px', paddingRight: '1.5px', padding: '0'}}>
                            <Body.ListText >
                                Mínima inferior
                            </Body.ListText>
                            <ModalBody.Input value={maxima_tolerancia_inferior_pressao} type="number"
                                        onChange={e => setMaxTolInf(e.target.value)}/>
                        </ModalBody.Card>
                    </Body.HalfContent>
                    <Body.HalfContent percentage={24.8}>
                        <ModalBody.Card style={{marginBottom: 0, textAlign: 'center', height: '90px',justifyContent: 'center',
                            paddingLeft: '1.5px', paddingRight: '1.5px', padding: '0'}}>
                            <Body.ListText >
                            Inferior
                            </Body.ListText>
                            <ModalBody.Input value={tolerancia_inferior_pressao} type="number"
                                onChange={e => setMinTolInf(e.target.value)}/>
                        </ModalBody.Card>
                    </Body.HalfContent>
                    <Body.HalfContent percentage={24.8}>
                        <ModalBody.Card style={{marginBottom: 0, textAlign: 'center', height: '90px', justifyContent: 'center',
                            paddingLeft: '1.5px', paddingRight: '1.5px', padding: '0'}}>
                            <Body.ListText >
                                Superior
                            </Body.ListText>
                            <ModalBody.Input value={tolerancia_superior_pressao} type="number"
                                        onChange={e => setMinTolSup(e.target.value)}/>
                        </ModalBody.Card>
                    </Body.HalfContent>
                    <Body.HalfContent percentage={24.8}>
                        <ModalBody.Card style={{marginBottom: 0, textAlign: 'center', height: '90px',
                            paddingLeft: '1.5px', paddingRight: '1.5px', padding: '0'}}>
                            <Body.ListText >
                                Máxima superior
                            </Body.ListText>
                            <ModalBody.Input value={maxima_tolerancia_superior_pressao} type="number"
                                        onChange={e => setMaxTolSup(e.target.value)}/>
                        </ModalBody.Card>
                    </Body.HalfContent>
                </Body.Row>
            </ModalBody.Card>
            <ModalBody.Card>
                <Body.ListText>Altura (mm)</Body.ListText>
                <Body.Row style={{width: '100%', justifyContent: 'space-between'}}>
                    <Body.HalfContent percentage={49.5}>
                        <ModalBody.Card>
                            <Body.ListText>Máxima</Body.ListText>
                            <ModalBody.Input value={altura_maxima} type="number"
                                onChange={e => setAltMax(e.target.value)}/>
                        </ModalBody.Card>
                    </Body.HalfContent>
                    <Body.HalfContent percentage={49.5}>
                        <ModalBody.Card>
                            <Body.ListText>Terno crítico</Body.ListText>
                            <ModalBody.Input value={altura_terno_critico} type="number"
                                onChange={e => setAltTerno(e.target.value)}/>
                        </ModalBody.Card>
                    </Body.HalfContent>
                </Body.Row>
                <Body.Row style={{width: '100%', justifyContent: 'space-between'}}>
                    <Body.HalfContent percentage={49.5}>
                        <ModalBody.Card>
                            <Body.ListText>Dif. correção</Body.ListText>
                            <ModalBody.Input value={diferenca_altura_correcao} type="number"
                                onChange={e => setDifCorrecao(e.target.value)}/>
                        </ModalBody.Card>
                    </Body.HalfContent>
                    <Body.HalfContent percentage={49.5}>
                        <ModalBody.Card>
                            <Body.ListText>Fração dif. correção</Body.ListText>
                            <ModalBody.Input value={fracao_diferenca_altura_correcao} type="number"
                                onChange={e => setFracao(e.target.value)}/>
                        </ModalBody.Card>
                    </Body.HalfContent>
                </Body.Row>
            </ModalBody.Card>   
            </Body.HalfContent>
            <Body.HalfContent percentage={49.5}>
                <ModalBody.Card>
                    <Body.Title style={{fontSize: '20px', marginBottom: '0px'}}>CLP</Body.Title>
                </ModalBody.Card>
                <ModalBody.Card style={{paddingLeft: '10px', paddingRight: '10px', padding: '5px'}}>
                <Body.Row style={{width: '100%', justifyContent: 'space-between'}}>
                <Body.HalfContent percentage={23}>
                    <Body.ListText>
                        Pressão (psi)
                    </Body.ListText>
                    <ModalBody.Card style={{marginBottom: 0}}>
                        <Body.ListText>
                            SetPoint
                        </Body.ListText>
                        <Body.ListTitle>
                            {data?.setpoint}
                        </Body.ListTitle>
                    </ModalBody.Card>
                </Body.HalfContent>
                <Body.HalfContent percentage={38}>
                    <ModalBody.Card style={{marginBottom: 0,
                        paddingLeft: '1.5px', paddingRight: '1.5px', padding: '0'}}>
                        <Body.ListText >
                            Esquerda
                        </Body.ListText>
                        <Body.Row style={{width: '100%', justifyContent: 'space-between'}}>
                            <Body.HalfContent percentage={49.5}>
                                <ModalBody.Card >
                                    <Body.ListText>min</Body.ListText>
                                    <Body.ListTitle>{data?.pressao_minima_le}</Body.ListTitle>
                                </ModalBody.Card>
                            </Body.HalfContent>
                            <Body.HalfContent percentage={49.5}>
                                <ModalBody.Card>
                                    <Body.ListText>max</Body.ListText>
                                    <Body.ListTitle>{data?.pressao_maxima_le}</Body.ListTitle>
                                </ModalBody.Card>
                            </Body.HalfContent>
                        </Body.Row>
                    </ModalBody.Card>
                </Body.HalfContent>
                <Body.HalfContent percentage={38}>
                    <ModalBody.Card style={{marginBottom: 0,
                        paddingLeft: '1.5px', paddingRight: '1.5px', padding: '0'}}>
                        <Body.ListText >
                            Direita
                        </Body.ListText>
                        <Body.Row style={{width: '100%', justifyContent: 'space-between'}}>
                            <Body.HalfContent percentage={49.5}>
                                <ModalBody.Card>
                                    <Body.ListText>min</Body.ListText>
                                    <Body.ListTitle>{data?.pressao_minima_ld}</Body.ListTitle>
                                </ModalBody.Card>
                            </Body.HalfContent>
                            <Body.HalfContent percentage={49.5}>
                                <ModalBody.Card>
                                    <Body.ListText>max</Body.ListText>
                                    <Body.ListTitle>{data?.pressao_maxima_ld}</Body.ListTitle>
                                </ModalBody.Card>
                            </Body.HalfContent>
                        </Body.Row>
                    </ModalBody.Card>
                </Body.HalfContent>
                </Body.Row>
                </ModalBody.Card>
                <ModalBody.Card>
                <Body.ListText>Tolerancia de pressão (%)</Body.ListText>
                <Body.Row style={{width: '100%', justifyContent: 'space-between'}}>
                    <Body.HalfContent percentage={24.8}>
                        <ModalBody.Card style={{marginBottom: 0, textAlign: 'center', height: '80px',
                            paddingLeft: '1.5px', paddingRight: '1.5px', padding: '0'}}>
                            <Body.ListText >
                                Mínima inferior
                            </Body.ListText>
                            <Body.ListTitle>{data?.maxima_tolerancia_inferior_pressao.toFixed(2)}</Body.ListTitle>
                        </ModalBody.Card>
                    </Body.HalfContent>
                    <Body.HalfContent percentage={24.8}>
                        <ModalBody.Card style={{marginBottom: 0, textAlign: 'center', height: '80px', justifyContent: 'center',
                            paddingLeft: '1.5px', paddingRight: '1.5px', padding: '0'}}>
                            <Body.ListText >
                                Inferior
                            </Body.ListText>
                            <Body.ListTitle>{data?.tolerancia_inferior_pressao.toFixed(2)}</Body.ListTitle>
                        </ModalBody.Card>
                    </Body.HalfContent>
                    <Body.HalfContent percentage={24.8}>
                        <ModalBody.Card style={{marginBottom: 0, textAlign: 'center', height: '80px', justifyContent: 'center',
                            paddingLeft: '1.5px', paddingRight: '1.5px', padding: '0'}}>
                            <Body.ListText >
                                Superior
                            </Body.ListText>
                            <Body.ListTitle>{data?.tolerancia_superior_pressao.toFixed(2)}</Body.ListTitle>
                        </ModalBody.Card>
                    </Body.HalfContent>
                    <Body.HalfContent percentage={24.8}>
                        <ModalBody.Card style={{marginBottom: 0, textAlign: 'center', height: '80px',
                            paddingLeft: '1.5px', paddingRight: '1.5px', padding: '0'}}>
                            <Body.ListText >
                                Máxima superior
                            </Body.ListText>
                            <Body.ListTitle>{data?.maxima_tolerancia_superior_pressao.toFixed(2)}</Body.ListTitle>
                        </ModalBody.Card>
                    </Body.HalfContent>
                </Body.Row>
                </ModalBody.Card>
                <ModalBody.Card>
                <Body.ListText>Altura (mm)</Body.ListText>
                    <Body.Row style={{width: '100%', justifyContent: 'space-between'}}>
                        <Body.HalfContent percentage={49.5}>
                            <ModalBody.Card>
                                <Body.ListText>Máxima</Body.ListText>
                                <Body.ListTitle>{data?.altura_maxima}</Body.ListTitle>
                            </ModalBody.Card>
                        </Body.HalfContent>
                        <Body.HalfContent percentage={49.5}>
                            <ModalBody.Card>
                                <Body.ListText>Terno crítico</Body.ListText>
                                <Body.ListTitle>{data?.altura_terno_critico}</Body.ListTitle>
                            </ModalBody.Card>
                        </Body.HalfContent>
                    </Body.Row>
                    <Body.Row style={{width: '100%', justifyContent: 'space-between'}}>
                        <Body.HalfContent percentage={49.5}>
                            <ModalBody.Card>
                                <Body.ListText>Dif. correção</Body.ListText>
                                <Body.ListTitle>{data?.diferenca_altura_correcao}</Body.ListTitle>
                            </ModalBody.Card>
                        </Body.HalfContent>
                        <Body.HalfContent percentage={49.5}>
                            <ModalBody.Card>
                                <Body.ListText>Fração dif. correção</Body.ListText>
                                <Body.ListTitle>{data?.fracao_diferenca_altura_correcao}</Body.ListTitle>
                            </ModalBody.Card>
                        </Body.HalfContent>
                    </Body.Row>
                </ModalBody.Card> 
                </Body.HalfContent>
            </Body.Row>
            <ModalBody.Card>
                <Body.Title style={{fontSize: '20px', marginBottom: '0px'}}>Calculadora de setpoint</Body.Title>
            </ModalBody.Card>
            <ModalBody.Card style={{paddingLeft: '10px', paddingRight: '10px', padding: '5px'}}>
            <Body.Row style={{width: '100%', justifyContent: 'space-between'}}>
                    <Body.HalfContent percentage={49.8}>
                        <Body.ListText >
                            Pressão de nitrogênio (psi)
                        </Body.ListText>
                        <Body.Row style={{width: '100%', justifyContent: 'space-between'}}>
                            <Body.HalfContent percentage={49.5}>
                                <ModalBody.Card >
                                    <Body.ListText>Esquerda</Body.ListText>
                                    <ModalBody.Input value={pressao_nitrogenio_le} type="number"
                                        onChange={e => setNitroLe(e.target.value)}/>
                                </ModalBody.Card>
                            </Body.HalfContent>
                            <Body.HalfContent percentage={49.5}>
                                <ModalBody.Card>
                                    <Body.ListText>Direita</Body.ListText>
                                    <ModalBody.Input value={pressao_nitrogenio_ld} type="number"
                                        onChange={e => setNitroLd(e.target.value)}/>
                                </ModalBody.Card>
                            </Body.HalfContent>
                         </Body.Row>
                         <Body.ListText style={{marginTop: '10px'}}>
                            Pressão máxima mancal (psi)
                        </Body.ListText>
                        <Body.Row style={{width: '100%', justifyContent: 'space-between'}}>
                            <Body.HalfContent percentage={49.5}>
                                <ModalBody.Card >
                                    <Body.ListText>Esquerda</Body.ListText>
                                    <ModalBody.Input value={pressao_maxima_mancal_le} type="number"
                                        onChange={e => setPMaxMancalLe(e.target.value)}/>
                                </ModalBody.Card>
                            </Body.HalfContent>
                            <Body.HalfContent percentage={49.5}>
                                <ModalBody.Card>
                                    <Body.ListText>Direita</Body.ListText>
                                    <ModalBody.Input value={pressao_maxima_mancal_ld} type="number"
                                        onChange={e => setPMaxMancalLd(e.target.value)}/>
                                </ModalBody.Card>
                            </Body.HalfContent>
                         </Body.Row>
                </Body.HalfContent>
                <Body.HalfContent percentage={49.8}>
                        <Body.ListText >
                            Pressão de trabalho (psi)
                        </Body.ListText>
                        <Body.Row style={{width: '100%', justifyContent: 'space-between'}}>
                            <Body.HalfContent percentage={49.5}>
                                <ModalBody.Card >
                                    <Body.ListText>Esquerda</Body.ListText>
                                    <ModalBody.Input value={pressao_trabalho_le} type="number"
                                        onChange={e => setPTrabLe(e.target.value)}/>
                                </ModalBody.Card>
                            </Body.HalfContent>
                            <Body.HalfContent percentage={49.5}>
                                <ModalBody.Card>
                                    <Body.ListText>Direita</Body.ListText>
                                    <ModalBody.Input value={pressao_trabalho_ld} type="number"
                                        onChange={e => setPTrabLd(e.target.value)}/>
                                </ModalBody.Card>
                            </Body.HalfContent>
                         </Body.Row>
                         <Body.ListText style={{marginTop: '10px'}}>
                            Pressão máx. trab. acumulador (psi)
                        </Body.ListText>
                        <Body.Row style={{width: '100%', justifyContent: 'space-between'}}>
                            <Body.HalfContent percentage={49.5}>
                                <ModalBody.Card >
                                    <Body.ListText>Esquerda</Body.ListText>
                                    <ModalBody.Input value={pressao_maxima_trabalho_acumulador_le} type="number"
                                        onChange={e => setPTrabAcLe(e.target.value)}/>
                                </ModalBody.Card>
                            </Body.HalfContent>
                            <Body.HalfContent percentage={49.5}>
                                <ModalBody.Card>
                                    <Body.ListText>Direita</Body.ListText>
                                    <ModalBody.Input value={pressao_maxima_trabalho_acumulador_ld} type="number"
                                        onChange={e => setPTrabAcLd(e.target.value)}/>
                                </ModalBody.Card>
                            </Body.HalfContent>
                         </Body.Row>
                </Body.HalfContent>
            </Body.Row>
            </ModalBody.Card>         
        </Body.Scroll>
        </ModalBody.VisualizeTernoContent> 
        </>
    </Modal>
}

export const Performance: React.FC <Props> = ({
    toggleModal, ternos
}) => {

    const [terno, setTerno] = useState<Types.Terno>()
    const [modalTerno, toggleModalTerno] = useState(false)

    const selectTerno = (item: Types.Terno) => {
        setTerno(item)
        toggleModalTerno(true)
    }
    return <><Modal toggleModal={toggleModal} title="Desempenho dos ternos" >
        <>
        <ModalBody.VisualizePerformanceContent >
                {ternos?.map((item , index) => {
                    return <ModalBody.PerformanceCard key={index.toString()}
                        onClick={()=>selectTerno(item)}>
                        <Body.Row style={{justifyContent: 'center'}}>
                            <Body.ListText style={{marginRight: 10}}> {item.nome_terno}</Body.ListText>
                            <Body.CircleTitle color={functions.getStatusTerno(item.status).color} >
                                <Body.IconTitle src={functions.getStatusTerno(item.status).icon} />
                            </Body.CircleTitle>
                        </Body.Row>
                        <Body.Row style={{justifyContent: 'center', marginBottom: '5px'}}>
                            <ModalBody.CircleTitle color='#FFF' title={item.manual?'Manual':'Automático'}
                                style={{marginLeft: '5px', marginRight: '5px'}}>
                                <ModalBody.TextoCircle>{item.manual?"Manual":"Automático"}</ModalBody.TextoCircle>
                            </ModalBody.CircleTitle>
                            <ModalBody.CircleTitle color={item.funcionando?'#92B258':'#F03F3F'} 
                                style={{background: item.funcionando?'#92B258':'#F03F3F'}} 
                                title={item.funcionando?"Moendo":'Parado'}>
                                    <ModalBody.TextoCircle>{item.funcionando?"Moendo":"Parado"}</ModalBody.TextoCircle>
                            </ModalBody.CircleTitle>    
                        </Body.Row>
                        <Body.ListTitle style={{fontSize: '14px', marginBottom: '10px'}}>
                            {functions.getStatusTerno(item.status).msg}
                        </Body.ListTitle>
                        
                        <Body.ListText style={{fontSize: 12}}>Pressão média</Body.ListText>
                        <Body.GradientContent style={{minHeight: '25px'}}>      
                        <Body.GradientCenter />
                        <Body.RailPickup >
                            <Body.Pickup left={functions.getPressurePickup(
                                                functions.getPressaoMedia(item.transmissor_pressao_direito, item.transmissor_pressao_esquerdo),
                                                functions.getPressaoMedia(item.pressao_esquerda.minima, item.pressao_direita.minima),
                                                functions.getPressaoMedia(item.pressao_esquerda.maxima, item.pressao_direita.maxima))}>
                                    <Body.CirclePickup />
                                    <Body.TextPickup>
                                        {functions.getPressaoMedia(item.transmissor_pressao_direito, item.transmissor_pressao_esquerdo).toFixed(0)} psi
                                    </Body.TextPickup>
                                </Body.Pickup>
                        </Body.RailPickup>
                    </Body.GradientContent>

                    <Body.ListText style={{fontSize: 12}}>Dif. de deslocamento</Body.ListText>
                    <Body.GradientContent style={{minHeight: '25px'}}>      
                        <Body.GradientCenter />
                            <Body.RailPickup >
                                <Body.Pickup left={functions.getDeslocamentoPickup(item)}>
                                        <Body.CirclePickup />
                                        <Body.TextPickup>
                                            {functions.getDeslocamento(item).toFixed(2)}mm 
                                        </Body.TextPickup>
                                    </Body.Pickup>
                            </Body.RailPickup>
                        </Body.GradientContent>
                    </ModalBody.PerformanceCard>})}
        </ModalBody.VisualizePerformanceContent>
        </>
    </Modal>
    {modalTerno?<VisualizarTerno toggleModal={toggleModalTerno} terno={terno!} />:null}
    </>
}

export const ParametrizationUH: React.FC <Props> = ({
    toggleModal, token, uh
}) => {
    const [data, setData] = useState<Types.ParametrizationUnidadeHidraulica>()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading, toggleLoading] = useState(true)    
    const [sending, toggleSending] = useState(false)

    const [temp_max_oleo, setTemMaxOleo] = useState('0')
    const [tempo_max_valvula_ligada, setTempoMaxValv] = useState('0')

    useEffect(()=>{
        toggleLoading(true)
        axios.get("https://www.dhm.dinamoautomacao.com.br/api/parametrizacao?uh="+uh+"&token="+functions.ReplaceSpecialCharacters(token!), {withCredentials: true})
            .then((response)=>{
                setData(response.data)
                setTempoMaxValv(response.data.nuvem.tempo_max_valvula_ligada.toString())
                setTemMaxOleo(response.data.nuvem.temp_max_oleo.toString())
                toggleLoading(false)
            })
            .catch((error)=>{
                toggleLoading(false)
                functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            })
    }, [])

    const send = () => {
        toggleLoading(true)
        toggleSending(true)
        axios.post("https://www.dhm.dinamoautomacao.com.br/api/parametrizacao?uh="+uh+"&token="+functions.ReplaceSpecialCharacters(token!), 
        {temp_max_oleo: Number(temp_max_oleo), tempo_max_valvula_ligada: Number(tempo_max_valvula_ligada)}, {withCredentials: true})
            .then(() => {
                dispatch({type: 'ADD_NOTIFICATION', title: '', text: 'Parâmetros enviados com sucesso!', status: 1})
                toggleLoading(false)
                toggleSending(false)
            })
            .catch((error)=>{
                toggleLoading(false)
                toggleSending(false)
                functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            })
    }

    return <Modal toggleModal={toggleModal} title="Parametrização" sendParams={()=>send()}
                subtitle="Unidade Hidráulica" loading={loading} width={900} sending={sending}>
                <ModalBody.VisualizeTernoContent style={{width: '900px'}}>
                    <Body.Scroll>
                        <Body.Row style={{width: '100%', justifyContent: 'space-between'}}>
                            <Body.HalfContent percentage={49.5}>
                            <ModalBody.Card>
                                <Body.Title style={{fontSize: '20px', marginBottom: '0px'}}>Nuvem</Body.Title>
                            </ModalBody.Card>
                             <Body.Row style={{justifyContent: 'space-between'}}>
                                <Body.HalfContent percentage={49.5}>
                                    <ModalBody.Card>
                                        <Body.ListText>Temperatura máx. do óleo (°C)</Body.ListText>
                                        <ModalBody.Input value={temp_max_oleo} type="number"
                                            onChange={e => setTemMaxOleo(e.target.value)}/>
                                    </ModalBody.Card>
                                </Body.HalfContent>
                                <Body.HalfContent percentage={49.5}>
                                    <ModalBody.Card>
                                        <Body.ListText>Tempo máx. de válvula ligada (ms)</Body.ListText>
                                        <ModalBody.Input value={tempo_max_valvula_ligada} type="number"
                                            onChange={e => setTempoMaxValv(e.target.value)}/>
                                    </ModalBody.Card>
                                </Body.HalfContent>
                            </Body.Row>
                            {/* <Body.Row style={{justifyContent: 'space-between'}}>
                                <Body.HalfContent percentage={49.5}>
                                    <ModalBody.Card>
                                        <Body.ListText>Tempo para ligar motor</Body.ListText>
                                        <Body.ListTitle>{data?.nuvem.tempo_ligar_motor}ms</Body.ListTitle>
                                    </ModalBody.Card>
                                </Body.HalfContent>
                                <Body.HalfContent percentage={49.5}>
                                    <ModalBody.Card>
                                        <Body.ListText>Altura min. para partida livre</Body.ListText>
                                        <Body.ListTitle>{data?.nuvem.altura_minima}mm</Body.ListTitle>
                                    </ModalBody.Card>
                                </Body.HalfContent>
                                </Body.Row>
                                <ModalBody.Card>
                                    <Body.ListText>Nº de ocorrencias</Body.ListText>
                                    <Body.Row style={{justifyContent: 'space-between', width: '100%'}}>
                                        <Body.HalfContent percentage={49.5}>
                                            <ModalBody.Card>
                                                <Body.ListText>vazamento</Body.ListText>
                                                <Body.ListTitle>{data?.nuvem.vazamento}</Body.ListTitle>
                                            </ModalBody.Card>
                                        </Body.HalfContent>
                                        <Body.HalfContent percentage={49.5}>
                                            <ModalBody.Card>
                                                <Body.ListText>Problemas no acumulador</Body.ListText>
                                                <Body.ListTitle>{data?.nuvem.problemas_no_acumulador}</Body.ListTitle>
                                            </ModalBody.Card>
                                        </Body.HalfContent>
                                        </Body.Row>
                                </ModalBody.Card> */}
                            </Body.HalfContent>
                            <Body.HalfContent percentage={49.5}>
                            <ModalBody.Card>
                                <Body.Title style={{fontSize: '20px', marginBottom: '0px'}}>CLP</Body.Title>
                            </ModalBody.Card>
                             <Body.Row style={{justifyContent: 'space-between'}}>
                                <Body.HalfContent percentage={49.5}>
                                    <ModalBody.Card>
                                        <Body.ListText>Temperatura máx. do óleo</Body.ListText>
                                        <Body.ListTitle>{data?.temp_max_oleo}ºC</Body.ListTitle>
                                    </ModalBody.Card>
                                </Body.HalfContent>
                                <Body.HalfContent percentage={49.5}>
                                    <ModalBody.Card>
                                        <Body.ListText>Tempo máx. de válvula ligada</Body.ListText>
                                        <Body.ListTitle>{data?.tempo_max_valvula_ligada}ms</Body.ListTitle>
                                    </ModalBody.Card>
                                </Body.HalfContent>
                                </Body.Row>
                                {/* <Body.Row style={{justifyContent: 'space-between'}}>
                                <Body.HalfContent percentage={49.5}>
                                    <ModalBody.Card>
                                        <Body.ListText>Tempo para ligar motor</Body.ListText>
                                        <Body.ListTitle>{data?.tempo_ligar_motor}ms</Body.ListTitle>
                                    </ModalBody.Card>
                                </Body.HalfContent>
                                <Body.HalfContent percentage={49.5}>
                                    <ModalBody.Card>
                                        <Body.ListText>Altura min. para partida livre</Body.ListText>
                                        <Body.ListTitle>{data?.altura_minima}mm</Body.ListTitle>
                                    </ModalBody.Card>
                                </Body.HalfContent>
                                </Body.Row>
                                <ModalBody.Card>
                                    <Body.ListText>Nº de ocorrencias</Body.ListText>
                                    <Body.Row style={{justifyContent: 'space-between', width: '100%'}}>
                                        <Body.HalfContent percentage={49.5}>
                                            <ModalBody.Card>
                                                <Body.ListText>vazamento</Body.ListText>
                                                <Body.ListTitle>{data?.vazamento}</Body.ListTitle>
                                            </ModalBody.Card>
                                        </Body.HalfContent>
                                        <Body.HalfContent percentage={49.5}>
                                            <ModalBody.Card>
                                                <Body.ListText>Problemas no acumulador</Body.ListText>
                                                <Body.ListTitle>{data?.problemas_no_acumulador}</Body.ListTitle>
                                            </ModalBody.Card>
                                        </Body.HalfContent>
                                        </Body.Row>
                                </ModalBody.Card> */}
                            </Body.HalfContent>
                        </Body.Row>
                    </Body.Scroll>
                </ModalBody.VisualizeTernoContent>
            </Modal>
}

export const VisualizarTerno: React.FC <VisualizarTernoProps> = ({
    toggleModal, terno
}) => {

    return <Modal toggleModal={toggleModal} title={terno?.nome_terno} terno={true}
        manual={terno?.manual!} funcionando={terno.funcionando}>
        <>
           <ModalBody.VisualizeTernoContent>
                <ModalBody.Card >
                    <Body.Row style={{justifyContent: 'center'}}>
                        <Body.ListText style={{marginRight: 10}}>
                            Status
                        </Body.ListText>
                        <Body.CircleTitle color={functions.getStatusTerno(terno?.status!).color} >
                            <Body.IconTitle src={functions.getStatusTerno(terno?.status!).icon} />
                        </Body.CircleTitle>
                    </Body.Row>
                    <Body.ListTitle style={{fontSize: 16}}>
                        {functions.getStatusTerno(terno?.status!).msg}
                    </Body.ListTitle>
                </ModalBody.Card>
                <ModalBody.Card >
                    <Body.Row style={{justifyContent: 'space-between', width: '100%'}}>
                        <Body.HalfContent percentage={49.5}>
                            <Body.ListText>Lado esquerdo</Body.ListText>
                            <Body.Row style={{gridGap: '0px 5px'}}>
                                <Body.ListText>Deslocamento</Body.ListText>
                                <Body.ListTitle>{terno?.transmissor_posic_esquerdo.toFixed(0)}
                                <strong style={{ fontSize: '10px'}}>
                                        mm </strong></Body.ListTitle>
                            </Body.Row>
                        </Body.HalfContent>
                        <Body.HalfContent percentage={49.5} style={{textAlign: "right"}}>
                            <Body.ListText>Lado direto</Body.ListText>
                            <Body.Row style={{gridGap: '0px 5px',justifyContent: "flex-end", width: '100%'}}>
                                <Body.ListText>Deslocamento</Body.ListText>
                                <Body.ListTitle>{terno?.transmissor_posic_direito.toFixed(0)}
                                <strong style={{ fontSize: '10px'}}>
                                        mm </strong></Body.ListTitle>
                            </Body.Row>
                        </Body.HalfContent>
                    </Body.Row>
                    <Body.GradientContent style={{minHeight: '40px', textAlign: 'center'}}>
                        <Body.ListText>Diferença de Deslocamento</Body.ListText>
                        <Body.GradientCenter />
                        <Body.RailPickup >
                            <Body.Pickup left={functions.getDeslocamentoPickup(terno!)}>
                                    <Body.CirclePickup />
                                    <Body.TextPickup>
                                        {functions.getDeslocamento(terno!).toFixed(2)}mm
                                    </Body.TextPickup>
                                </Body.Pickup>
                        </Body.RailPickup>
                    </Body.GradientContent>
                </ModalBody.Card>
                <ModalBody.Card style={{height: '65px',justifyContent:'center'}}>
                        <Body.ListText>SetPoint de pressão</Body.ListText>
                        <Body.ListTitle>{terno?.setpoint_pressao} psi</Body.ListTitle>
                </ModalBody.Card>
                <ModalBody.Card style={{height: '100%',justifyContent:'center'}}>
                    <Body.ListText>Pressão média</Body.ListText>
                    <Body.GradientContent style={{minHeight: '20px'}}>
                        <Body.GradientCenter />
                        <Body.RailPickup >
                            <Body.Pickup left={functions.getPressurePickup(
                                functions.getPressaoMedia(terno?.transmissor_pressao_direito!, terno?.transmissor_pressao_esquerdo!),
                                functions.getPressaoMedia(terno?.pressao_esquerda.minima, terno.pressao_direita.minima),
                                functions.getPressaoMedia(terno.pressao_esquerda.maxima, terno.pressao_direita.maxima))}>
                                <Body.CirclePickup />
                                <Body.TextPickup>
                                    {functions.getPressaoMedia(terno?.transmissor_pressao_direito!, terno?.transmissor_pressao_esquerdo!).toFixed(1)} psi
                                </Body.TextPickup>
                            </Body.Pickup>
                        </Body.RailPickup>
                    </Body.GradientContent>
                </ModalBody.Card>
                <Body.Row style={{justifyContent: 'space-between'}}>
                    <Body.HalfContent percentage={49.5} style={{height: '100%'}} >
                    <ModalBody.Card style={{height: '100%'}}>
                        <Body.GradientContent style={{minHeight: '40px'}}>
                            <Body.ListText>Pressão Esquerda</Body.ListText>
                            <Body.GradientCenter />
                            <Body.RailPickup >
                                <Body.Pickup left={functions.getPressurePickup(terno!.transmissor_pressao_esquerdo,
                                                    terno.pressao_esquerda.minima, terno.pressao_esquerda.maxima)}>
                                    <Body.CirclePickup />
                                    <Body.TextPickup>
                                        {terno?.transmissor_pressao_esquerdo.toFixed(0)} psi
                                    </Body.TextPickup>
                                </Body.Pickup>
                            </Body.RailPickup>
                        </Body.GradientContent>
                    </ModalBody.Card>
                    </Body.HalfContent>
                    <Body.HalfContent percentage={49.5} >
                        <ModalBody.Card>
                        <Body.GradientContent style={{minHeight: '40px'}}>
                            <Body.ListText>Pressão Direita</Body.ListText>
                            <Body.GradientCenter />
                            <Body.RailPickup >
                                <Body.Pickup left={functions.getPressurePickup(terno!.transmissor_pressao_direito,
                                                    terno.pressao_direita.minima, terno.pressao_direita.maxima)}>
                                        <Body.CirclePickup />
                                    <Body.TextPickup>
                                        {terno?.transmissor_pressao_direito.toFixed(0)} psi
                                    </Body.TextPickup>
                                </Body.Pickup>
                            </Body.RailPickup>
                        </Body.GradientContent>
                        </ModalBody.Card>
                    </Body.HalfContent>
                </Body.Row>
                 {/* <ModalBody.Card>
                    <Body.Row style={{width: '100%', justifyContent: 'center', cursor: 'pointer'}}>
                        <Body.ListTitle>Log</Body.ListTitle>
                    </Body.Row>
                </ModalBody.Card>  */}

            </ModalBody.VisualizeTernoContent>
            </>
            </Modal>
}

export const DefineLimits: React.FC <DefineLimitsProps> = ({
    toggleModal, id, ternos, isUH2, token
}) => {
    const dispatch = useDispatch()
    const data = useSelector((state: State)=>state.ternos)

    const [uh, setUh] = useState(0)
    const [modalUH, toggleModalUH] = useState(false)
    const [modalPT, toggleModalPT] = useState(false)
    const [id_terno, setIdTerno] = useState<number>(-1)
    const [nomeTerno, setNomeTerno] = useState('')

    const selectTerno = (id: number, nome: string) => {
        setIdTerno(id)
        setNomeTerno(nome)
        toggleModalPT(true)
    }

    const selectUH = (numberUH: number) => {
        setUh(numberUH)
        toggleModalUH(true)
    }

    return <><Modal toggleModal={toggleModal} title="Selecionar terno">
            <>
            <ModalBody.VisualizeTernoContent style={{display: 'flex',flexWrap: 'wrap', justifyContent: 'space-between',
                    width: '400px'}}>
                {ternos?.map((item, index)=>{
                    return <ModalBody.Card style={{cursor: 'pointer', width: '49.5%'}}
                                onClick={()=>selectTerno(item.terno_id, item.nome_terno)}>
                            <ModalBody.ModalSelectText key={index.toString()}>
                                {item.nome_terno}
                            </ModalBody.ModalSelectText>
                        </ModalBody.Card>
                })}
            </ModalBody.VisualizeTernoContent> 
            </>
         </Modal>
         {modalPT?<ParametrizationTernoModal 
            toggleModal={toggleModalPT} terno={id_terno} nome_terno={nomeTerno} token={token}/>:null}
         </>
}